import Api from "../app/api/Api";
import { Service } from "./service";

export class MatchService extends Service {

    constructor(){
        super()
    }

    /**
     * 匹配的要员信息（包括要员的面试情况）
     * 
     * @param id 匹配id
     * @returns 
     */
    public async getMatchedTalentInfo(id: string) {
        return await Api.get('/api/match/'+ id +'/talent')
    }

    /**
     * 匹配要员-放弃
     * 
     * @param id 匹配id
     * @returns 
     */
    public async matchedTalentDiscard(id: string) {
        return await Api.post('/api/match/'+ id +'/talent-discard')
    }

    /**
     * 匹配要员-备选
     * 
     * @param id 匹配id
     * @returns 
     */
    public async matchedTalentAlternative(id: string) {
        return await Api.post('/api/match/'+ id +'/talent-alternative')
    }

    /**
     * 匹配要员-采用
     * 
     * @param id 匹配id
     * @returns 
     */
    public async matchedTalentEmployRequest(id: string) {
        return await Api.post('/api/match/'+ id +'/talent-employ-request')
    }

    /**
     * 获取某次面试的信息
     * @param id 匹配id
     * @param times 面试次数
     * @returns 
     */
    public async getMatchedInterview(id: string, times: number) {
        return await Api.get('/api/match/'+ id +'/interview/' + times)
    }

    /**
     * 更新面试信息
     * 
     * @param id 匹配id
     * @param interview 该次面试信息
     * @returns 
     */
    public async updateMatchedInterview(id: string, interview: any) {
        // console.log(interview)
        return await Api.post('/api/match/'+ id +'/interview', interview)
    }

    /**
     * 匹配的案件信息（包括要员概要、要员的面试情况、案件原文、案件状态）
     * 案件详情页面用
     * @param id 匹配id
     * @returns 
     */
    public async getMatchedCaseInfo(id: string) {
        return await Api.get('/api/match/'+ id +'/case')
    }

    /**
     * 匹配案件-放弃
     * 
     * @param id 匹配id
     * @returns 
     */
    public async matchedCaseDiscard(id: string) {
        return await Api.post('/api/match/'+ id +'/case-discard')
    }

    /**
     * 匹配案件-备选
     * 
     * @param id 匹配id
     * @returns 
     */
    public async matchedCaseAlternative(id: string) {
        return await Api.post('/api/match/'+ id +'/case-alternative')
    }

    /**
     * 匹配案件-同意（采用申请）
     * 
     * @param id 匹配id
     * @returns 
     */
    public async matchedCaseEmployRequestAccept(id: string) {
        return await Api.post('/api/match/'+ id +'/case-employ-request-accept')
    }

    /**
     * 匹配案件-拒绝（采用申请）
     * 
     * @param id 匹配id
     * @returns 
     */
    public async matchedCaseEmployRequestRefused(id: string) {
        return await Api.post('/api/match/'+ id +'/case-employ-request-refused')
    }

    /**
     * 解锁匹配的要员
     * @param id 匹配id
     * @returns 
     */
    public async unlockTalent(id: string) {
        return await Api.get('/api/match/'+ id +'/unlock-talent')
    }

    /**
     * 解锁匹配的案件
     * @param id 匹配id
     * @returns 
     */
    public async unlockCase(id: string) {
        return await Api.get('/api/match/'+ id +'/unlock-case')
    }    
}