import React from 'react';
import './TemplateBar.css';
import { publish } from '../../util/event';
import { WithTranslation, withTranslation } from 'react-i18next';

interface TemplateBarProps extends WithTranslation {
    // 模版数组：title模版标题，text模版文字
    tpls?: Array<{title:string, text:string}>;
    // 选择的模版（数组下标）
    selected?: number
}
interface TemplateBarState {
    // 当前选择的模版
    tplIndex: number
}

/**
 * 模版选择组件
 */
class TemplateBar extends React.Component<TemplateBarProps, TemplateBarState> {

    constructor(props:TemplateBarProps){
        super(props);
        this.state = {
            tplIndex: props.selected ? props.selected:0
        }
    }

    render() {
        const { t } = this.props
        const tpls = this.props.tpls
        const selected = this.state.tplIndex
        const templateList = tpls.map((item, index) => {
            return(
                <div key={item.text}
                    className={`template ${selected === index ? 'active' : ''}`} 
                    onClick={()=>this.onTemplateClick(index)}>{t(item.title)}
                </div>
            )
        })
        return(
            <div className='template-bar'>
              {templateList}
            </div>
        )
    }

    onTemplateClick(tpl:number) {
        this.setState({
            tplIndex: tpl
        })
        // console.log(this.state.tplIndex)
        // console.log(this.props.tpls[tpl].text)
        // 模版选择事件
        publish('templatebar.tpl-selected', this.props.tpls[tpl].text)
    }
}

export default withTranslation()(TemplateBar);