import { UserService } from './UserService';
import { CaseService } from './CaseService';
import { TalentService } from './TalentService';
import { MatchService } from './MatchService';
import { MessageService } from './MessageService';
import { AppService } from './AppService';
import { EmailService } from './EmailService';
import { GoodsService } from './GoodsService';
import { UserAccountService } from './UserAccountService';
import { UserUnlockService } from './UserUnlockService';
import { OrderService } from './OrderService';

declare var window: any;

export class Server {
  public static app: AppService = new AppService();
  public static email: EmailService = new EmailService();
  public static user: UserService = new UserService();
  public static case: CaseService = new CaseService();
  public static talent: TalentService = new TalentService();
  public static match: MatchService = new MatchService();
  public static msg: MessageService = new MessageService();
  public static goods: GoodsService = new GoodsService();
  public static account: UserAccountService = new UserAccountService();
  public static unlock: UserUnlockService = new UserUnlockService();
  public static order: OrderService = new OrderService();
  public static initialized: boolean = false;
  protected static signUpDetails: any = null;
  
  public static async init() {
    Server.initialized = true;
  }
}
