import parse from 'html-react-parser';
import React from "react";
import { WithTranslation, withTranslation } from 'react-i18next';
import { Server } from "../../../server/server";
import { CaseStatus } from '../../common/common';
import AlertModal from "../../modals/AlertModal";
import { subscribe, unsubscribe } from "../../util/event";
import SharedQuillEditor from "../SharedQuillEditor";
import CaseActionBar from "./CaseActionBar";
import "./CaseCard.css";
import MatchedTalentList from "./MatchedTalentList";

/**
 * 组件-案件信息（包含匹配案件列表）
 */
class CaseCard extends React.Component<CaseCardProps, CaseCardState> {
    // 案件id
    private id: string
    // 编辑器
    private quillRef: any

    constructor(props: CaseCardProps) {
        super(props)
        this.id = props.id
        this.state = {
            loading: false,
            caseText: props.html,
            caseStatus: props.status,
            matchedTalents: [],
            editMode: false,
            alert: ''
        }
    }

    componentDidMount() {
        // 加载案件信息以及匹配的要员信息
        this.getCaseInfo(this.id)

        // 注册案件编辑回调事件
        subscribe('case.' + this.id + '.edit', this.onEditEvent)
    }

    componentWillUnmount() {
        // 卸载案件编辑回调事件
        unsubscribe('case.' + this.id + '.edit', this.onEditEvent)
    }

    render(): React.ReactNode {
        const { t } = this.props
        const id = this.props.id
        const isPremiumUser = this.props.isPremiumUser
        const caseStatus = this.state.caseStatus
        const caseText = this.state.caseText
        const matchedTalents = this.state.matchedTalents
        const editMode = this.state.editMode

        return (
            <div key={id} className="case-card">
                <div className="case-card-main">
                    <div className="case">
                        {!editMode &&
                            <div className={`text-container ${caseStatus == CaseStatus.OPENED ? 'opened': 'closed'}`}>
                                {parse(caseText)}
                            </div>
                        }
                        {
                            // 编辑器 && 
                            <div className="edit-container" style={{display: editMode ? 'block': 'none'}}>
                                <SharedQuillEditor key={'quill-' + id} placeholder={t('elements.case.CaseCard.542184-0')} getRef={(ref: any) => this.quillRef = ref} />
                                <div className='case-post-btns'>
                                    <button onClick={this.onPostClick}>{t('elements.case.CaseCard.542184-1')}</button>
                                    <button onClick={this.onCancelClick}>{t('elements.case.CaseCard.542184-2')}</button>
                                </div>
                            </div>
                        }
                        {!editMode &&
                            <CaseActionBar 
                                id={id} 
                                status={caseStatus} 
                                onChangeStatus={this.onChangeStatus}
                                onRefreshClick={this.onReloadCase}
                            />
                        }
                    </div>
                    {
                        /* 匹配的要员列表 */
                        // caseStatus == CaseStatus.OPENED && 
                        <MatchedTalentList key={'match-'+id} list={matchedTalents} isPremiumUser={isPremiumUser}/>
                    }
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={() => this.setState({ alert: '' })} />
            </div>
        )
    }

    /**
     * 子组件CaseActionBar改变status后回调父组件改变父组件的status
     * 
     * @param status 
     */
    onChangeStatus = (status:number) => {
        // console.log(status)
        this.setState({
            caseStatus: status
        })
    }

    /**
     * 重新加载本组件数据（案件信息及匹配的要员信息）
     */
    onReloadCase = () => {
        console.log('onReloadCase')
        // 重新获取案件及匹配的要员信息
        this.getCaseInfo(this.id)
    }

    /**
     * 修改事件回调函数
     */
    onEditEvent = () => {
        this.onEditMode()
    }

    /**
     * 修改提交事件
     */
    onPostClick = () => {
        const { t } = this.props
        let html = this.quillRef.root.innerHTML
        let text = this.quillRef.getText()
        let realtext = text.trim().replace(/[\r\n]/g, '')
        // console.log("length:" + text.length)
        if(realtext.length == 0) {
            return this.setState({
                alert: t('elements.case.CaseCard.542184-3')
            })
        }
        if(realtext.length >= 2000) {
            this.setState({
                alert: t('elements.case.CaseCard.542184-4')
            })
        }
        this.updateCase(this.id, text, html)
    }

    /**
     * 修改取消事件
     */
    onCancelClick = () => {
        this.offEditMode()
    }

    /**
     * 关闭编辑模式
     */
    offEditMode = () => {
        this.setState({
            editMode: false
        })
        this.quillRef.setText("")
    }

    /**
     * 开启编辑模式
     */
    onEditMode = () => {
        this.setState({
            editMode: true
        })
        var delta = this.quillRef.clipboard.convert(this.state.caseText);
        this.quillRef.setContents(delta)
    }

    /**
     * 获取详情
     */
    getCaseInfo = (id:string) => {
        Server.case.getCase(id).then(res => {
            // console.log(JSON.stringify(res))
            if(res.success) {
                const { text, status, matchedTalents } = res.data
                // console.log(matchedTalents)
                this.setState({
                    caseText: text,
                    caseStatus: status,
                    matchedTalents: matchedTalents || []
                })
            }
        }).catch(err => {
            console.error(err)
        })
    }

    /**
     * 更新原文
     * 
     * @param id 案件id
     * @param html 案件原文
     */
    updateCase = (id:string, text:string, html:string)=> {
        Server.case.updateCase(this.id, text, html).then(res => {
            if(!res.success) {
                this.setState({
                    alert: res.message
                })
                return console.log(res.message)
            }
            this.setState({
                caseText: html
            })
            this.offEditMode()
            this.onReloadCase()
        }).catch((err) => {
            console.error(err)
        })
    }
}

export default withTranslation()(CaseCard)

interface CaseCardProps extends WithTranslation {
    // 案件idx
    id: string
    // 案件原文
    html: string
    // 案件状态
    status: number
    // 当前登录用户是否为付费会员
    isPremiumUser: boolean
}

interface CaseCardState {
    // 是否加载中
    loading: boolean
    // 案件匹配的要员列表
    matchedTalents: any[]
    // 案件原文
    caseText: string,
    // 案件状态
    caseStatus: number

    // 是否为编辑模式（编辑模式可编辑原文）
    editMode: boolean
    // 错误提示
    alert: string
}