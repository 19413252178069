import { TFunction } from "i18next"

export enum CaseStatus {
    // 1公开
    OPENED = 1,
    // 2交往中
    // ONGOING = 2,
    // 3关闭
    CLOSED = 3,
    // 4暂停
    // PAUSE = 4
}

export enum CaseProgress {
    // 1最新发布
    NEW = 1,
    // 2马上满员
    FULL_MOST = 2,
    // 3再次公开 有什么实际意思？TODO
    OPEN_AGINE = 3,
    // 4要员已满
    FULL = 4
}

export enum TalentStatus {
    // 1公开
    OPENED = 1,
    // 2交往中
    // ONGOING = 2,
    // 3关闭
    CLOSED = 3,
    // 4暂停
    // PAUSE = 4
}

export enum TalentProgress {
    // 1最新发布
    NEW = 1,
    // 2公开中
    OPENED = 2,
    // 3稼动中
    WORKING = 3,
    // 4已离职
    RESIGNED = 4
}

export enum MatchedType {
    // AUTO
    AUTO = 1,
    // 人为
    HUMAN = 2
}

/**
 * 他社案件关系状态
 * 
 * 1.待（采用申请待确认）
 * 2.定（成交）
 * 3.备（备选）
 * 4.新（营业中）
 * 5.弃（放弃）
 * 6.关（停止营业（该案件已关闭））
 */
export enum MatchedCaseStatus {
    /** 1.待（采用申请待确认） */
    EMPLOY_REQUEST = 1,
    /** 2.定（成交） */
    EMPLOY_DONE = 2,
    /** 3.备（备选） */
    ALTERNATIVE = 3,
    /** 4.新（营业中）（默认） */
    OPEN = 4,
    /** 5.弃（放弃） */
    DISCARD = 5,
    /** 6.关（停止营业（该案件已关闭）） */
    CLOSED = 6
}

/**
 * 他社要员关系状态
 * 
 * 1.申（采用申请中）
 * 2.定（采用成功）
 * 3.备（备选）
 * 4.新（营业中）
 * 5.拒（采用申请被拒绝）
 * 6.弃（放弃）
 * 7.关（停止营业（该要员已关闭））
 * 8.停（停止营业（被其他案件采用））
 */
export enum MatchedTalentStatus {
    /** 1.申（采用申请中） */
    EMPLOY_REQUEST = 1,
    /** 2.定（采用成功） */
    EMPLOY_REQUEST_OK = 2,
    /** 3.备（备选） */
    ALTERNATIVE = 3,
    /** 4.新（营业中）（默认） */
    OPEN = 4,
    /** 5.拒（采用申请被拒绝） */
    EMPLOY_REQUEST_REFUSED = 5,
    /** 6.弃（放弃） */
    DISCARD = 6,
    /** 7.关（停止营业（该要员已关闭）） */
    CLOSED = 7,
    /** 8.停（停止营业（被其他案件采用）） */
    STOP = 8,
}

export enum InterviewWay {
    // 1面谈
    FACE2FACE = 1,
    // 2网络面试
    ONLINE = 2
}

export enum InterviewTimes {
    // 未指定
    NONE = 0,
    // 默认总面试次数
    DEFALUT_TOTAL_TIMES = 3,
    // 第一次
    FIRST = 1,
    // 第二次
    SECOND = 2,
    // 第三次
    THIRD = 3
}

/**
* 营业事件区分
*/
export enum BusinessEvent {
    // 面试
    INTERVIEW = 1,
    // 采用
    EMPLOY = 2,
}

export enum RecordStatus {
    // 无效
    INVALID = 0,
    // 正常
    NORMAL = 1
}

export enum CaseOrTalent {
    // 案件
    CASE = 1,
    // 要员
    TALENT = 2
}

export enum MessageType {
    // 文本
    TEXT = 1
}

export enum UserType {
    /**
     * 普通用户
     */
    BASIC = 0,
    /**
     * 付费会员
     */
    PREMIUM = 1
}

export enum GoodsCategory {
    /**
     * 充值: 0
     */
    RECHARGE = 0,

    /**
     * 特定要员
     */
    TALENT = 1,

    /**
     * 特定案件
     */
    CASE = 2,

    /**
     * 会员资格: 3
     */
    PREMIUM = 3,
}

export enum TaxCategory {
    /**
     * 无税
     */
    NONE = -1,
    /**
     * 消费税
     */
    EXCISE = 0
}

export enum OrderType {
    /**
     * -1不指定
     */
    NONE = -1,
    
    /**
     * 0充值（购买梧桐果）
     */
    RECHARGE = 0,

    /**
     * 1激活特定要员（梧桐果支付）
     */
    UNLOCK_TALENT = 1,

    /**
     * 2激活特定案件（梧桐果支付）
     */
    UNLOCK_CASE = 2,

    /**
     * 3年付费会员（梧桐果支付）
     */
    PREMIUM = 3

}

export enum PayWay {
    /**
     *  支付方式 0：梧桐果支付
     */
    POINT = 0,
    /**
     *  支付方式 1：第三方在线支付
     */
    THIRD_PARTY = 1,
    /**
     *  支付方式 2：其他
     */
    OTHER = 2,
}

export enum PayProvider {
    /**
     *  支付渠道方 0：Paypal
     */
    PAYPAL = 0,
    /**
     *  支付渠道方 1：微信支付
     */
    WEPAY = 1,
    /**
     *  支付渠道方 2：支付宝
     */
    ALIPAY = 2,
    /**
     *  支付渠道方 3：信用卡
     */
    CREDIT = 3,
    /**
     *  支付渠道方 4：其他
     */
    OTHER = 4,
}

export enum GoodsCurrency {
    /**
     *  货币类型 0：梧桐果
     */
    POINT = 0,
    /**
     *  货币类型 1：日元
     */
    JPY = 1,
    /**
     *  货币类型 2：人民币
     */
    CNY = 2,
}

export enum UnlockType {
    /**
     * 0激活要员
     */
    TALENT = 0,

    /**
     * 1激活案件
     */
    CASE = 1
}

export enum Language {
    /**
     * 日文
     */
    ja = 'ja',
    
    /**
     * 中文
     */
    zh = 'zh'
}

export enum TermialType {
    /** 0：PC */
    PC = 0,
    /** 1：便携设备 */
    MOBILE = 1,
}

export enum RechargeSource {
    /**
     * 从他社案件详情迁移而来
     */
    CASE = 1,
    /** 从他社要员详情迁移而来 */
    TALENT = 2,
    /**
     * 从账户(充值与账单页面)迁移而来
     */
    BALANCE = 3
}

/**
 * 匹配的案件的状态
 * 
 * @param status 
 * @returns 
 */
export function getMathcedCaseStatus(status: number, short = true, t?:TFunction) {
    switch (status) {
        case MatchedCaseStatus.OPEN:
            // return short ? '新': '营业中'
            return short ? t('common.MathcedCaseStatus.short.OPEN'): t('common.MathcedCaseStatus.OPEN')
        case MatchedCaseStatus.DISCARD:
            // return short ? '弃': '放弃'
            return short ? t('common.MathcedCaseStatus.short.DISCARD'): t('common.MathcedCaseStatus.DISCARD')
        case MatchedCaseStatus.ALTERNATIVE:
            // return short ? '备': '备选'
            return short ? t('common.MathcedCaseStatus.short.ALTERNATIVE'): t('common.MathcedCaseStatus.ALTERNATIVE')
        case MatchedCaseStatus.EMPLOY_REQUEST:
            // return short ? '待': '采用申请待确认'
            return short ? t('common.MathcedCaseStatus.short.EMPLOY_REQUEST'): t('common.MathcedCaseStatus.EMPLOY_REQUEST')
        case MatchedCaseStatus.EMPLOY_DONE:
            // return short ? '定': '成交'
            return short ? t('common.MathcedCaseStatus.short.EMPLOY_DONE'): t('common.MathcedCaseStatus.EMPLOY_DONE')
        case MatchedCaseStatus.CLOSED:
            // return short ? '关': '停止营业（该案件已关闭）'
            return short ? t('common.MathcedCaseStatus.short.CLOSED'): t('common.MathcedCaseStatus.CLOSED')
        default:
            return ''
    }
}

/**
 * 匹配的案件的成交及面试安排要员数量统计字符串
 * 
 * @param dealCount 已采用要员数量
 * @param interviewCount 面试安排要员数量
 * @returns 
 */
export function getMathcedCaseTotal(dealCount:number, interviewCount:number) {
    return `[定${dealCount}/面${interviewCount}]`
}

/**
 * 匹配的要员的状态
 * 
 * @param status 要员状态
 * @param short 是否返回略称 
 * @returns 要员状态显示文字
 */
export function getMathcedTalentStatus(status: number, short = true, t:TFunction) {
    switch (status) {
        case MatchedTalentStatus.OPEN:
            // return short ? '新': '营业中'
            return short ? t('common.MathcedTalentStatus.short.OPEN'): t('common.MathcedTalentStatus.OPEN')
        case MatchedTalentStatus.DISCARD:
            // return short ? '弃': '放弃'
            return short ? t('common.MathcedTalentStatus.short.DISCARD'): t('common.MathcedTalentStatus.DISCARD')
        case MatchedTalentStatus.ALTERNATIVE:
            // return short ? '备': '备选'
            return short ? t('common.MathcedTalentStatus.short.ALTERNATIVE'): t('common.MathcedTalentStatus.ALTERNATIVE')
        case MatchedTalentStatus.EMPLOY_REQUEST:
            // return short ? '申': '采用申请中'
            return short ? t('common.MathcedTalentStatus.short.EMPLOY_REQUEST'): t('common.MathcedTalentStatus.EMPLOY_REQUEST')
        case MatchedTalentStatus.EMPLOY_REQUEST_REFUSED:
            // return short ? '拒': '采用申请被拒绝'
            return short ? t('common.MathcedTalentStatus.short.EMPLOY_REQUEST_REFUSED'): t('common.MathcedTalentStatus.EMPLOY_REQUEST_REFUSED')
        case MatchedTalentStatus.EMPLOY_REQUEST_OK:
            // return short ? '定': '采用成功'
            return short ? t('common.MathcedTalentStatus.short.EMPLOY_REQUEST_OK'): t('common.MathcedTalentStatus.EMPLOY_REQUEST_OK')
        case MatchedTalentStatus.CLOSED:
            // return short ? '关': '停止营业（该要员已关闭）'
            return short ? t('common.MathcedTalentStatus.short.CLOSED'): t('common.MathcedTalentStatus.CLOSED')
        case MatchedTalentStatus.STOP:
            // return short ? '停': '停止营业（被其他案件采用）'
            return short ? t('common.MathcedTalentStatus.short.STOP'): t('common.MathcedTalentStatus.STOP')
        default:
            return ''
    }
}

/**
 * 匹配的要员的面试状况统计字符串
 * 
 * @param talentInterviewComplete 已完成的面试数量
 * @param talentInterviewComing 未来的面试数量
 * @returns 
 */
export function getMatchedTalentInterviewTotal(talentInterviewComplete:number, talentInterviewComing:number) {
    return `[待${talentInterviewComplete}/面${talentInterviewComing}]`
}

export function getInterviewTimes(times:number, totalTimes?: number) {
    return `${times}/${totalTimes != null ? totalTimes: '?'}面`
}

export function getInterviewWay(way:number) {
    let wayStr = ''
    if (way == InterviewWay.FACE2FACE) {
        wayStr = '面谈'
    } else if (way == InterviewWay.ONLINE) {
        wayStr = '网络'
    }
    return wayStr
}

export function getMatchedLevel(matchedPercent: string) {
    // 去掉%百分号只取数值部分（存在小数点）
    const matchedPercentNum = Number(matchedPercent.replace(/%/g, '')).valueOf()
    // 70%以下：⭐︎、70%到90%之间：⭐︎⭐︎、大于90%：⭐︎⭐︎⭐︎
    if (matchedPercentNum < 70) {
        // return '⭐︎'
        return '\u2729'
    } else if (matchedPercentNum >= 70 && matchedPercentNum < 90) {
        // return '⭐︎⭐︎'
        return '\u2729\u2729'
    } else if (matchedPercentNum >= 90) {
        // return '⭐︎⭐︎⭐︎'
        return '\u2729\u2729\u2729'
    }
}