import { Server } from "../../server/server";
import { Language } from "../common/common";

export default class Api {
    public static endpoint = process.env.REACT_APP_API_BASE_PATH
    
    constructor() {}

    private static async request(url:string, method:string, body?:any, token?:string) {
        // console.log(body)
        let requestBody = {
            method: method,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + (token ? token : localStorage.getItem('token')),
              'lan': localStorage.getItem('lan') || Language.ja,
              'from': window.location.origin || ''
            }
        }
        if (body) {
            requestBody = Object.assign(requestBody, { body: JSON.stringify(body) })
        }
        // console.log("requestBody:" , requestBody)
        const response = await fetch(url, requestBody);
        // console.log(response)
        const {code, msg, data} = await response.json()
        if(code === 0) {
            return {success: true, message: 'ok', data: data}
        } else {
            if (code == 401) {
                // 无权限，删除token，强制退出
                // localStorage.removeItem('token')
                Server.user.logout()
            }
            return {success: false, message: msg}
        }
    }
    
    private static async formDataRequest(url: string, formData: FormData) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'lan': localStorage.getItem('lan') || Language.ja,
              'from': window.location.origin || ''
            },
            body: formData
        });
        // console.log(response)
        const {code, msg, data} = await response.json()
        if(code === 0) {
            return {success: true, message: 'ok', data: data}
        } else {
            return {success: false, message: msg}
        }
    }

    private static async stream(url:string, filename:string) {
        let requestBody = {
            // method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'lan': localStorage.getItem('lan') || Language.ja,
              'from': window.location.origin || ''
            }
        }
        const response = await fetch(url, requestBody);
        // console.log(response)
        if(response.status == 200) {
            const blob = await response.blob()
            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a')
            a.href = blobUrl
            a.download = filename
            document.body.appendChild(a)
            a.click()
            URL.revokeObjectURL(blobUrl)
            document.body.removeChild(a)
        } else {
            return Promise.reject()
        }
        
    }

    public static async get(path:string, token?:string) {
        const url = Api.endpoint + path
        return await this.request(url, 'GET', null, token)
    }
    
    public static async post(path:string, body?:any, token?:string) {
        const url = Api.endpoint + path
        return await this.request(url, 'POST', body, token)
    }

    public static async upload(path:string, formData: FormData) {
        const url = Api.endpoint + path
        return await this.formDataRequest(url, formData)
    }

    public static async download(path:string, filename:string) {
        const url = Api.endpoint + path
        return await this.stream(url, filename)
    }
}