import Api from "../app/api/Api";
import { Service } from "./service";

interface LoginUser {
    // 邮箱
    email: string
    // 密码
    password: string

}

export class LoginService extends Service {
    
    constructor() {
        super();
    }

    /**
     * 通过email地址和密码登录
     * 
     * @param user 用email地址注册的用户实体
     * @returns 登录成功后返回用户登录态token
     */
    async login(user: LoginUser) {
        // const { email, password } = user
        return await Api.post('/api/user/login', user)
    }

    /**
     * 获取公众号二维码
     * 
     * @returns 
     */
    async qrcode() {
        return await Api.get('/wx/mp/qrcode')
    }

    /**
     * 检查扫码关注公众号结果
     * 
     * @param scene 客户端唯一标识
     * @returns 
     */
    async qrcodeLoginCheck(scene: string) {
        return await Api.get("/api/user/login-scan-qrcode?scene=" + scene)
    }
}