import { regexCountry, regexGender, regexJLPT, regexWorkStyle } from "./regex";

/**
 * check the input if is a valid number
 * @param value 
 * @returns 
 */
export function checkNumber(value: string) {
  const regex = /^[0-9]*$/; // 0-9 number

  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Format time to twitter style ones
 * @param time timestamp in seconds
 * @param ago the 'ago' suffix 
 * @returns the time formatted
 */
export function formatTimestamp(time: number, ago?: boolean) {

  const m = new Map([[1, 'Jan'], [2, 'Feb'], [3, 'Mar'], [4, 'Apr'], [5, 'May'], [6, 'Jun'], 
    [7, 'Jul'], [8, 'Aug'], [9, 'Sep'], [10, 'Oct'], [11, 'Nov'], [12, 'Dec']]);

  let now  = secondsOfNow();
  let diff = now - time;

  const days    = Math.floor(diff / (60 * 60 * 24));
  const hours   = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((diff % (60 * 60)) / 60);
  const seconds = Math.floor(diff % 60);

  if (days > 0) {
    const date = new Date(time * 1000);
    
    if (days > 365) {
      return date.toLocaleString();
    } else {
      const month = date.getMonth() + 1;
      const day   = date.getDate();
      return m.get(month) + ' ' + day;
    }
  }

  if (hours > 0) {
    let t = hours + 'h';
    if (ago) t += ' ago';
    return t;
  }

  if (minutes > 0) {
    let t = minutes + 'm';
    if (ago) t += ' ago';
    return t;
  }

  if (seconds > 0) {
    let t = seconds + 's';
    if (ago) t += ' ago';
    return t;
  }

  return 'just now';
};

/**
 * Gets the time value of now in milliseconds.
 * @returns the time value in milliseconds
 */
export function msOfNow() {
  return new Date().getTime();
}

/**
 * Gets the time value of now in seconds.
 * @returns the time value in seconds
 */
export function secondsOfNow() {
  return Math.floor(new Date().getTime() / 1000);
}

/**
 * Gets the unique id.
 * @returns the unique id
 */
export function uniqueId() {
  return Math.floor(Math.random() * 1000000);
}

/**
 * Gets a uuid.
 * @returns the unique uuid
 */
export function uuid() {
  var str = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  return str.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * Generate a random number between min and max, including both min and max.
 * @param min 
 * @param max 
 * @returns the integer
 */
export function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getAssetImage(asset:any):string {
  let image = asset.image;
  if(!image)
    image = asset.id;
  return '/assets/' + image + '.png';
}

export function getPortraitImage(profile:any):string {
  let image = profile ? profile.portrait : null;
  if(!image)
    image = '/portrait-default.png';
  return image;
}

export function getBannerImage(profile:any):string {
  let image = profile ? profile.banner : null;
  if(!image)
    image = '/banner-default.png';
  return image;
}

export function getMenuIcon(name:string):string {
  return '/icon/' + name + '.png';
}

/**
 * Convert the url in the str to link
 * @param str
 * @returns 
 */
export function urlToLink(str: string):any {
  const re = /(f|ht){1}(tp|tps):\/\/([\w-]+\S)+[\w-]+([\w-?%#&=]*)?(\/[\w- ./?%#&=]*)?/g;
  
  str = str.replace(re, function (url) {
    return `<a href=${url} target="_blank" style="color: white">${url}</a>`;
  });

  return {__html: str};
}

export function numberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * get amount of media file from the Quill editor content
 * @param quillRef 
 * @returns 
 */
export function getMediaAmount(quillRef: any) {
  let mediaAmount = 0;
  let html     = quillRef.root.innerHTML;
  let img      = html.match(/<img/gi);
  let iframe   = html.match(/<iframe/gi);
  let audio    = html.match(/<audio/gi);
  mediaAmount += img && img.length;
  mediaAmount += iframe && iframe.length;
  mediaAmount += audio && audio.length;

  return mediaAmount;
}

/**
 * Convert the substr which start with @ to user slug
 * @param str
 * @returns 
 */
export function convertSlug(str: string):any {
  const pattern = /@\w+(-\w+)*/g;
  str = str.replace(pattern, function (slug) {
    return `<a className='activity-page-slug-link' href='/profile/${slug.substring(1)}' id="url-${slug}">${slug}</a>`;
  });

  return str;
}

/**
 * Convert the substr which start with # to hash tag
 * @param str
 * @returns 
 */
export function convertHashTag(str: string): string {
  const pattern = /#\w+(-\w+)*/g;
  str = str.replace(pattern, function (hashtag) {
    return `<a className='activity-page-slug-link' href='/topic/${hashtag.substring(1)}' id="url-${hashtag}">${hashtag}</a>`;
  });

  return str;
}

/**
 * Convert the URLs that are not within a tag to link.
 * @param str original string
 * @returns 
 */
export function convertUrls(str: string): string {
  // match all of URLs
  const urlRegex = /(f|ht){1}(tp|tps):\/\/([\w-]+\S)+[\w-]+([\w-?%#&=]*)?(\/[\w- ./?%#&=]*)?/g;

  // match all of <a> tag content
  const hrefRegex = /<a\s+[^>]*?href\s*=\s*(['"])(.*?)\1/g;
  const hrefs     = str.match(hrefRegex);

  // match all of img tag content
  const imgSrcRegex = /<img.*?src="(.*?)".*?>/g;
  const imgSrcs     = str.match(imgSrcRegex);

  // match all of audio tag content
  const audioSrcRegex = /<audio.*?src="(.*?)"/g;
  const audioSrcs     = str.match(audioSrcRegex);

  // match all of iframe tag content
  const iframeSrcRegex = /<iframe.*?src="(.*?)"/g;
  const iframeSrcs     = str.match(iframeSrcRegex);
  
  // repalce all of URLs while ignoring URLs that are already within an <a>, <img> or <audio> tag.
  const convertedText = str.replace(urlRegex, (url) => {
    if (hrefs && hrefs.includes(`<a href="${url}"`))
      return url;
    else if (imgSrcs && imgSrcs.includes(`<img src="${url}">`))
      return url;
    else if (audioSrcs && audioSrcs.includes(`<audio src="${url}"`))
      return url;
    else if (iframeSrcs && iframeSrcs.includes(`<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="${url}"`))
      return url;
    else
      return `<a href=${url} target="_blank" id="url-${url}">${url}</a>`;
  });

  return convertedText;
}

export function isValidUrl(url: string) {
  var pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
  return pattern.test(url);
}

// find the first image in the topic content
export function getFirstImage(content: any) {
  let image = '';
  let start = content.indexOf('<img');

  if (start != -1) {
    let end = content.indexOf('>', start);
    if (end != -1)
      image = content.substring(start + 10, end - 1);
  }

  return image;
}

/**
 * browserDetect
 * @returns browserName
 */
export function browserDetect() {
  let userAgent = navigator.userAgent;
  let browserName;
  
  // Approach 1
  // if (userAgent.match(/chrome|chromium|crios/i)) {
  //   browserName = "chrome";
  // } else if (userAgent.match(/firefox|fxios/i)) {
  //   browserName = "firefox";
  // } else if (userAgent.match(/safari/i)) {
  //   browserName = "safari";
  // } else if (userAgent.match(/opr\//i)) {
  //   browserName = "opera";
  // } else if (userAgent.match(/edg/i)) {
  //   browserName = "edge";
  // } else {
  //   browserName="other browser";
  // }
  
  // Approach 2
  // CHROME
  if (userAgent.indexOf("Chrome") != -1 ) {
    console.log("Google Chrome");
    browserName = "chrome";
  }
  // FIREFOX
  else if (userAgent.indexOf("Firefox") != -1 ) {
    console.log("Mozilla Firefox");
    browserName = "firefox";
  }
  // INTERNET EXPLORER
  else if (userAgent.indexOf("MSIE") != -1 ) {
    console.log("Internet Exploder");
    browserName = "ie";
  }
  // EDGE
  else if (userAgent.indexOf("Edge") != -1 ) {
    console.log("Internet Edge");
    browserName = "edge";
  }
  // SAFARI
  else if (userAgent.indexOf("Safari") != -1 ) {
    console.log("Safari");
    browserName = "safari";
  }
  // OPERA
  else if (userAgent.indexOf("Opera") != -1 ) {
    console.log("Opera");
    browserName = "opera";
  }
  // YANDEX BROWSER
  else if (userAgent.indexOf("YaBrowser") != -1 ) {
    console.log("YaBrowser");
    browserName = "yandex";
  }
  // Brave - TODO: need to test and update
  else if (userAgent.indexOf("Brave") != -1 ) {
    console.log("Brave");
    browserName = "brave";
  }
  // OTHERS
  else {
    console.log("Others");
    browserName = "others";
  }

  return browserName;
}

export function isValidEmail(email: string) {
  // var pattern = /^[a-zA-Z0-9-]+(.[a-zA-Z0-9_-]+)@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+).[a-zA-Z]{2,6}$/;
  var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,6})$/;
  return pattern.test(email);
}
export function isValidPwd(password: string) {
  var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
  return pattern.test(password)
}
export function isPhoneNumber(phone: string) {
  // 只包含数字、-、+符号
  return /^\+?[0-9\-]*$/.test(phone)
}
export async function fetchGraphQL(queryObject: any) {
  const response = await fetch('https://arweave.net/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer YOUR_TOKEN',
    },
    body: JSON.stringify(queryObject),
  });

  const data = await response.json();
  // console.log("==> data:", data)
  return data.data.transactions.edges;
}

export function capitalizeFirstLetter(str: string) {
  return str.replace(/^\w/, c => c.toUpperCase());
}

/**
 * Remove all HTML tags from a string
 * @param str 
 * @returns 
 */
export function stripHtmlTags(str: string) {
  return str.replace(/<[^>]*>/g, ''); 
}

/**
 * 辞书解析类型01-06: 包含即可
 * @param str 解析内容
 * @param category 内容类型
 * @returns -1: 未找到
 */
export function parse0106(str: string, category: string) {
  let regex;

  if (category === '性别') regex = regexGender;
  if (category === '国籍') regex = regexCountry;
  if (category === '语言水平') regex = regexJLPT;
  if (category === '出勤方式') regex = regexWorkStyle;

  for (let i = 0; i < regex.length; i++) {
    let resp = str.match(regex[i]);
    console.log('parse0106:', resp);
    if (resp) return i;
  }

  return -1;
}

// 分析性别
export function getGender(str: string) {
  // 正则表达式
  let regex = [
    /(男性|男|男のみ|限男性)/g,  // 男
    /(女性|女|女のみ|限女性)/g,  // 女
  ];

  let result = 'Not Found';
  for (let i = 0; i < regex.length; i++) {
    let resp = str.match(regex[i]);
    console.log('getGender:', resp);
    
    if (resp) {
      if (i === 0) result = '男';
      if (i === 1) result = '女';
      return result;
    }
  }

  return result;
}

// 分析国籍
export function getCountry(str: string) {
  // 正则表达式
  let regex = [
    /(中国籍|中国|china|chinese|中国人|外国籍は日本語非常に流暢なら提案可)/g,  // 中国
    /(日本籍|日本|japan|japanese|日本人|日本籍のみ|日本人が望ましい|日本人技術者のみ|帰化不可)/g,  // 日本
  ];

  let result = 'Not Found';
  for (let i = 0; i < regex.length; i++) {
    let resp = str.match(regex[i]);
    console.log('getCountry:', resp);
    
    if (resp) {
      if (i === 0) result = '中国';
      if (i === 1) result = '日本';
      return result;
    }
  }

  return result;
}

// 分析语言水平
export function getJLPT(str: string) {
  // 正则表达式
  let regex = [
    /(日本人|日本語流暢|日语流畅|日本語ネイティブ|日本語流暢な方のみ|N[12]|N[12]以上|日本語N[12]|日語N[12]|日语N[12]|日本語[12]級|日語[12]級|日语[12]級)/g,  // 流畅
    /(N[34]|日本語N[34]|日語N[34]|日语N[34]|日本語[34]級|日語[34]級|日语[34]級)/g,  // 普通
    /(N[5]|日本語N[5]|日語N[5]|日语N[5]|日本語[5]級|日語[5]級|日语[5]級)/g,  // 入门
  ];

  let result = 'Not Found';
  for (let i = 0; i < regex.length; i++) {
    let resp = str.match(regex[i]);
    console.log('getJLPT:', resp);
    
    if (resp) {
      if (i === 0) result = '流畅';
      if (i === 1) result = '普通';
      if (i === 2) result = '入门';
      return result;
    }
  }

  return result;
}

// 分析出勤方式
export function getWorkStyle(str: string) {
  // 正则表达式
  let regex = [
    /(在宅|テレワーク|リモート|基本在宅|リモート可|フルリモート|リモート併用|在宅あり|テレワークも可能|フルリモ|全在宅|副業)/g,  // 在宅
    /(出社|現場出勤|在宅なし|常駐|への出勤|リモートは少ない)/g,  // 出社
    /(部分出勤)/g,  // 部分出勤
  ];

  let result = 'Not Found';
  for (let i = 0; i < regex.length; i++) {
    let resp = str.match(regex[i]);
    // console.log('getWorkStyle:', resp);
    
    if (resp) {
      if (i === 0) result = '在宅';
      if (i === 1) result = '出社';
      if (i === 2) result = '部分出勤';
      return result;
    }
  }

  return result;
}

// try a new way
export function parsePost(str: string) {
  let titles = ['开始时间', '日语要求', '开发语言', '开发工具', '工作方式', '人数',  ];

  // 关键词列表（辞书）
  let keywords = [
    ['期間', '期 間', '期　間', '', ],  // '开始时间'
    // ['日語', '日本語', ], // '日语要求'
    ['', ], // '日语要求'
    ['', '', ], // '开发语言'
    ['', '', ], // '开发工具',
    ['', '', ], // '工作方式'
    ['人数', '人　数', ], // '人数'
  ];

  // 正则表达式
  let regex = [
    /(?<!\d)(?:(\d{4})[\/\年](\d{1,2})|(\d{1,2})[\/\月](\d{0,2}))(?!\d)/g,  // '开始时间'
    /((日本语|日本語)N[12345])|((JLPT )?(N[12345]))/g,  // '日语要求'
    /(python|django|sql|bash|cobol|java|php|perl|swift|kotlin|objective-c|html|css|js|javascript|typescript|go|golang|rust|c(#|\+|#?(\+\+)))/gi,  // '开发语言'
    /(unity|unreal|Springboot|jQuery|node.js|react|react.js|next|next.js|vue|vue.js|vscode|mysql|oracle|sqlserver|db2|mogondb|PostgreSQL|ETL)/gi,  // '开发工具'
    /(オフィス|リモート|フルリモート|副業|在宅|テレワーク||定期出社)/g,  // 出勤方式
    /\b(\d+(?:-|\~|\～)\d+(?=人|名)|(\d+)(?=人|名))\b/g,   // '人数'
  ];

  // 特别的关键词
  let special = ['即日', '日本人', '', '', '', '',  ];

  let final = [];
  for (let i = 0; i < titles.length; i++) {
    let result = parseOneKey(str, keywords[i], titles[i], special[i], regex[i]);
    final.push(result);
  }
  
  // console.log("final:", final)
  return final;
}

function parseOneKey(str: string, keywords: string[], title: string, special: string, regex: any) {
  let start = 0, lengthOfWord = 0, result = '';

  for (let i = 0; i < keywords.length; i++) {
    start = str.indexOf(keywords[i]);
    if (start > 0) {
      lengthOfWord = keywords[i].length;
      break;
    }
  }
  // console.log("start:", start, lengthOfWord)

  if (special && str.indexOf(special) !== -1) {
    result = `【${title}】--> ${special}`;
  }
  else if (start > 0) {
    let end = str.indexOf('</p>', start);
    // console.log("end:", end)

    result = str.substring(start + lengthOfWord, end);
    result = result.replace('：', '');
    // console.log("result:", result)
    result = `【${title}】--> ${result}`;
  }
  else {
    // 正则表达式
    let resp = str.match(regex);
    // console.log('matched:', resp);
    
    if (resp && resp.length > 0) {
      let uniqueArr = resp.filter((v, i, a) => a.findIndex(t => t.toUpperCase() === v.toUpperCase()) === i);
      result = `【${title}】--> ${uniqueArr}`;
    }
    else
      result = `【${title}】--> Not Found`;
  }

  return result;
}

export function removeDuplicate(data: any) {
  let result = [];
  let ids    = [] as any;
  for (let i = 0; i < data.length; i++) {
    let id = data[i].node.tags[2].value;
    if (!ids.includes(id)) {
      ids.push(id);
      result.push(data[i]);
    }
  }

  return result;
}