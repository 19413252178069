import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { BsFillXCircleFill } from 'react-icons/bs';
import { Server } from '../../server/server';
import { isPhoneNumber, isValidEmail } from '../util/util';
import AlertModal from './AlertModal';
import MessageModal from './MessageModal';
import './Modal.css';
import './SignupEmailModel.css';

interface SignupEmailModelPro extends WithTranslation {
    onClose: Function
    open: boolean
}

interface SignupEmailModelState {
    email: string
    code: string
    pwd: string
    pwdConfirm: string
    inviteCode: string,
    firstName: string,
    lastName: string,
    mobile: string,
    company: string,
    companyShort: string,
    isSendEmail: boolean
    count: number
    message:string
    alert: string
}

class SignupEmailModel extends React.Component<SignupEmailModelPro, SignupEmailModelState> {

    private timer: any = null
    // public signupService: SignupService;

    constructor(props: SignupEmailModelPro) {
        super(props)
        // const [] = useState(0)
        this.state = {
            email: '',
            code: '',
            pwd: '',
            pwdConfirm: '',
            inviteCode: '',

            firstName: '',
            lastName: '',
            mobile: '',
            company: '',
            companyShort: '',

            isSendEmail: false,
            count: 60,
            message:'',
            alert: '',
        }
        
    }

    // 发送邮箱验证码
    onSendCodeClick = async () => {
        const { t } = this.props
        const email = this.state.email
        if(!email) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-0')})
        }
        if(!isValidEmail(email)) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-1')})
        }

        // 开启倒计时
        this.startCountdown()

        // 发送验证码
        Server.email.sendCode(email).then(res => {
            if(!res.success) {
                this.stopCountdown()
                return this.setState({ alert: res.message })
            }
        }).catch(err => {
            console.log(err)
            this.stopCountdown()
            this.setState({ alert: t('modals.SignupEmailModel.752457-2') })
        })
    }
    
    // 注册
    onSignUpClick = async () => {
        const { t } = this.props
        const email = this.state.email
        const code = this.state.code
        const pwd = this.state.pwd
        const pwdConfirm = this.state.pwdConfirm
        const inviteCode = this.state.inviteCode

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const company = this.state.company
        const companyShort = this.state.companyShort
        const mobile = this.state.mobile

        if (email.length == 0) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-0')})
        }
        if(!isValidEmail(email)) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-1')})
        }
        if(code.length == 0) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-3')})
        }
        if (pwd.length == 0) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-4')})
        }
        if (pwd.length < 6) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-5')})
        }
        if (pwdConfirm.length == 0) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-6')})
        }
        if (pwdConfirm != pwd) {
            return this.setState({alert : t('modals.SignupEmailModel.752457-7')})
        }
        if (!firstName || !lastName) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-8')})
        }
        if(mobile && !isPhoneNumber(mobile)) {
            return this.setState({alert: t('modals.SignupEmailModel.752457-9')})
        }

        this.setState({message: t('modals.SignupEmailModel.752457-10')})

        Server.user.signupByEmail({
            email: email,
            pwd: pwd,
            code: code,
            pwdTwo: pwdConfirm,
            inviteCode: inviteCode,
            firstName: firstName,
            lastName: lastName,
            mobile: mobile,
            company: company,
            companyShort: companyShort
        }).then(res => {
            if(!res.success) {
                return this.setState({
                    alert: res.message,
                    message: '',
                })
            }
            this.setState({
                alert: '',
                message: t('modals.SignupEmailModel.752457-11'),
                email:'',
                pwd:'',
                code: '',
                pwdConfirm: ''
            })
            setTimeout(()=> {
                this.setState({
                    message: ''
                })
                this.props.onClose()
            }, 500)
        }).catch(err => {
            console.log(err)
            this.setState({
                alert: t('modals.SignupEmailModel.752457-12'),
                message: '',
            })
        })
    }

    // 开启倒计时
    startCountdown = () => {
        this.setState({
            isSendEmail: true,
            count: 60
        })
        this.timer = setInterval(()=> {
            if (this.state.count > 1) {
                this.setState({
                    count: this.state.count - 1
                })
            } else {
                this.stopCountdown()
            }
        }, 1000)
    }

    // 停止倒计时
    stopCountdown = () => {
        this.setState({
            isSendEmail: false,
            count: 60
        })
        this.resetTimer()
    }

    // 清空计时器
    resetTimer = () => {
        if (this.timer) {
            clearInterval(this.timer)
        }
        this.timer = null
    }

    componentWillUnmount(): void {
        this.stopCountdown()
    }

    render() {
        const { t } = this.props
        if(!this.props.open){
            return (<div></div>)
        }
        
        const isSendEmail = this.state.isSendEmail
        const count = this.state.count
        return (
            <div>
                <div className={ `modal ${this.props.open ? 'open' : ''}` }>
                    <div className="modal-content">
                        <button className="modal-close-button" onClick={() => this.props.onClose()}>
                            <BsFillXCircleFill />
                        </button>
                        <div><h3>{t('modals.SignupEmailModel.752457-13')}</h3></div>
                        <div className='content-container'>
                            <div className='item-container'>
                                <input
                                    id='email'
                                    name='email'
                                    placeholder={t('modals.SignupEmailModel.752457-14')}
                                    maxLength={30}
                                    value={this.state.email}
                                    onChange={(e)=> {this.setState({ email: e.currentTarget.value })}}>
                                </input>
                            </div>
                            
                            <div className='item-container column-two sendcode-container'>
                                <input
                                    className='input'
                                    id='code'
                                    name='code'
                                    placeholder={t('modals.SignupEmailModel.752457-15')}
                                    maxLength={6}
                                    value={this.state.code}
                                    onChange={(e)=> {this.setState({ code: e.currentTarget.value })}}>
                                </input>
                                {
                                    isSendEmail ? (
                                        <div className='countdown'>{count}{t('modals.SignupEmailModel.752457-16')}</div>
                                    ) : (
                                        <div className='sendcode-btn' onClick={this.onSendCodeClick}>{t('modals.SignupEmailModel.752457-17')}</div>
                                    )
                                }
                            </div>
                            <div className='item-container'>
                                <input
                                    id='pwd'
                                    name='pwd'
                                    type='password'
                                    placeholder={t('modals.SignupEmailModel.752457-18-0')}
                                    maxLength={24}
                                    value={this.state.pwd}
                                    onChange={(e) => {this.setState({ pwd: e.currentTarget.value}) }}>
                                </input>
                            </div>
                            <div className='item-container'>
                                <input
                                    id='pwdConfirm'
                                    name='pwdConfirm'
                                    type='password'
                                    placeholder={t('modals.SignupEmailModel.752457-18-1')}
                                    maxLength={24}
                                    value={this.state.pwdConfirm}
                                    onChange={ e => {this.setState({ pwdConfirm: e.currentTarget.value })}}>
                                </input>
                            </div>
                            <div className='item-container'>
                                <input
                                    placeholder={t('modals.SignupEmailModel.752457-19')}
                                    name="mobile"
                                    value={this.state.inviteCode}
                                    onChange={(e) => this.setState({inviteCode: e.target.value})}
                                    maxLength={6}/>
                            </div>
                            <div className='item-container section-title'>{t('modals.SignupEmailModel.752457-20')}</div>
                            <div className='item-container column-two'>
                                <input
                                    placeholder={t('modals.SignupEmailModel.752457-21')}
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={(e) => this.setState({lastName: e.target.value})}
                                    maxLength={16}/>
                                <input
                                    placeholder={t('modals.SignupEmailModel.752457-22')}
                                    name="lastName"
                                    value={this.state.firstName}
                                    onChange={(e) => this.setState({firstName: e.target.value})}
                                    maxLength={16}/>
                            </div>
                            <div className='item-container'>
                                <input
                                    placeholder={t('modals.SignupEmailModel.752457-23')}
                                    name="mobile"
                                    value={this.state.mobile}
                                    onChange={(e) => this.setState({mobile: e.target.value})}
                                    maxLength={16}/>
                            </div>
                            <div className='item-container'>
                                <input
                                    placeholder={t('modals.SignupEmailModel.752457-24')}
                                    name="company"
                                    value={this.state.company}
                                    onChange={(e) => this.setState({company: e.target.value})}
                                    maxLength={32}/>
                            </div>
                            <div className="item-container">
                                    <input
                                        // className="base-input-text"
                                        placeholder={t('modals.SignupEmailModel.752457-25')}
                                        name="company"
                                        value={this.state.companyShort}
                                        onChange={(e) => this.setState({companyShort: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            <button onClick={this.onSignUpClick}>
                                <label>{t('modals.SignupEmailModel.752457-26')}</label>
                            </button>
                        </div>
                    </div>
                    <MessageModal message={this.state.message} />
                    <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
                </div>
            </div>
        )
    }
}

export default withTranslation('translation', { withRef : true })(SignupEmailModel);