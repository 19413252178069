import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { BsFillXCircleFill } from "react-icons/bs";
import { Server } from "../../server/server";
import { isValidEmail } from "../util/util";
import AlertModal from "./AlertModal";
import './ChangeEmailModal.css';

class ChangeEmailModal  extends React.Component<ChangeEmailModalProps, any> {
    private timer: any = null
    private userInfo:any = null
    constructor (props: any) {
        super(props)

        this.state = {
            email: '',
            code: '',
            isSendEmail: false,
            count: 60,
            show: false,
            alert: ''
        }
    }
    componentDidMount(): void {

    }

    componentWillUnmount(): void {
        this.stopCountdown()
    }
    render(): React.ReactNode {
        const { t } = this.props
        const isSendEmail = this.state.isSendEmail
        const count = this.state.count
        if (!this.state.show) {
            return(<></>)
        }

        return (
            <div className="modal open">
                <div className="modal-content">
                    <button className="modal-close-button" onClick={this.hide}>
                        <BsFillXCircleFill />
                    </button>
                    <div className="modal-title">
                        <h3>{t('modals.ChangeEmailModal.136151-0')}</h3>
                    </div>
                    <div className="modal-main">
                        <form className="modal-form">
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.ChangeEmailModal.136151-1')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.ChangeEmailModal.136151-2')}
                                        name="email"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({email: e.target.value})}
                                        maxLength={32}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.ChangeEmailModal.136151-3')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.ChangeEmailModal.136151-3')}
                                        name="code"
                                        value={this.state.code}
                                        onChange={(e) => this.setState({code: e.target.value})}
                                        maxLength={8}/>
                                
                                {
                                isSendEmail ? (
                                    <div className='countdown'>{count}{t('modals.ChangeEmailModal.136151-4')}</div>
                                ) : (
                                    <div className='sendcode-btn' onClick={this.onSendCodeClick}>{t('modals.ChangeEmailModal.136151-5')}</div>
                                )
                                }
                                </div>
                            </div>
                            
                            <div>
                                <button className="base-button" type="button" onClick={this.handleSubmit}>{t('modals.ChangeEmailModal.136151-6')}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
            </div>
        )
    }

    hide = () => {
        this.setState({
            show: false
        })
        this.clear()
    }

    show = () => {
        this.setState({
            show: true
        }, ()=> {
            this.loadInfo()
        })
    }

    onSendCodeClick = () => {
        const { t } = this.props
        const email = this.state.email
        if (!email) {
            return this.setState({ alert: t('modals.ChangeEmailModal.136151-7') })
        }
        if(!isValidEmail(email)) {
            return this.setState({ alert: t('modals.ChangeEmailModal.136151-8') })
        }
        // 开启倒计时
        this.startCountdown()

        // 发送{t('modals.ChangeEmailModal.136151-3')}
        Server.email.sendCodeChangeEmail(email).then(res => {
            if (!res.success) {
                this.stopCountdown()
                return this.setState({alert: res.message})
            }
        }).catch(e => {
            console.log(e)
            this.stopCountdown()
            return this.setState({alert: t('modals.ChangeEmailModal.136151-9')})
        })
    }

    handleSubmit = () => {
        const { t } = this.props
        const email = this.state.email
        const code = this.state.code
        if (!email) {
            return this.setState({ alert: t('modals.ChangeEmailModal.136151-7') })
        }
        if(!isValidEmail(email)) {
            return this.setState({ alert: t('modals.ChangeEmailModal.136151-8') })
        }
        if (!code) {
            return this.setState({ alert: t('modals.ChangeEmailModal.136151-10') })
        }

        Server.user.changeEmail(email, code).then(res => {
            if(!res.success) {
                return this.setState({alert: res.message})
            }
            // 隐藏对话框
            this.hide()

            // 更新用户信息
            this.userInfo['email'] = email
            this.userInfo['name'] = email
            Server.user.setProfile(this.userInfo)

            // 更新成功后回调
            if(this.props.onChangeEmailSuccess) {
                this.props.onChangeEmailSuccess()
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                alert: t('modals.ChangeEmailModal.136151-11')
            })
        })
    }

    loadInfo = () => {
        Server.user.isLogin() && Server.user.getProfile().then(user => {
            // console.log(user)
            this.userInfo = user
            this.setState({
                email: user.email
            })
        }).catch(e => {
            console.log(e)
        })
    }

    clear = () => {
        this.setState({
            email: '',
            code: '',
            isSendEmail: false,
            count: 60,
            // show: false,
            alert: ''
        })

        this.resetTimer()
    }

    // 开启倒计时
    startCountdown = () => {
        this.setState({
            isSendEmail: true,
            count: 60
        })
        this.timer = setInterval(()=> {
            if (this.state.count > 1) {
                this.setState({
                    count: this.state.count - 1
                })
            } else {
                this.stopCountdown()
            }
        }, 1000)
    }

    // 停止倒计时
    stopCountdown = () => {
        this.setState({
            isSendEmail: false,
            count: 60
        })
        this.resetTimer()
    }

    // 清空计时器
    resetTimer = () => {
        if (this.timer) {
            clearInterval(this.timer)
        }
        this.timer = null
    }
}

export default withTranslation('translation', { withRef: true })(ChangeEmailModal)

interface ChangeEmailModalProps extends WithTranslation {
    onChangeEmailSuccess: Function
    ref: any
}