import qs from "qs"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { Server } from "../../server/server"
import { CaseOrTalent } from "../common/common"
import HtmlContainer from "../elements/HtmlContainer"
import ManualMatchedList from "../elements/ManualMatchedList"
import './TopicPage.css'

class TopicPage extends React.Component<WithTranslation, TopicPageState> {

    private type: number
    private topic: string

    constructor(props: any) {
        super(props)
        // console.log(window.location.search)
        const { search } = window.location
        const { type, topic } = qs.parse(search.replace(/^\?/, ''))
        // console.log(type, topic)
        this.type = Number(type)
        this.topic = String(topic)
        this.state = {
            loading: false,
            topicDataList: [],
        }
    }

    componentDidMount(): void {
        this.loadTopicData(this.topic)
    }

    render(): React.ReactNode {
        const { t } = this.props
        if (this.state.loading) {
            return (<div>{t('pages.TopicPage.160268-0')}</div>)
        }
        const type = this.type
        const topic = this.topic
        const topicDataList = this.state.topicDataList || []
        let list: any = []
        topicDataList.forEach(item => {
            const id = item.id
            const text = item.text
            const card = (
                <div key={id} className="topic-card">
                    <HtmlContainer key={'h-' + id} id={id} html={text} />
                    <ManualMatchedList key={'m-' + id} type={type} id={id} />
                </div>
            )
            list.push(card)
        });

        const title = t( (type == CaseOrTalent.CASE ? 'common.topic.case.': 'common.topic.talent.') + topic)

        return (
            <div className="main">
                <div className="topic-title">{title}</div>
                <div className='topic-list'>
                    {topicDataList.length == 0 && <div>{t('pages.TopicPage.160268-1')}</div>}
                    {topicDataList.length > 0 && <div>{list}</div>}
                </div>
            </div>
        )
    }

    async loadTopicData(topic: string) {
        this.setState({ loading: true })
        let res = null
        // 获取主题案件或要员列表
        if (CaseOrTalent.CASE == this.type) {
            res = await Server.case.getTopicCases(topic).catch((e) => { console.error(e); this.setState({ loading: false }) })
        } else {
            res = await Server.talent.getTopicTalents(topic).catch((e) => { console.error(e); this.setState({ loading: false }) })
        }

        if (!res || !res.success) {
            return this.setState({
                loading: false,
            })
        }

        this.setState({
            loading: false,
            topicDataList: res.data || []
        })
    }
}

export default withTranslation()(TopicPage)

interface TopicPageState {
    // 主题案件或要员列表
    topicDataList: TopicData[],
    // 数据加载标志位
    loading: boolean
}

interface TopicData {
    // 主题案件或要员的id
    id: string
    // 主题案件或要员的原文
    text: string
}