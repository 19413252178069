import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import zhCN from './locales/zh-CN.json';
import jaJP from './locales/ja-JP.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Language } from "./app/common/common";

const resources = {
    "ja": {
        translation: jaJP
    },
    "zh": {
        translation: zhCN
    }
};

const currentLocale = localStorage.getItem('lan') || Language.ja;

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: currentLocale, // 默认当前的语言环境
        lng: currentLocale, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        debug: false,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;