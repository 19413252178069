import React from "react";
import { Server } from '../../../server/server';
import CaseCard from './CaseCard';
import './MyCaseList.css';
import { WithTranslation, withTranslation } from "react-i18next";

/**
 * 组件-我的案件列表
 */
class MyCaseList extends React.Component<WithTranslation, MyCaseListState> {
    constructor(props:any) {
        super(props)
        this.state = {
            loading: false,
            cases: [],
            isPremiumUser: false,
        }
    }

    componentDidMount(): void {
        this.getCases()
    }

    render(): React.ReactNode {
        const { t } = this.props
        if(this.state.loading) {
            return (<div>{t('elements.case.MyCaseList.633056-0')}</div>)
        }

        const cases = this.state.cases || []
        // 当前登录用户是否为有效的付费用户
        const isPremiumUser = this.state.isPremiumUser
        let list = []
        for(let i = 0, len = cases.length; i<len; i++) {
            const casee = cases[i]
            const id = casee.id
            const talentText = casee.text
            const talentStatus = casee.status
            list.push(<CaseCard key={id} id={id} status={talentStatus} html={talentText} isPremiumUser={isPremiumUser} />)
        }

        return(
            <>
                <div className="my-case-title">{t('elements.case.MyCaseList.633056-1')}</div>
                <div className='my-case-list'>
                    {cases.length == 0 && <div>{t('elements.case.MyCaseList.633056-2')}</div>}
                    {cases.length > 0 && <div>{list}</div>}
                </div>
            </>
        )
    }

    getCases() {
        // 加载我的案件
        this.setState({loading: true})
        Server.case.getMyCases().then((res)=>{
            if (!res.success) {
                return this.setState({
                    loading: false,
                })
            }
            Server.user.getProfile().then(user => {
                const { premiumUser } = Server.user.isPremiumUser(user)
                this.setState({
                    cases: res.data || [],
                    isPremiumUser: premiumUser,
                    loading: false
                })
            })
        }).catch(e => {
            console.error(e)
            this.setState({
                loading: false
            })
        })
    }
}

export default withTranslation('translation', { withRef: true})(MyCaseList);

interface MyCaseListState {
    loading: boolean
    cases: any[]
    isPremiumUser: boolean
}
