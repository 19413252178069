import { Card, Col, Collapse, CollapseProps, Flex, Row } from "antd";
import parse from 'html-react-parser';
import qs from "qs";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Server } from "../../server/server";
import { GoodsCategory, UnlockType } from "../common/common";
import AlertModal from "../modals/AlertModal";
import ConfirmDialogModal from "../modals/ConfirmDialogModal";
import { YYYY_MM_DD, utc2Local } from "../util/date";

class PurchasePage extends React.Component<WithTranslation, PurchasePageState> {
    // 商品id
    // private gid:string
    // 商品区分（特定要员、特定案件、付费会员）
    private gtype: number
    // 要解锁的要员或案件区分（0解锁案件，1解锁要员）
    private unlockType: number
    // 操作完成后返回的url地址
    private backurl: string
    // 匹配id
    private matchId: string
    // 匹配的案件id
    private caseId: string
    // 匹配的要员id
    private talentId: string
    // 确认对话框引用
    private confirmDialogRef:any

    constructor(props: any) {
        super(props)
        const {search} = window.location
        const { gid, gtype, unlock, m, c, t, url } = qs.parse(search.replace(/^\?/, ''))
        // console.log(qs.parse(search.replace(/^\?/, '')))
        // this.gid = String(gid)
        this.gtype = gtype ? Number(gtype) : null
        this.unlockType = unlock ? Number(unlock): null
        this.matchId = m ? String(m) : null
        this.caseId = c ? String(c) : null
        this.talentId = t ? String(t) : null
        this.backurl = url ? String(url) : null
        console.log(this.backurl)
        this.state = {
            balanceAmount: 0,
            goodName: '',
            goodPrice: 0,
            goodExpired: '',
            caseOrTalentBrief: '',
            caseOrTalentHtml: '',
            loading: false,
            alert: ''
        }
        this.confirmDialogRef = React.createRef()
    }

    componentDidMount(): void {
        this.loadInfo()
    }

    render(): React.ReactNode {
        const { t } = this.props
        const balanceAmount = this.state.balanceAmount
        const html = this.state.caseOrTalentHtml
        const name = this.state.goodName
        const amount = this.state.goodPrice
        const expired = this.state.goodExpired

        let caseOrTalentStr = '', buyPremium = false
        let items: CollapseProps['items'] = null
        if(this.gtype == GoodsCategory.CASE) {
            caseOrTalentStr = t('pages.PurchasePage.765493-0')
        } else if(this.gtype == GoodsCategory.TALENT) {
            caseOrTalentStr = t('pages.PurchasePage.765493-1')
        }
        if (this.gtype == GoodsCategory.CASE || this.gtype == GoodsCategory.TALENT) {
            items = [{
                key: '1',
                label: name,
                children: <div>{parse(html)}</div>,
            }]
        } else if (this.gtype == GoodsCategory.PREMIUM) {
            buyPremium = true
        }
        
        return(
            <div className="main">
                <span>{t('pages.PurchasePage.765493-2')}</span>
                <hr style={{width:"100%"}}/>
                <Row gutter={3}>
                    <Col span={5}>{t('pages.PurchasePage.765493-3')}</Col>
                    <Col span={19} style={{fontWeight:"bold"}}>{name}</Col>
                </Row>
                {expired &&
                <Row gutter={3}>
                    <Col span={5}>{t('pages.PurchasePage.765493-4')}</Col>
                    <Col span={19} style={{fontWeight:"bold"}}>{expired}</Col>
                </Row>
                }
                <Row gutter={3}>
                    <Col span={5}>{t('pages.PurchasePage.765493-5')}</Col>
                    <Col span={19}>
                        <Flex vertical>
                            <span style={{fontWeight:"bold"}}>{amount}{t('pages.PurchasePage.765493-6')}</span>
                            {
                            (balanceAmount < amount) &&
                            <span>{t('pages.PurchasePage.765493-7')}{t('pages.PurchasePage.765493-8', {amount:balanceAmount})}<a href={`/balance?redirect=${encodeURIComponent(window.location.href)}`}>{t('pages.PurchasePage.765493-9')}</a>{t('pages.PurchasePage.765493-10')}</span>
                            }
                        </Flex>
                    </Col>
                </Row>
                <Flex style={{width: '100%'}} vertical={false} gap="large" justify="flex-end">
                    <button onClick={this.onCancelClick}>{t('pages.PurchasePage.765493-11')}</button>
                    <button onClick={this.onPurchaseClick} disabled={(balanceAmount < amount) ? true: false}>{t('pages.PurchasePage.765493-12')}</button>
                </Flex>
                <hr style={{width:"100%"}}/>
                {
                    caseOrTalentStr && 
                    <>
                    <Row gutter={3}>
                        <Col span={24}>
                        <span>{t('pages.PurchasePage.765493-13', {caseOrTalent:caseOrTalentStr})}</span>
                        </Col>
                    </Row>
                    <Row gutter={3}>
                        <Col span={24}>
                            <Collapse items={items} defaultActiveKey={['1']}></Collapse>
                        </Col>
                    </Row>
                    </>
                }
                {
                    buyPremium &&
                    <Row gutter={3}>
                        <Col span={24}>
                            <Card title={<div style={{textAlign:"center"}}>{t('pages.PurchasePage.765493-14')}</div>} hoverable size="default" bordered={false} >
                                <Flex style={{width:'100%'}} vertical align="left">
                                    <span>{t('pages.PurchasePage.765493-15')}</span>
                                    <span>{t('pages.PurchasePage.765493-16')}</span>
                                    <span>{t('pages.PurchasePage.765493-17')}</span>
                                </Flex>
                            </Card>
                        </Col>
                    </Row>
                }
                <ConfirmDialogModal ref={this.confirmDialogRef} />
                <AlertModal message={this.state.alert} button="OK" onClose={() => this.setState({ alert: '' })} />
            </div>
        )
    }

    loadInfo = () => {
        const gtype = this.gtype
        const unlockType = this.unlockType
        const caseId = this.caseId
        const talentId = this.talentId
        if(gtype) {
            this.loadGoodsInfo(gtype)
        }
        let id = null
        if(unlockType != null && unlockType == UnlockType.CASE && caseId) {
            id = caseId
        } else if(unlockType != null && unlockType == UnlockType.TALENT && talentId) {
            id = talentId
        }
        this.loadCaseOrTalentInfo(unlockType, id)

        this.loadUserAccount()
    }

    loadUserAccount = () => {
        Server.user.isLogin() && Server.account.balance().then(res => {
            // console.log(res)
            if (res.success && res.data) {
                const {amount, version} = res.data
                this.setState({
                    balanceAmount: Number(Number(amount).toFixed(0))
                })
            }
        }).catch(err => {
            console.error(err)
        })
    }

    loadGoodsInfo = (type: number) => {
        let callFun = null
        if (type == GoodsCategory.TALENT) {
            callFun = Server.goods.getPurchaseTalentGoods
        } else if (type == GoodsCategory.CASE) {
            callFun = Server.goods.getPurchaseCaseGoods
        } else if (type == GoodsCategory.PREMIUM) {
            callFun = Server.goods.getPurchasePremiumGoods
        }

        callFun && callFun().then(res => {
            if(res.success && res.data) {
                const { name, nameZh, amount, expired } = res.data
                this.setState({
                    goodName: name,
                    goodPrice: amount,
                    goodExpired: type == GoodsCategory.PREMIUM ? utc2Local(expired, YYYY_MM_DD): null
                })
            }
        }).catch(err => {
            console.error(err)
        })
        
    }

    loadCaseOrTalentInfo = (type: number, id:string) => {
        let callFun = null
        if (type == UnlockType.CASE) {
            callFun = Server.case.getCaseSampleInfo
        } else if (type == UnlockType.TALENT) {
            callFun = Server.talent.getTalentSampleInfo
        }
        callFun && callFun(id).then(res => {
            // console.log(JSON.stringify(res))
            if(res.success && res.data) {
                const { brief, html } = res.data
                this.setState({
                    caseOrTalentHtml: html,
                    caseOrTalentBrief: brief
                })
            }
        }).catch(err => {
            console.error(err)
        })
    }

    // 取消操作
    onCancelClick = () => {
        // window.history.back();
        window.history.replaceState(null, "", this.backurl);
        window.history.go(0);
    }

    // 确定购买
    onPurchaseClick = () => {
        const { t } = this.props
        const price = this.state.goodPrice
        this.confirmDialogRef.current.build({
            title: t('pages.PurchasePage.765493-18'),
            message: t('pages.PurchasePage.765493-19', {amount: price}),
            okText: t('pages.PurchasePage.765493-12'),
            okCallback: ()=> {
                this.onPurchaseCallback()
            },
            cancelText: t('pages.PurchasePage.765493-11'),
            cancelCallback: () => {
                
            }
        })
    }

    onPurchaseCallback = () => {
        const { t } = this.props
        const gtype = this.gtype
        const unlockType = this.unlockType
        const mid = this.matchId
        const caseId = this.caseId
        const talentId = this.talentId
        let callFun = null
        if (gtype == GoodsCategory.PREMIUM) {
            // 会员购买
            callFun = Server.user.activePermiumUser
        } else if(gtype == GoodsCategory.TALENT) {
            // 激活解锁匹配的要员
            callFun = Server.match.unlockTalent
        } else if (gtype == GoodsCategory.CASE) {
            // 激活解锁匹配的要员案件
            callFun = Server.match.unlockCase
        }

        callFun(mid).then(res => {
            // console.log(res)
            if(!res.success) {
                return this.setState({
                    alert: res.message
                })
            }

            this.setState({
                alert: t('pages.PurchasePage.765493-20')
            }, () => {
                window.history.replaceState(null, "", this.backurl);
                window.history.go(0);
            })

        }).catch((err) => {
            console.log(err)
            this.setState({
                alert: t('pages.PurchasePage.765493-21')
            })
        })
    }

}

export default withTranslation()(PurchasePage)

interface PurchasePageState {
    balanceAmount: number,
    goodName: string
    goodPrice: number
    goodExpired: string
    caseOrTalentBrief: string
    caseOrTalentHtml: string
    loading: boolean
    alert: string
}