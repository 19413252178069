import React from "react";
import { BsFillXCircleFill } from "react-icons/bs";
import { Server } from "../../server/server";
import AlertModal from "./AlertModal";
import "./ChangePasswordModal.css";
import { WithTranslation, withTranslation } from "react-i18next";

class ChangePasswordModal  extends React.Component<WithTranslation, any> {
    constructor (props: any) {
        super(props)
        this.state = {
            password: '',
            newPwd: '',
            newPwdSecond: '',
            show: false,
            alert: ''
        }
    }

    render(): React.ReactNode {
        const { t } = this.props
        if (!this.state.show) {
            return(<></>)
        }

        return (
            <div className="modal open">
                <div className="modal-content">
                    <button className="modal-close-button" onClick={this.hide}>
                        <BsFillXCircleFill />
                    </button>
                    <div className="modal-title">
                        <h3>{t('modals.ChangePasswordModal.594663-0')}</h3>
                    </div>
                    <div className="modal-main">
                        <form className="modal-form">
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.ChangePasswordModal.594663-1')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.ChangePasswordModal.594663-2')}
                                        name="password"
                                        type="password"
                                        value={this.state.password}
                                        onChange={(e) => this.setState({password: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.ChangePasswordModal.594663-3')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.ChangePasswordModal.594663-4')}
                                        name="newPwd"
                                        type="password"
                                        value={this.state.newPwd}
                                        onChange={(e) => this.setState({newPwd: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.ChangePasswordModal.594663-5')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.ChangePasswordModal.594663-6')}
                                        name="newPwdSecond"
                                        type="password"
                                        value={this.state.newPwdSecond}
                                        onChange={(e) => this.setState({newPwdSecond: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            </div>
                            <div>
                                <button className="base-button" type="button" onClick={this.handleSubmit}>{t('modals.ChangePasswordModal.594663-7')}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
            </div>
        )
    }

    hide = () => {
        this.setState({
            show: false
        })
    }

    show = () => {
        this.setState({
            show: true,
            password: '',
            newPwd: '',
            newPwdSecond: '',
            alert: ''
        })
    }

    handleSubmit = () => {
        const { t } = this.props
        const oldPwd = this.state.password
        const newPwd = this.state.newPwd
        const newPwdSecond = this.state.newPwdSecond
        if (!oldPwd || !newPwd || !newPwdSecond) {
            return
        }
        if (newPwd.length < 6) {
            return this.setState({ alert: t('modals.ChangePasswordModal.594663-8') })
        }
        if (newPwd != newPwdSecond) {
            return this.setState({ alert: t('modals.ChangePasswordModal.594663-9') })
        }

        Server.user.changePassword(oldPwd, newPwd).then(res => {
            if (!res.success) {
                return this.setState({ alert: res.message })
            }
            this.setState({ alert: t('modals.ChangePasswordModal.594663-10') })
        }).catch(e => {
            console.log(e)
            this.setState({
                alert: t('modals.ChangePasswordModal.594663-11')
            })
        })
    }
}

export default withTranslation('translation', { withRef : true })(ChangePasswordModal)