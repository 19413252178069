import { ConfigProvider, Empty, Select, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Server } from "../../server/server";
import { GoodsCurrency, OrderType, PayProvider, PayWay, TaxCategory } from "../common/common";
import { utc2Local } from "../util/date";
import './OrderPage.css';

class OrderPage extends React.Component<WithTranslation, OrderPageState> {
    
    constructor(props: WithTranslation) {
        super(props);
        this.state = {
            orderType: -1,
            orderList: [],
            loading: false,
        };
    }

    componentDidMount(): void {
        this.loadOrder(this.state.orderType)
    }

    render(): React.ReactNode {
        const { t } = this.props
        const orderList = this.state.orderList || []
        const menuItems = [
            {
                value: OrderType.NONE,
                label: t('pages.OrderPage.900384-0'),
            },
            {
                value: OrderType.RECHARGE,
                label: t('pages.OrderPage.900384-1'),
            },
            {
                value: OrderType.UNLOCK_CASE,
                label: t('pages.OrderPage.900384-2'),
            },
            {
                value: OrderType.UNLOCK_TALENT,
                label: t('pages.OrderPage.900384-3'),
            },
            {
                value: OrderType.PREMIUM,
                label: t('pages.OrderPage.900384-4'),
            },
        ];
        const tableEmptyRender = () => {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>{t('pages.OrderPage.900384-18')}</>}></Empty>
        }
        return (
            <div className="main">
                <Space>
                    <Select popupMatchSelectWidth={false} options={menuItems} defaultValue={-1} onChange={this.onFilterChange} />
                </Space>
                <ConfigProvider renderEmpty={tableEmptyRender}>
                    <Table 
                        rowKey={record => record.id}
                        dataSource={orderList} 
                        size="small" 
                        bordered 
                        // pagination={{ pageSize: 50 }} 
                        pagination={false}
                        // locale={{emptyText: t('pages.OrderPage.900384-18')}}
                        scroll={{ x: 'max-content'} }>
                        <Column 
                            title={t('pages.OrderPage.900384-5')} 
                            dataIndex="orderTime" 
                            key="orderTime" 
                            render={(_: any, record: DataType) => (
                                utc2Local(record.orderTime, 'YYYY/MM/DD HH:mm')
                            )}
                        />
                        <Column 
                            title={t('pages.OrderPage.900384-6')} 
                            dataIndex="orderType" 
                            key="orderType" 
                            render={(_: any, record: DataType) => {
                                switch(record.orderType) {
                                    case OrderType.RECHARGE:
                                        return t('pages.OrderPage.900384-1');
                                    case OrderType.UNLOCK_CASE:
                                        return t('pages.OrderPage.900384-2');
                                    case OrderType.UNLOCK_TALENT:
                                        return t('pages.OrderPage.900384-3');
                                    case OrderType.PREMIUM:
                                        return t('pages.OrderPage.900384-4');
                                    default:
                                        return '';
                                }
                            }}
                        />
                        <Column 
                            title={t('pages.OrderPage.900384-19')} 
                            dataIndex="payProvider" 
                            key="payProvider" 
                            render={(_: any, record: DataType) => {
                                const payWay = record.payWay
                                const payProvider = record.payProvider
                                if(payWay == PayWay.POINT) {
                                    // 梧桐果
                                    return t('pages.OrderPage.900384-12')
                                }
                                switch(payProvider) {
                                    case PayProvider.PAYPAL:
                                        return t('pages.OrderPage.900384-19-01');
                                    case PayProvider.WEPAY:
                                        return t('pages.OrderPage.900384-19-02');
                                    case PayProvider.ALIPAY:
                                        return t('pages.OrderPage.900384-19-03');
                                    case PayProvider.CREDIT:
                                        return t('pages.OrderPage.900384-19-04');
                                    default:
                                        return t('pages.OrderPage.900384-19-05');
                                }
                            }}
                        />
                        <Column 
                            title={t('pages.OrderPage.900384-7')} 
                            dataIndex="payWay" 
                            key="payWay" 
                            render={(_: any, record: DataType) => {
                                const payWay = record.payWay
                                if(payWay == PayWay.POINT) {
                                    // 梧桐果
                                    return t('pages.OrderPage.900384-12')
                                }
                                const payCurrency = record.payCurrency || GoodsCurrency.JPY
                                let unitStr = payCurrency == GoodsCurrency.JPY ? t('pages.OrderPage.900384-13'): t('pages.OrderPage.900384-14')
                                return unitStr
                            }}
                        />
                        <Column 
                            title={t('pages.OrderPage.900384-8')} 
                            dataIndex="taxCategory" 
                            key="taxCategory" 
                            render={(_: any, record: DataType) => {
                                const taxCategory = record.taxCategory
                                if (taxCategory === TaxCategory.NONE) { return '-'}
                                if (taxCategory === TaxCategory.EXCISE) return t('pages.OrderPage.900384-15')
                                return '-'
                            }}
                        />
                        <Column 
                            title={t('pages.OrderPage.900384-9')} 
                            dataIndex="payTaxAmount" 
                            key="payTaxAmount"
                            render={(_: any, record: DataType) => {
                                const payWay = record.payWay
                                if(payWay == PayWay.POINT) {
                                    // 梧桐果支付
                                    return '-'
                                }
                                const payCurrency = record.payCurrency || GoodsCurrency.JPY
                                let unitStr = payCurrency == GoodsCurrency.JPY ? t('pages.OrderPage.900384-13'): t('pages.OrderPage.900384-14')
                                const taxAmount = record.payTaxAmount
                                return Number(taxAmount).toLocaleString() + unitStr
                            }}
                        />
                        <Column 
                            title={t('pages.OrderPage.900384-10')} 
                            dataIndex="payTotalAmount" 
                            key="payTotalAmount"
                            render={(_: any, record: DataType) => {
                                const payWay = record.payWay
                                let unitStr = ''
                                if(payWay == PayWay.POINT) {
                                    unitStr = t('pages.OrderPage.900384-16')
                                } else {
                                    const payCurrency = record.payCurrency || GoodsCurrency.JPY
                                    unitStr = payCurrency == GoodsCurrency.JPY ? t('pages.OrderPage.900384-13'): t('pages.OrderPage.900384-14')
                                }
                                const payTotalAmount = record.payTotalAmount
                                return Number(payTotalAmount).toLocaleString() + unitStr
                            }}
                        />
                        <Column
                            title={t('pages.OrderPage.900384-11')}
                            key="action"
                            render={(_: any, record: DataType) => {
                                if (record.payWay === PayWay.POINT) {
                                    return ''
                                } else {
                                    const orderId = record.id.toString()
                                    const orderTimeStr = utc2Local(record.orderTime, 'YYYYMMDDHHmm')
                                    let fileName = '領収書_梧桐智配サービス料_' + orderTimeStr + '.pdf'
                                    return <a onClick={() => {this.downloadInvoice(orderId, fileName)}}>{t('pages.OrderPage.900384-17')}</a>
                                }
                            }}
                        />
                    </Table>
                </ConfigProvider>
            </div>
        );
    }

    loadOrder = (orderType: number) => {
        Server.order.getAllOrders(orderType).then((res: any) => {
            this.setState({
                orderList: res.data
            })
        })
    }

    onFilterChange = (value:any) => {
        // console.log('onFilterChange', value)
        this.setState({
            orderType: value
        })
        this.loadOrder(value)
    }

    downloadInvoice = (orderId:string, fileName:string) => {
        Server.order.downloadInvoice(orderId, fileName)
    }
}

export default withTranslation()(OrderPage)

interface OrderPageState {
    orderType: number;
    orderList: any[];
    loading: boolean
}

interface DataType {
    /** id */
    id: React.Key;
    /** 日期时间 */
    orderTime: Date;
    /** 订单类型 0充值（购买梧桐果）1激活特定要员（梧桐果支付） 2激活特定案件（梧桐果支付） 3年付费会员（梧桐果支付）*/
    orderType: number;
    /** 支付方式 0：梧桐果支付 1：第三方在线支付 2：其他 */
    payWay: number;
    /** 第三方支付时的第三方支付平台标识 0:Paypal、1:WePay 2:支付宝 3:信用卡 4:其他 */
    payProvider: number;
    /** 支付货币 0：梧桐果 1：日元 2：人民币 */
    payCurrency: number;
    /** 税种 -1:无 0:消费税 */
    taxCategory: number;
    /** 税额 */
    payTaxAmount: number;
    /** 总金额（商品价值+税额）*/
    payTotalAmount: number;
}