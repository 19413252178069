import React from "react";
import parse from 'html-react-parser';
import TalentActionBar from "./TalentActionBar";
import MatchedCaseList from "./MatchedCaseList";
import "./TalentCard.css";
import SharedQuillEditor from "../SharedQuillEditor";
import { subscribe, unsubscribe } from "../../util/event";
import { Server } from "../../../server/server";
import AlertModal from "../../modals/AlertModal";
import { TalentStatus } from "../../common/common";
import { WithTranslation, withTranslation } from "react-i18next";

/**
 * 组件-要员信息（包含匹配案件列表）
 */
class TalentCard extends React.Component<TalentCardProps, TalentCardState> {
    // 要员id
    private id: string
    private quillRef: any;
    constructor(props: TalentCardProps) {
        super(props)
        this.id = props.id
        this.state = {
            loading: false,
            talentText: props.html,
            talentStatus: props.status,
            talentResume: props.resume,
            matchedCases: [],
            editMode: false,
            alert: ''
        }
    }

    componentDidMount() {
        // 加载要员信息以及匹配的案件信息
        this.getTalentInfo(this.id)

        // 注册要员编辑回调事件
        subscribe('talent.' + this.id + '.edit', this.onEditEvent)
    }

    componentWillUnmount() {
        // 卸载要员编辑回调事件
        unsubscribe('talent.' + this.id + '.edit', this.onEditEvent)
    }

    render(): React.ReactNode {
        const { t } = this.props
        const id = this.props.id
        const isPremiumUser = this.props.isPremiumUser
        const talentStatus = this.state.talentStatus
        const talentResume = this.state.talentResume
        const talentText = this.state.talentText
        const matchedCases = this.state.matchedCases
        const editMode = this.state.editMode

        return (
            <div key={id} className="talent-card">
                <div className="talent-card-main">
                    <div className="talent">
                        {!editMode &&
                            <div className={`text-container ${talentStatus == TalentStatus.OPENED? 'opened': 'closed'}`}>
                                {parse(talentText)}
                            </div>
                        }
                        {
                            // 编辑器 && 
                            <div className="edit-container" style={{display: editMode ? 'block': 'none'}}>
                                <SharedQuillEditor key={'quill-' + id} placeholder={t('elements.talent.TalentCard.080374-0')} getRef={(ref: any) => this.quillRef = ref} />
                                <div className='talent-post-btns'>
                                    <button onClick={this.onPostClick}>{t('elements.talent.TalentCard.080374-1')}</button>
                                    <button onClick={this.onCancelClick}>{t('elements.talent.TalentCard.080374-2')}</button>
                                </div>
                            </div>
                        }
                        {!editMode &&
                            <TalentActionBar 
                                id={id} 
                                status={talentStatus} 
                                resume={talentResume} 
                                onChangeStatus={this.onChangeStatus}
                                onRefreshClick={this.onReloadTalent}
                            />
                        }
                    </div>
                    {
                        /* 匹配的案件列表 */
                        // talentStatus == TalentStatus.OPENED && 
                        <MatchedCaseList key={'match-'+id} list={matchedCases} isPremiumUser={isPremiumUser}></MatchedCaseList>
                    
                    }
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={() => this.setState({ alert: '' })} />
            </div>
        )
    }

    /**
     * 子组件ActionBar改变status后回调父组件改变父组件的status
     * 
     * @param status 
     */
    onChangeStatus = (status:number) => {
        this.setState({
            talentStatus: status
        })
    }

    /**
     * 重新加载本组件数据（要员信息及匹配的案件信息）
     */
    onReloadTalent = () => {
        console.log('onReloadTalent')
        this.getTalentInfo(this.id)
    }

    /**
     * 要员修改事件回调函数
     */
    onEditEvent = () => {
        this.onEditMode()
    }

    /**
     * 要员修改提交事件
     */
    onPostClick = () => {
        const { t } = this.props
        let html = this.quillRef.root.innerHTML
        let text = this.quillRef.getText()
        let realtext = text.trim().replace(/[\r\n]/g, '')
        // console.log("length:" + text.length)
        if(realtext.length == 0) {
            return this.setState({
                alert: t('elements.talent.TalentCard.080374-3')
            })
        }
        if(realtext.length >= 2000) {
            this.setState({
                alert: t('elements.talent.TalentCard.080374-4')
            })
        }
        this.updateTalent(this.id, text, html)
    }

    /**
     * 修改取消事件
     */
    onCancelClick = () => {
        this.offEditMode()
    }

    /**
     * 关闭编辑模式
     */
    offEditMode = () => {
        this.setState({
            editMode: false
        })
        this.quillRef.setText("")
    }

    /**
     * 开启编辑模式
     */
    onEditMode = () => {
        this.setState({
            editMode: true
        })
        var delta = this.quillRef.clipboard.convert(this.state.talentText);
        this.quillRef.setContents(delta)
    }

    /**
     * 获取要员详情（包含与要员匹配的案件列表）
     * 
     * @param id 要员id
     */
    getTalentInfo = (id:string) => {
        Server.talent.getTalent(id).then(res => {
            // console.log(JSON.stringify(res))
            if(res.success) {
                const { text, status, resume, matchedCases } = res.data
                // console.log(matchedCases)
                this.setState({
                    talentText: text,
                    talentStatus: status,
                    talentResume: resume,
                    matchedCases: matchedCases || []
                })
            }
        }).catch(err => {
            console.error(err)
        })
    }

    /**
     * 更新要员原文
     * 
     * @param id 要员id
     * @param html 要员原文（从富文本编辑器获取）
     */
    updateTalent = (id:string, text:string, html:string)=> {
        Server.talent.updateTalent(this.id, text, html).then(res => {
            if(!res.success) {
                return console.log(res.message)
            }
            this.setState({
                talentText: html
            })
            this.offEditMode()
            this.onReloadTalent()
        }).catch((err) => {
            console.error(err)
        })
    }

}

export default withTranslation()(TalentCard)

interface TalentCardProps extends WithTranslation {
    // 要员id
    id: string
    // 要员原文
    html: string
    // 要员状态
    status: number
    // 要员履历下载地址
    resume: string
    // 当前登录用户是否为付费会员
    isPremiumUser: boolean
}

interface TalentCardState {
    // 是否加载中
    loading: boolean
    // 要员匹配的案件列表
    matchedCases: any[]
    // 要员原文
    talentText: string,
    // 要员状态
    talentStatus: number
    // 要员履历下载地址
    talentResume: string
    // 是否为编辑模式（编辑模式可编辑要员原文）
    editMode: boolean
    // 错误提示
    alert: string
}