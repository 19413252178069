import dayjs from "dayjs";
import Api from "../app/api/Api";
import { UserType } from "../app/common/common";
import { YYYY_MM_DD, utc2Local } from "../app/util/date";
import { Service } from "./service";
import { isExpired, decodeToken } from "react-jwt";

export class UserService extends Service {

    public user: any

    constructor() {
        super()
    }

    public isLogin() {
        const token = localStorage.getItem('token');
        if (!token) return false;

        const decodedToken = decodeToken<any>(token);
        if (!decodedToken || !decodedToken.name) return false;

        const isTokenExpired = isExpired(token);
        if (isTokenExpired) return false;
        
        return true;
    }

    public logout() {
        this.user = null;
        localStorage.removeItem('token');
        this.notifyListeners('user-logout')
        // 跳转到首页
        // window.location.href = '/'
        window.history.replaceState(null, "", '/');
        window.history.go(0);
    }

    public async setProfile(user:any) {
        // console.log('setProfile')
        // console.log(this.listeners)
        this.user = user
        this.notifyListeners('user-profile-updated', this.user)
    }

    public async getProfile() {
        if(this.user) {
            // console.log('从内存中获取用户信息')
            return this.user
        }
        // 从服务器获取用户信息
        const res = await Api.get('/api/user/profile')
        if (res.success) {
            this.user = res.data
        }
        // console.log(this.user)
        return this.user
    }

    /**
     * 通过email地址和密码注册为新用户
     * 
     * @param user 用email地址注册的用户实体
     * @returns 注册成功后返回用户登录态token
     */
    async signupByEmail(user: SignupUserByEmail) : Promise<any> {
        return await Api.post('/api/user/signup', user)
    }

    /**
     * 忘记密码（重置）
     * 
     * @param email 找回密码的邮箱
     * @param code 邮箱验证码
     * @param pwd 重置后的密码
     * @returns 
     */
    public async resetPassword(email:string, code:string, pwd:string) {
        return await Api.post('/api/user/reset-password', {email:email, code:code, pwd:pwd})
    }

    /**
     * 修改密码
     * 
     * @param oldPwd 旧密码
     * @param newPwd 新密码
     * @returns 
     */
    public async changePassword(oldPwd:string, newPwd:string) {
        return await Api.post('/api/user/change-password', {oldPwd, newPwd})
    }

    /**
     * 修改邮箱
     * 
     * @param newEmail 新邮箱地址
     * @param code 邮箱验证吗
     * @returns 
     */
    public async changeEmail(newEmail: string, code: string) {
        return await Api.post('/api/user/change-email', {newEmail, code})
    }

    /**
     * 更新个人信息
     * 
     * @param firstName 名字
     * @param lastName 姓氏
     * @param company 公司全称
     * @param companyShort 公司简称
     * @param mobile 常用电话
     * @param token 登录token（可选）
     * @returns 
     */
    public async updageExtInfo(firstName: string, lastName: string, company: string, companyShort: string, mobile: string, token?:string) {
        return await Api.post('/api/user/update-extinfo', {firstName, lastName, company, companyShort, mobile}, token)
    }
    /**
     * 更新个人信息
     * 
     * @param email 邮箱
     * @param code 邮箱验证码
     * @param firstName 名字
     * @param lastName 姓氏
     * @param company 公司全称
     * @param companyShort 公司简称
     * @param mobile 常用电话
     * @param inviteCode 邀请码（可选）
     * @param token 登录token（可选）
     * @returns 
     */
    public async updateWxUserInfo(email:string, code:string, firstName: string, lastName: string, company: string, companyShort: string, mobile: string, inviteCode:string, token?:string) {
        return await Api.post('/api/user/update-wxuserinfo', { email, code, firstName, lastName, company, companyShort, mobile, inviteCode }, token)
    }

    /**
     * 绑定微信公众号openid到指定账户
     * 
     * @param email 绑定到的用户的邮箱
     * @param password 绑定到的用户的密码
     * @param openIdToken 以公众号openid注册的用户的token
     * @returns 
     */
    public async bindWxOpenId(email:string, password:string, openIdToken: string) {
        return await Api.post('/api/user/bind-wxopenid', { email, password }, openIdToken)
    }

    /**
     * 是否有效期内的付费会员
     * 
     * @returns 
     */
    public isPremiumUser(user:any) {
        const userType = user.type
        if (userType == UserType.PREMIUM) {
            const premiumUserExpired = utc2Local(user.expired, YYYY_MM_DD)
            const now = dayjs()
            const nowStr = now.format('YYYYMMDD')
            const expiredDate = dayjs(premiumUserExpired).format('YYYYMMDD')
            const diff = Number(nowStr) - Number(expiredDate)
            if (diff > 0) {
                return {
                    premiumUser: false
                }
            }
            // const diffDay = dayjs(expiredDate).diff(dayjs(nowStr),'day')
            // console.log("会员续费剩余天数：" + diffDay)
            const diffMonth = dayjs(expiredDate).diff(dayjs(nowStr),'month', true)
            // console.log("会员续费剩余月数：" + diffMonth)
            return {
                premiumUser: true,
                isAlmostExpired: diffMonth <= 1
            }
        }
        return {
            premiumUser: false
        }
    }

    /**
     * 激活付费会员
     * 
     * @returns 
     */
    public async activePermiumUser() {
        return await Api.post('/api/user/active-premium-user')
    }
}

interface SignupUserByEmail {
    // 邮箱地址
    email:string
    // 邮箱验证码
    code: string
    // 密码
    pwd: string
    // 确认密码
    pwdTwo: string
    // 邀请码
    inviteCode: string
    // 名
    firstName: string
    // 姓
    lastName: string
    // 电话
    mobile: string
    // 公司全称
    company: string
    // 公司简称
    companyShort: string
}