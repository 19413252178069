import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { BsFillXCircleFill } from "react-icons/bs";
import { Server } from "../../server/server";
import { isValidEmail } from "../util/util";
import AlertModal from "./AlertModal";
import './ResetPasswordModal.css';

class ResetPasswordModal  extends React.Component<WithTranslation, any> {
    private timer: any = null
    private userInfo:any = null
    constructor (props: any) {
        super(props)

        this.state = {
            email: '',
            code: '',
            newPwd: '',
            newPwdSecond: '',
            isSendEmail: false,
            count: 60,
            show: false,
            alert: ''
        }
    }
    componentDidMount(): void {

    }

    componentWillUnmount(): void {
        this.stopCountdown()
    }
    render(): React.ReactNode {
        const { t } = this.props
        const isSendEmail = this.state.isSendEmail
        const count = this.state.count
        if (!this.state.show) {
            return(<></>)
        }

        return (
            <div className="modal open">
                <div className="modal-content">
                    <button className="modal-close-button" onClick={this.hide}>
                        <BsFillXCircleFill />
                    </button>
                    <div className="modal-title">
                        <h3>{t('modals.ResetPasswordModal.252776-0')}</h3>
                    </div>
                    <div className="modal-main">
                        <form className="modal-form">
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.ResetPasswordModal.252776-1')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    {/* {Server.user.isLogin() ? (
                                        <span>{this.state.email}</span>
                                    ) : ( */}
                                        <input
                                        className="base-input-text"
                                        placeholder={t('modals.ResetPasswordModal.252776-2')}
                                        disabled={Server.user.isLogin()}
                                        name="email"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({email: e.target.value})}
                                        maxLength={32}/>
                                    {/* )} */}
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.ResetPasswordModal.252776-3')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.ResetPasswordModal.252776-3')}
                                        name="code"
                                        value={this.state.code}
                                        onChange={(e) => this.setState({code: e.target.value})}
                                        maxLength={8}/>
                                
                                {
                                isSendEmail ? (
                                    <div className="countdown">{count}{t('modals.ResetPasswordModal.252776-4')}</div>
                                ) : (
                                    <div className='sendcode-btn' onClick={this.onSendCodeClick}>{t('modals.ResetPasswordModal.252776-5')}</div>
                                )
                                }
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.ResetPasswordModal.252776-6')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.ResetPasswordModal.252776-7')}
                                        name="newPwd"
                                        type="password"
                                        value={this.state.newPwd}
                                        onChange={(e) => this.setState({newPwd: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.ResetPasswordModal.252776-8')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.ResetPasswordModal.252776-9')}
                                        name="newPwdSecond"
                                        type="password"
                                        value={this.state.newPwdSecond}
                                        onChange={(e) => this.setState({newPwdSecond: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            </div>
                            <div>
                                <button className="base-button" type="button" onClick={this.handleSubmit}>{t('modals.ResetPasswordModal.252776-10')}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
            </div>
        )
    }

    hide = () => {
        this.setState({
            show: false
        })
        this.clear()
    }

    show = () => {
        this.setState({
            show: true
        }, ()=> {
            this.loadInfo()
        })
    }

    onSendCodeClick = () => {
        const { t } = this.props
        const email = this.state.email
        if (!email) {
            return this.setState({ alert: t('modals.ResetPasswordModal.252776-11-0') })
        }
        if(!isValidEmail(email)) {
            return this.setState({ alert: t('modals.ResetPasswordModal.252776-11-1') })
        }

        // 开启倒计时
        this.startCountdown()

        // 发送验证码
        Server.email.sendCodeForgetPassword(email).then(res => {
            if (!res.success) {
                this.stopCountdown()
                return this.setState({alert: res.message})
            }
        }).catch(e => {
            console.log(e)
            this.stopCountdown()
            return this.setState({alert: t('modals.ResetPasswordModal.252776-12')})
        })
    }

    handleSubmit = () => {
        const { t } = this.props
        const email = this.state.email
        const code = this.state.code
        const newPwd = this.state.newPwd
        const newPwdSecond = this.state.newPwdSecond
        if (!email) {
            return this.setState({ alert: t('modals.ResetPasswordModal.252776-11-0') })
        }
        if(!isValidEmail(email)) {
            return this.setState({ alert: t('modals.ResetPasswordModal.252776-11-1') })
        }
        if (!code) {
            return this.setState({ alert: t('modals.ResetPasswordModal.252776-13') })
        }
        if (!newPwd || !newPwdSecond) {
            return this.setState({ alert: t('modals.ResetPasswordModal.252776-14') })
        }

        if (newPwd.length < 6) {
            return this.setState({ alert: t('modals.ResetPasswordModal.252776-15') })
        }
        if (newPwd != newPwdSecond) {
            return this.setState({ alert: t('modals.ResetPasswordModal.252776-16') })
        }

        Server.user.resetPassword(email, code, newPwd).then(res => {
            if(!res.success) {
                return this.setState({alert: res.message})
            }
            this.setState({
                alert: t('modals.ResetPasswordModal.252776-17'),
                email: Server.user.isLogin()? this.state.email : '',
                code: '',
                newPwd: '',
                newPwdSecond: '',
                isSendEmail: false,
                count: 60,
            })
            // this.clear()
            if(this.userInfo) {
                this.userInfo['hasPwd'] = true
                Server.user.setProfile(this.userInfo)
            }

        }).catch(e => {
            console.log(e)
            this.setState({
                alert: t('modals.ResetPasswordModal.252776-18')
            })
        })
    }

    loadInfo = () => {
        Server.user.isLogin() && Server.user.getProfile().then(user => {
            // console.log(user)
            this.userInfo = user
            this.setState({
                email: user.email
            })
        }).catch(e => {
            console.log(e)
        })
    }

    clear = () => {
        this.setState({
            email: '',
            code: '',
            newPwd: '',
            newPwdSecond: '',
            isSendEmail: false,
            count: 60,
            // show: false,
            // alert: ''
        })

        this.resetTimer()
    }

    // 开启倒计时
    startCountdown = () => {
        this.setState({
            isSendEmail: true,
            count: 60
        })
        this.timer = setInterval(()=> {
            if (this.state.count > 1) {
                this.setState({
                    count: this.state.count - 1
                })
            } else {
                this.stopCountdown()
            }
        }, 1000)
    }

    // 停止倒计时
    stopCountdown = () => {
        this.setState({
            isSendEmail: false,
            count: 60
        })
        this.resetTimer()
    }

    // 清空计时器
    resetTimer = () => {
        if (this.timer) {
            clearInterval(this.timer)
        }
        this.timer = null
    }
}

export default withTranslation('translation', { withRef : true })(ResetPasswordModal)