import Api from "../app/api/Api";
import { Service } from "./service";

export class UserAccountService extends Service {

    constructor(){
        super()
    }

    async balance() {
        // 获取当前用户的账户余额
        return await Api.get('/api/account/balance')
    }
}