import { DatePicker, Flex, TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillXCircleFill } from "react-icons/bs";
import { Server } from "../../server/server";
import { InterviewTimes, InterviewWay } from "../common/common";
import { YYYY_MM_DD_HH_MM, local2Utc, utc2Local } from "../util/date";
import AlertModal from "./AlertModal";
import './InterviewModal.css';
import './Modal.css';
import { WithTranslation, withTranslation } from "react-i18next";

class InterviewModal extends React.Component<InterviewModalProps, InterviewModalState> {
    
    // 匹配id
    private mid:string

    constructor(props: InterviewModalProps) {
        super(props)

        this.state = {
            times: InterviewTimes.FIRST,
            timesTotal: InterviewTimes.DEFALUT_TOTAL_TIMES,
            dateTime: null,
            way: InterviewWay.FACE2FACE,
            place: '',
            memo: '',

            title: '',
            timesTotalDisable: false,
            process: false,
            alert: ''
        }

        this.mid = props.mid
    }

    componentDidMount() {
        const {mid} = this.props
        this.loadInterview(mid, InterviewTimes.NONE, -1)
    }

    render(): React.ReactNode {
        const { t } = this.props
        if(!this.props.open){
            return (<></>);
        }
        const times = this.state.times
        const title = [t('modals.InterviewModal.588036-0'),t('modals.InterviewModal.588036-1'), t('modals.InterviewModal.588036-2')][times-1]
        const timesTotal = this.state.timesTotal
        const timesTotalDisable = this.state.timesTotalDisable
        const dateTime = this.state.dateTime
        return(
            <>
            <div className="modal open">
                <div className="modal-content">
                    <button className="modal-close-button" onClick={()=>this.props.onClose()}>
                        <BsFillXCircleFill />
                    </button>
                    <div className="title">
                        <h3>{title}</h3>
                    </div>
                    <div>
                        <form className="interview-form">
                            <div className="row form-item">
                                <div className="col form-item-label">
                                    <label className="form-item-required" title="">{t('modals.InterviewModal.588036-3')}</label>
                                </div>
                                <div className="col form-item-control-wrapper">
                                    <div className="form-item-control">
                                        <span className="form-item-children">
                                            {timesTotalDisable ? (
                                                <Flex gap="small" align="start" vertical={false}>
                                                    <select className="base-input-text" style={{width:'50%'}} name="times" value={times} onChange={this.handleTimesChange}>
                                                    {this.renderTimesOptions(timesTotal)}
                                                    </select>
                                                    <div>{t('modals.InterviewModal.588036-4')}{timesTotal}{t('modals.InterviewModal.588036-5')}</div>
                                                </Flex>
                                            ) : (
                                                <Flex gap="small" align="start" vertical={false}>
                                                    <select className="base-input-text" name="times" value={times} onChange={this.handleTimesChange}>
                                                        {this.renderTimesOptions(timesTotal)}
                                                    </select>
                                                    <select className="base-input-text" name="timesTotal" value={timesTotal} onChange={this.handleTimesTotalChange}>
                                                        <option value={1}>{t('modals.InterviewModal.588036-6')}</option>
                                                        <option value={2}>{t('modals.InterviewModal.588036-7')}</option>
                                                        <option value={3}>{t('modals.InterviewModal.588036-8')}</option>
                                                    </select>
                                                </Flex>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-item">
                                <div className="col form-item-label">
                                    <label className="form-item-required" title="">{t('modals.InterviewModal.588036-9')}</label>
                                </div>
                                <div className="col form-item-control-wrapper">
                                    <div className="form-item-control">
                                        {/* <span className="form-item-children"> */}
                                        <Flex gap="small" align="start" vertical={false}>
                                            <DatePicker format="YYYY/MM/DD" size="small" value={dateTime} placeholder={t('modals.InterviewModal.588036-10')} minDate={dayjs()} onChange={this.onDateChange}/>
                                            <TimePicker format="HH:mm" size="small" value={dateTime} needConfirm={false} placeholder={t('modals.InterviewModal.588036-11')} onChange={this.onTimeChange}/>
                                        </Flex>
                                        {/* </span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row form-item">
                                <div className="col form-item-label">
                                    <label className="form-item-required" title="">{t('modals.InterviewModal.588036-12')}</label>
                                </div>
                                <div className="col form-item-control-wrapper">
                                    <div className="form-item-control">
                                        <span className="form-item-children">
                                            <select className="base-input-text" name="way" value={this.state.way} onChange={this.handleWayChange}>
                                                <option value={1}>{t('modals.InterviewModal.588036-13')}</option>
                                                <option value={2}>{t('modals.InterviewModal.588036-14')}</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-item">
                                <div className="col form-item-label">
                                    <label className="form-item-required" title="">{t('modals.InterviewModal.588036-15')}</label>
                                </div>
                                <div className="col form-item-control-wrapper">
                                    <div className="form-item-control">
                                        <span className="form-item-children">
                                            <textarea className="base-input-text" placeholder={t('modals.InterviewModal.588036-16')} name="place" rows={3} value={this.state.place} onChange={this.onPlaceChange} maxLength={300}></textarea>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-item">
                                <div className="col form-item-label">
                                    <label className="form-item-normal" title="">{t('modals.InterviewModal.588036-17')}</label>
                                </div>
                                <div className="col form-item-control-wrapper">
                                    <div className="form-item-control">
                                        <span className="form-item-children">
                                            <textarea className="base-input-text" placeholder={t('modals.InterviewModal.588036-18')} name="place" rows={3} value={this.state.memo} onChange={this.onMemoChange} maxLength={300}></textarea>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button className="base-button" type="button" onClick={this.handleSubmit} disabled={this.state.process}>{t('modals.InterviewModal.588036-19')}</button>
                            </div>
                        </form>
                    </div>
                    
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
            </div>
            </>
        )
    }

    renderTimesOptions(size: number) {
        const { t } = this.props
        let options = []
        for(let i = 0; i < size; i++) {
            const value = i+1
            options.push(<option key={i} value={value}>{t('modals.InterviewModal.588036-20', {times: value})}</option>)
        }
        return options
    }

    handleTimesChange = (e:any) => {
        const times = e.target.value
        this.setState({
            times: times
        }, () => {
            this.loadInterview(this.mid, this.state.times, -1)
        })
    }

    handleTimesTotalChange = (e: any) => {
        this.setState({
            timesTotal: e.target.value,
            times: InterviewTimes.FIRST
        }, () => {
            this.loadInterview(this.mid, this.state.times, this.state.timesTotal)
        })
    }

    onDateChange = (date: Dayjs) => {
        // console.log(date)
        this.setState({
            dateTime: date
        })
    }

    onTimeChange = (date: Dayjs) => {
        // console.log(date)
        this.setState({
            dateTime: date
        })
    }

    handleWayChange = (e:any) => {
        // console.log(e.target.value)
        this.setState({
            way: e.target.value
        })
    }
    onPlaceChange = (e:any) => {
        // console.log(e.target.value)
        this.setState({
            place: e.target.value
        })
    }
    onMemoChange = (e:any) => {
        // console.log(e.target.value)
        this.setState({
            memo: e.target.value
        })
    }

    handleSubmit = () => {
        const { t } = this.props
        let mid = this.mid
        let times = this.state.times
        let timesTotal = this.state.timesTotal
        const dateTime = this.state.dateTime
        const way = this.state.way
        const place = this.state.place
        const memo = this.state.memo
        if (!times || !timesTotal || !dateTime || !way || !place) {
            return this.setState({
                alert: t('modals.InterviewModal.588036-21')
            })
        }
        if(place && place.length > 300) {
            return this.setState({
                alert: t('modals.InterviewModal.588036-22')
            })
        }
        if(memo && memo.length > 300) {
            return this.setState({
                alert: t('modals.InterviewModal.588036-23')
            })
        }
        this.setState({process: true})
        const dateTimeStr = dateTime.format(YYYY_MM_DD_HH_MM)
        // console.log("面试时间：", dateTimeStr)
        // console.log("面试时间UTC：", local2Utc(dateTime.toDate()))
        const interview = {
            timesTotal,
            times,
            time: local2Utc(dateTime.toDate()), 
            way: way,
            place: place,
            memo: memo
        }
        // console.log(JSON.stringify(interview))
        Server.match.updateMatchedInterview(mid, interview).then(res => {
            if(!res.success) {
                return this.setState({
                    alert: res.message,
                    process: false
                })
            }
            const isUpdate = res.data.isUpdate
            // console.log("interview is update:" + isUpdate)
            this.setState({
                // alert: '提交成功',
                dateTime: null,
                way: InterviewWay.FACE2FACE,
                place: "",
                memo: "",
                process: false
            }, () => {
                const data = {times, timesTotal, dateTimeStr, way, place, memo, isUpdate}
                this.props.onSubmitCallback(data)
            })
            // 关闭本窗口
            this.props.onClose()
        }).catch((err) => {
            console.error(err)
            this.setState({
                alert: t('modals.InterviewModal.588036-24'),
                process: false
            })
        })
    }

    clear() {
        this.setState({
            // date: '',
            // time: '',
            dateTime: null,
            way: InterviewWay.FACE2FACE,
            place: '',
            memo: '',
            // timesTotalDisable: false
        })
    }

    /**
     * 获取已有的面试信息
     * 
     * @param mid 匹配id
     * @param currentTimes 面试次数
     * @param manualTimesTotal 手动指定的总次数 -1代表未手动指定总次数
     */
    loadInterview = (mid:string, currentTimes:number, manualTimesTotal:number) => {
        this.clear()
        if(currentTimes == InterviewTimes.NONE) {
            currentTimes = InterviewTimes.FIRST
        }
        if (!mid || (currentTimes != InterviewTimes.FIRST && currentTimes != InterviewTimes.SECOND && currentTimes != InterviewTimes.THIRD)) {
            return
        }
        Server.match.getMatchedInterview(mid, currentTimes).then((res) => {
            // console.log(JSON.stringify(res))
            if(!res.success) {
                return
            }
            if (!res.data) {
                return this.clear()
            }
            
            const {timesTotal, times, time, way, place, memo} = res.data
            const dateTime = dayjs(utc2Local(time, YYYY_MM_DD_HH_MM))
            this.setState({
                times: times,
                timesTotal: manualTimesTotal > 0 ? manualTimesTotal : (timesTotal || InterviewTimes.DEFALUT_TOTAL_TIMES),
                timesTotalDisable: timesTotal!=null && timesTotal > 0,
                dateTime: dateTime,
                way: way,
                place: place || "",
                memo: memo || ""
            })
        }).catch((err)=> {
            console.error(err)
        })
    }

}

export default withTranslation('translation', { withRef : true })(InterviewModal)

interface InterviewModalProps extends WithTranslation {
    // 匹配id
    mid: string
    // 是否打开对话框
    open: boolean
    // 面试次数：1第一次，2第二次
    // times: number
    // 成功提交回调函数
    onSubmitCallback: Function
    // 关闭回调函数
    onClose: Function
    ref: any
}

interface InterviewModalState {
    // 面试次数
    times: number
    // 总面试次数
    timesTotal: number
    // 面试时间
    dateTime: Dayjs
    // 面试方式
    way: number
    // 面试地点或链接
    place: string
    // 备注信息
    memo: string

    // 标题
    title: string
    // 总面试次数是否可用
    timesTotalDisable: boolean
    // 是否处理中
    process: boolean
    alert: string
}