import React from "react"
import { Server } from "../../../server/server"
import { TalentStatus } from "../../common/common"
import AlertModal from "../../modals/AlertModal"
import { publish } from "../../util/event"
import "./TalentActionBar.css"
import { WithTranslation, withTranslation } from "react-i18next"
import ConfirmDialogModal from "../../modals/ConfirmDialogModal"

/**
 * 组件-要员操作（上传履历书、下载履历书、公开、关闭、修改）
 */
class TalentActionBar extends React.Component<TalentActionBarProps, TalentActionBarState> {
    private talentId: string
    private talentStatus: number
    private talentResumeUrl: string
    private fileInputRef: any
    private confirmDialogRef: any
    constructor (props: any) {
        super(props)
        this.talentId = props.id
        this.talentStatus = props.status
        this.talentResumeUrl = props.resume

        this.state = {
            // 要员状态
            talentStatus: this.talentStatus,
            // 要员履历书下载地址
            talentResumeUrl: this.talentResumeUrl,
            
            alert: ''
        }

        this.fileInputRef = React.createRef();
        this.confirmDialogRef = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps: Readonly<TalentActionBarProps>, prevState: Readonly<TalentActionBarState>, snapshot?: any): void {
        // console.log('componentDidUpdate')
    }

    render(): React.ReactNode {
        const { t } = this.props
        // 履历书下载地址
        const resumeUrl = this.state.talentResumeUrl
        // 要员当前状态
        const status = this.state.talentStatus
        return (
            <div key={this.talentId} className="talent-action-toolbar">
                <div className="left">
                {resumeUrl && status == TalentStatus.OPENED &&
                    <>
                    <button onClick={this.onDownloadResumeClick}>{t('elements.talent.TalentActionBar.252702-0')}</button>
                    <div>
                        <input style={{display: "none"}} type="file" ref={this.fileInputRef} onChange={this.onFileChange} onClick={this.handleClick} accept=".pdf" />
                        <button onClick={e => this.fileInputRef.current && this.fileInputRef.current.click()}>{t('elements.talent.TalentActionBar.252702-1')}</button>
                    </div>
                    </>
                }
                {!resumeUrl && status == TalentStatus.OPENED &&
                    <>
                    <div>
                        <input style={{display: "none"}} type="file" ref={this.fileInputRef} onChange={this.onFileChange} onClick={this.handleClick} accept=".pdf" />
                        <button onClick={e => this.fileInputRef.current && this.fileInputRef.current.click()}>{t('elements.talent.TalentActionBar.252702-2')}</button>
                    </div>
                    </>
                }
                </div>
                <div className="right">
                {/* 公开 */}
                {status == TalentStatus.OPENED &&
                    <>
                    <button onClick={this.onRefreshClick}>{t('elements.talent.TalentActionBar.252702-3')}</button>
                    <button onClick={this.onCloseClick}>{t('elements.talent.TalentActionBar.252702-4')}</button>
                    <button onClick={this.onUpdateClick}>{t('elements.talent.TalentActionBar.252702-5')}</button>
                    </>
                }
                {/* 关闭 */}
                {status == TalentStatus.CLOSED &&
                    <>
                    <button onClick={this.onOpenClick}>{t('elements.talent.TalentActionBar.252702-6')}</button>
                    </>
                }
                </div>
                <ConfirmDialogModal ref={this.confirmDialogRef} />
                <AlertModal message={this.state.alert} button="OK" onClose={() => this.setState({ alert: '' })} />
            </div>
        )
    }

    handleClick = (event: any) => {
        const { target = {} } = event || {};
        target.value = "";
    }
    onFileChange = (event:any) => {
        // 上传履历书
        const { t } = this.props
        const file = event.target.files[0]
        // console.log(file)
        if (file == undefined || !file) return
        const formData = new FormData();
        formData.append("file", file, file.name)
        formData.append('talentId', this.talentId)

        Server.talent.uploadResume(formData).then(res => {
            if(res.success) {
                // console.log(res.data)
                // this.props.onRefreshClick()
                this.setState({
                    talentResumeUrl: res.data,
                    alert: t('elements.talent.TalentActionBar.252702-7')
                })
            } else {
                let msg = res.message
                if (res.message === 'file.upload.size.max') {
                    msg = t('file.upload.size.max')
                } else if (res.message === 'file.upload.type.unsupport') {
                    msg =  t('file.upload.type.unsupport')
                }
                this.setState({
                    alert: msg
                })
            }
        })
    }

    onDownloadResumeClick = () => {
        // 下载履历书 
        const { t } = this.props
        const resumeUrl = this.state.talentResumeUrl
        Server.talent.downloadResume2(resumeUrl).catch(err => {
            this.setState({
                alert: t('elements.talent.TalentActionBar.252702-8')
            })
        })
    }

    onCloseClick = () => {
        // 关闭
        const { t } = this.props
        // 弹出确认对话框
        this.confirmDialogRef.current.build({
            title: t('elements.talent.TalentActionBar.252702-9'),
            message: t('elements.talent.TalentActionBar.252702-10'),
            okText: t('elements.talent.TalentActionBar.252702-11'),
            okCallback: ()=> {
                this.onCloseCallback()
            },
            cancelText: t('elements.talent.TalentActionBar.252702-12'),
            cancelCallback: () => {
                
            }
        })
    }

    onCloseCallback = () => {
        Server.talent.closeTalent(this.talentId).then(res => {
            if(res.success) {
                this.setState({
                    talentStatus: TalentStatus.CLOSED
                })
                this.props.onChangeStatus(TalentStatus.CLOSED)
            }
        }).catch(err => {
            console.error(err)
        })
    }

    onOpenClick = () => {
        // 公开
        Server.talent.openTalent(this.talentId).then(res => {
            if(res.success) {
                this.setState({
                    talentStatus: TalentStatus.OPENED
                })
                this.props.onChangeStatus(TalentStatus.OPENED)
            }
        }).catch(err => {
            console.error(err)
        })
        
    }

    onUpdateClick = () => {
        // 修改
        const id = this.talentId
        publish('talent.' + id + '.edit')
    }

    onRefreshClick = () => {
        // 刷新
        this.props.onRefreshClick()
    }

}

export default withTranslation()(TalentActionBar)

interface TalentActionBarProps extends WithTranslation {
    // 要员id
    id: string
    // 要员状态
    status: number
    // 要员履历下载地址
    resume: string
    // 要员状态改变事件
    onChangeStatus: Function
    // 点击刷新事件
    onRefreshClick: Function
}

interface TalentActionBarState {
    talentStatus: number
    talentResumeUrl: string
    alert: string
}