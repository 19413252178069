import Api from "../app/api/Api";
import { Service } from "./service";

export class EmailService extends Service {
    constructor() {
        super();
    }

    /**
     * 发送邮箱验证码(用户注册用)
     * 
     * @param email 邮箱地址 
     */
    async sendCode(email: string) {
        return await Api.post('/api/mail/send-captcha', {email})
    }

    /**
     * 发送邮箱验证码(忘记密码用)
     * 
     * @param email 邮箱地址
     * @returns 
     */
    async sendCodeForgetPassword(email: string) {
        return await Api.post('/api/mail/send-captcha-resetpasword', {email})
    }

    /**
     * 发送邮箱验证码(更换邮箱用)
     * 
     * @param email 邮箱地址
     * @returns 
     */
    async sendCodeChangeEmail(email: string) {
        return await Api.post('/api/mail/send-captcha-changeemail', {email})
    }
}