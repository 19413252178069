import React from "react";
import { Server } from "../../server/server";
import AlertModal from "../modals/AlertModal";
import './FeedbackPage.css';
import { WithTranslation, withTranslation } from "react-i18next";
import { isValidEmail } from "../util/util";

class FeedbackPage extends React.Component<WithTranslation, any> {

    constructor(props: any) {
        super(props)

        this.state = {
            email: '',
            content: '',
            
            process: false,
            alert: ''
        }
    }

    componentDidMount(): void {
        Server.user.addEventListener('user-login', this.onUserLogin);
        Server.user.addEventListener('user-logout', this.onUserLogout);

        Server.user.isLogin() && Server.user.getProfile().then(user => {
            this.setState({
                email: user.email || '',
                hasEmail: (user.email ? true: false)
            })
        })
    }

    componentWillUnmount(): void {
        Server.user.removeEventListener('user-login', this.onUserLogin);
        Server.user.removeEventListener('user-logout', this.onUserLogout);
    }

    render(): React.ReactNode {
        const { t } = this.props
        let isLogin = Server.user.isLogin()
        if (!isLogin) {
            return <></>
        }

        const email = this.state.email
        const hasEmail = this.state.hasEmail
        const content = this.state.content
        
        return (
            <div className="main">
                <div className="feedback-page-header">
                    {/* <div className="title"><h3>反馈</h3></div> */}
                </div>
                <div className="profile-page-container">
                    <div>
                        <form className="page-form">
                            <div className="page-form-item">
                                <div className="page-form-item-label">
                                    <label className="required" title="">{t('pages.FeedbackPage.424806-0')}</label>
                                </div>
                                <div className="page-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('pages.FeedbackPage.424806-1')}
                                        name="email"
                                        value={email}
                                        disabled={hasEmail}
                                        onChange={(e) => this.setState({email: e.target.value})}
                                        maxLength={32}/>
                                </div>
                            </div>
                            
                            <div className="page-form-item">
                                <div className="page-form-item-label">
                                    <label className="required">{t('pages.FeedbackPage.424806-2')}</label>
                                </div>
                                <div className="page-form-item-control">
                                    <textarea 
                                        className="base-input-text" 
                                        placeholder={t('pages.FeedbackPage.424806-3')} 
                                        name="place" 
                                        rows={8} 
                                        value={content} 
                                        onChange={(e) => this.setState({content: e.target.value})}
                                        maxLength={500}></textarea>
                                </div>
                            </div>
                            <div>
                                <button className="base-button" type="button" onClick={this.onSubmitClick} disabled={this.state.process}>{t('pages.FeedbackPage.424806-4')}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={() => this.setState({ alert: '' })} />
            </div>
        )
    }

    // 用户登录回调函数
    onUserLogin = () => {
        // this.forceUpdate()
    }

    // 用户退出登录回调函数
    onUserLogout = () => {
        this.forceUpdate()
    }

    onSubmitClick =() => {
        const { t } = this.props
        const email = this.state.email
        const content = this.state.content
        // if (!email || !content) {
        //     return
        // }
        if(!isValidEmail(email)) {
            return this.setState({ alert: t('pages.FeedbackPage.424806-7') })
        }
        if(!content) {
            return this.setState({ alert: t('pages.FeedbackPage.424806-8') })
        }
        if(content.length > 500) {
            return this.setState({ alert: t('pages.FeedbackPage.424806-9') })
        }

        this.setState({ process: true })

        Server.app.feedback(email, content).then(res => {
            if (!res.success) {
                return this.setState({
                    alert: t('pages.FeedbackPage.424806-5'),
                    process: false
                })
            }
            this.setState({
                alert: t('pages.FeedbackPage.424806-6'),
                process: false,
                content: ''
            })
        })
    }
}

export default withTranslation()(FeedbackPage)