import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { Server } from "../../../server/server"
import { CaseStatus } from "../../common/common"
import { publish } from "../../util/event"
import "./CaseActionBar.css"
import ConfirmDialogModal from "../../modals/ConfirmDialogModal"

/**
 * 组件-要员操作（刷新、公开、关闭、修改）
 */
class CardActionBar extends React.Component<CardActionBarProps, CardActionBarState> {
    private caseId: string
    private caseStatus: number
    private confirmDialogRef: any
    constructor (props: any) {
        super(props)
        this.caseId = props.id
        this.caseStatus = props.status

        this.state = {
            // 案件状态
            caseStatus: this.caseStatus
        }
        this.confirmDialogRef = React.createRef();
    }

    componentDidMount() {
    }

    // componentDidUpdate(prevProps: Readonly<CardActionBarProps>, prevState: Readonly<CardActionBarState>, snapshot?: any): void {
    //     if(prevProps.status != this.props.status) {
    //         this.caseStatus = this.props.status
    //         this.setState({
    //             caseStatus: this.props.status
    //         })
    //     }
    // }

    render(): React.ReactNode {
        const { t } = this.props
        // 案件当前状态
        const status = this.state.caseStatus
        return (
            <div key={this.caseId} className="case-action-toolbar">
                {/* 公开 */}
                {status == CaseStatus.OPENED &&
                    <>
                    <button onClick={this.onRefreshClick}>{t('elements.case.CardActionBar.btn-0')}</button>
                    <button onClick={this.onCloseClick}>{t('elements.case.CardActionBar.btn-1')}</button>
                    <button onClick={this.onUpdateClick}>{t('elements.case.CardActionBar.btn-2')}</button>
                    {/* <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}><BsEyeSlash /></div>
                    <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}><BsArrowRepeat /></div>
                    <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}><BsPencilSquare /></div> */}
                    </>
                }
                {/* 关闭 */}
                {status == CaseStatus.CLOSED &&
                    <>
                    <button onClick={this.onOpenClick}>{t('elements.case.CardActionBar.btn-3')}</button>
                    </>
                }
                <ConfirmDialogModal ref={this.confirmDialogRef} />
            </div>
        )
    }

    onCloseClick = () => {
        // 关闭
        const { t } = this.props
        // 弹出确认对话框
        this.confirmDialogRef.current.build({
            title: t('elements.case.CardActionBar.dialog01.title'),
            message: t('elements.case.CardActionBar.dialog01.msg'),
            okText: t('elements.case.CardActionBar.dialog01.ok'),
            okCallback: ()=> {
                this.onCloseCallback()
            },
            cancelText: t('elements.case.CardActionBar.dialog01.cancel'),
            cancelCallback: () => {
                
            }
        })
    }

    onCloseCallback = () => {
        Server.case.closeCase(this.caseId).then(res => {
            if(res.success) {
                this.setState({
                    caseStatus: CaseStatus.CLOSED
                })
                this.props.onChangeStatus(CaseStatus.CLOSED)
            }
        }).catch(err => {
            console.error(err)
        })
    }

    onOpenClick = () => {
        // 公开
        Server.case.openCase(this.caseId).then(res => {
            if(res.success) {
                this.setState({
                    caseStatus: CaseStatus.OPENED
                })
                this.props.onChangeStatus(CaseStatus.OPENED)
            }
        }).catch(err => {
            console.error(err)
        })
        
    }

    onUpdateClick = () => {
        // 修改
        const id = this.caseId
        publish('case.' + id + '.edit')
    }

    onRefreshClick = () => {
        // 刷新
        this.props.onRefreshClick()
    }

}

export default withTranslation()(CardActionBar)
interface CardActionBarProps extends WithTranslation {
    // 案件id
    id: string
    // 案件状态
    status: number
    // 案件状态改变事件
    onChangeStatus: Function
    // 点击刷新事件
    onRefreshClick: Function
}

interface CardActionBarState {
    // 案件状态
    caseStatus: number
}