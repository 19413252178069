import { Card, Col, Flex, Row } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { BsPencilFill } from "react-icons/bs";
import { Server } from "../../server/server";
import { GoodsCategory } from "../common/common";
import AlertModal from "../modals/AlertModal";
import ChangeEmailModal from "../modals/ChangeEmailModal";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import EditUserInfoModal from "../modals/EditUserInfoModal";
import ResetPasswordModal from "../modals/ResetPasswordModal";
import { YYYY_MM_DD, utc2Local } from "../util/date";
import './UserProfilePage.css';

class UserProfilePage extends React.Component<WithTranslation, any> {

    private changeEmailModalRef: any
    private changePasswordModalRef: any
    private resetPasswordModalRef: any
    private editUserInfoModalRef: any
    constructor(props: any) {
        super(props)

        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            mobile: '',
            company: '',
            companyShort: '',
            isWxMpUser: false,
            openid: '',
            isPremiumUser: false,
            isAlmostExpired: null,
            alert: ''
        }

        this.changeEmailModalRef = React.createRef()
        this.changePasswordModalRef = React.createRef()
        this.resetPasswordModalRef = React.createRef()
        this.editUserInfoModalRef = React.createRef()
    }

    componentDidMount(): void {
        Server.user.addEventListener('user-login', this.onUserLogin);
        Server.user.addEventListener('user-logout', this.onUserLogout);
        this.loadInfo()
    }

    componentWillUnmount(): void {
        Server.user.removeEventListener('user-login', this.onUserLogin);
        Server.user.removeEventListener('user-logout', this.onUserLogout);
    }

    render(): React.ReactNode {
        let isLogin = Server.user.isLogin()
        if (!isLogin) {
            return <></>
        }
        const { t } = this.props
        const email = this.state.email
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const company = this.state.company
        const companyShort = this.state.companyShort
        const mobile = this.state.mobile
        const hasPwd = this.state.hasPwd
        const isWxMpUser = this.state.isWxMpUser
        const openid = this.state.openid
        const inviteCode = this.state.inviteCode
        const isPremiumUser = this.state.isPremiumUser
        const premiumExpired = this.state.premiumExpired
        const isAlmostExpired = this.state.isAlmostExpired
        return (
            <div className="main">
                <div className="profile-page-header">
                    <div className="title"><h3>{t('pages.UserProfilePage.982276-0')}</h3></div>
                </div>
                <div className="profile-page-container">
                    {email &&
                    <>
                    <div className="profile-item">
                        <div className="profile-item-title">{t('pages.UserProfilePage.982276-1')}</div>
                        <div className="profile-item-content">{email}</div>
                        <div className="profile-item-btns">
                            <div className="btn-link" onClick={this.onChangeEmailClick}>
                                <span>{t('pages.UserProfilePage.982276-2')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="profile-item">
                        <div className="profile-item-title">{t('pages.UserProfilePage.982276-3')}</div>
                        <div className="profile-item-content">******</div>
                        <div className="profile-item-btns">
                            {hasPwd && 
                            <div className="btn-link" onClick={this.onEditPasswordClick}>
                                <span>{t('pages.UserProfilePage.982276-4')}</span>
                            </div>
                            }
                            <div className="btn-link" onClick={this.onResetPasswordClick}>
                                <span>{t('pages.UserProfilePage.982276-5')}</span>
                            </div>
                        </div>
                    </div>
                    </>
                    }
                    {/* {(isWxMpUser && openid) && <div className="profile-item">OpenID：{openid}</div>} */}
                    {(isWxMpUser && openid) && <div className="profile-item">{t('pages.UserProfilePage.982276-28')}</div>}
                    {/* {
                        (isPremiumUser && premiumExpired) && <>
                        <div className="profile-item">
                            <div className="profile-item-title">{t('pages.UserProfilePage.982276-8')}</div>
                            <div className="profile-item-content">
                                <Flex vertical justify="start" align="start">
                                    <span>{premiumExpired}</span>
                                </Flex>
                            </div>
                            <div className="profile-item-btns">
                                <div className="btn-link" onClick={this.purchasePremiumUser}>
                                    <span>{t('pages.UserProfilePage.982276-10')}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span style={{fontSize:"80%", color: "red"}}>{t('pages.UserProfilePage.982276-9')}</span>
                        </div>
                        </>
                    } */}
                    
                    <hr></hr>
                    <div className="profile-item">
                        <h3>{t('pages.UserProfilePage.982276-11')}</h3>
                        <div className="profile-item-btns">
                            <BsPencilFill onClick={this.onEditUserInfoClick} />
                        </div>
                    </div>

                    <div className="profile-item">
                        <div className="profile-item-content name">
                            <div className="lastName">
                                <div>{t('pages.UserProfilePage.982276-12')}</div>
                                <div>{lastName}</div>
                            </div>
                            <div className="firstName">
                                <div>{t('pages.UserProfilePage.982276-13')}</div>
                                <div>{firstName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-item">
                        <div className="profile-item-title">{t('pages.UserProfilePage.982276-14')}</div>
                        <div className="profile-item-content">{mobile}</div>
                    </div>
                    <div className="profile-item">
                        <div className="profile-item-title">{t('pages.UserProfilePage.982276-15')}</div>
                        <div className="profile-item-content">{company}</div>
                    </div>
                    <div className="profile-item">
                        <div className="profile-item-title">{t('pages.UserProfilePage.982276-16')}</div>
                        <div className="profile-item-content">{companyShort}</div>
                    </div>

                    <hr></hr>
                    {
                    !isPremiumUser && <>
                    <Row gutter={3}>
                        <Col span={24}>
                            <Card title={<div style={{textAlign:"center"}}>{t('pages.UserProfilePage.982276-17')}</div>} hoverable size="default" bordered={false} >
                                <Flex vertical align="left">
                                    <span>{t('pages.UserProfilePage.982276-18')}</span>
                                    <span>{t('pages.UserProfilePage.982276-19')}</span>
                                    <span>{t('pages.UserProfilePage.982276-20')}</span>
                                </Flex>
                                <Flex vertical align="center">
                                    <div style={{marginTop:"20px"}}>
                                        <button onClick={this.purchasePremiumUser}>{t('pages.UserProfilePage.982276-21')}</button>
                                    </div>
                                </Flex>
                            </Card>
                        </Col>
                    </Row>
                    </>
                    }
                    {
                    isPremiumUser &&
                    <>
                    <Row gutter={3}>
                        <Col span={24}>
                            <Card title={<div style={{textAlign:"center"}}>{t('pages.UserProfilePage.982276-17')}</div>} hoverable size="default" bordered={false}>
                                <Flex justify="center" align="center">
                                <div>
                                    <span style={{ whiteSpace: 'pre-line' }}>{t('pages.UserProfilePage.982276-23', { premiumExpired : premiumExpired })}</span>
                                    {isAlmostExpired &&
                                    <span style={{color: "red"}}>({t('pages.UserProfilePage.982276-9')})</span>
                                    }
                                </div>
                                </Flex>
                                <Flex vertical justify="center" align="center">
                                    <div style={{marginTop:"20px"}}>
                                        <button onClick={this.purchasePremiumUser}>{t('pages.UserProfilePage.982276-10')}</button>
                                    </div>
                                </Flex>
                            </Card>
                        </Col>
                    </Row>
                    </>
                    }
                    {
                    inviteCode && 
                    <Flex vertical align="center" justify="center" style={{marginTop: "20px"}}>
                        <div className="profile-item-title">{t('pages.UserProfilePage.982276-6')}</div>
                        <Flex vertical={false} justify="center">
                            <div id="invite-code" className="invite-code">{inviteCode}</div>
                            <div style={{marginLeft: "10px"}}>
                                <div className="btn-link" onClick={this.onCopyInviteCodeClick}>
                                    <span>{t('pages.UserProfilePage.982276-7')}</span>
                                </div>
                            </div>
                        </Flex>
                        <Flex vertical align="center" justify="center" style={{ fontSize: '90%'}}>
                            <span>{t('pages.UserProfilePage.982276-24')}</span>
                            <span>{t('pages.UserProfilePage.982276-25')}</span>
                        </Flex>
                    </Flex>
                    }
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={() => this.setState({ alert: '' })} />
                <ChangeEmailModal ref={this.changeEmailModalRef} onChangeEmailSuccess={this.onChangeEmailSuccess}></ChangeEmailModal>
                <ChangePasswordModal ref={this.changePasswordModalRef}></ChangePasswordModal>
                <ResetPasswordModal ref={this.resetPasswordModalRef}></ResetPasswordModal>
                <EditUserInfoModal ref={this.editUserInfoModalRef} onEditUserInfoSuccess={this.onEditUserInfoSuccess}></EditUserInfoModal>
            </div>
        )
    }

    // 用户登录回调函数
    onUserLogin = () => {
        // this.forceUpdate()
        this.loadInfo()
    }

    // 用户退出登录回调函数
    onUserLogout = () => {
        this.forceUpdate()
    }

    loadInfo = () => {
        Server.user.isLogin() && Server.user.getProfile().then(async user => {
            // console.log(user)
            const premiumUser = Server.user.isPremiumUser(user)

            this.setState({
                email: user.email || '',
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                mobile: user.mobile || '',
                company: user.company || '',
                companyShort: user.companyShort || '',
                hasPwd: user.hasPwd || false,
                isWxMpUser: user.isWxMpUser || false,
                openid: user.isWxMpUser ? user.name : '',
                inviteCode: user.inviteCode,
                isPremiumUser: premiumUser.premiumUser,
                isAlmostExpired: premiumUser.isAlmostExpired,
                premiumExpired: user.expired && utc2Local(user.expired, YYYY_MM_DD)
            })
        }).catch(e => {
            console.log(e)
        })
    }

    onChangeEmailClick = () => {
        this.changeEmailModalRef.current.show()
    }

    onChangeEmailSuccess = () => {
        // console.log('onChangeEmailSuccess')
        this.loadInfo()
    }

    onEditPasswordClick = () => {
        this.changePasswordModalRef.current.show()
    }

    onResetPasswordClick = () => {
        this.resetPasswordModalRef.current.show()
    }

    onEditUserInfoClick = () => {
        this.editUserInfoModalRef.current.show()
    }

    onEditUserInfoSuccess = () => {
        this.loadInfo()
    }
    onCopyInviteCodeClick = async () => {
        const { t } = this.props
        if (navigator.clipboard) {
            const code = document.querySelector('#invite-code').textContent
            // console.log(code)
            const copyText = t('pages.UserProfilePage.982276-26', { code: code })
            // console.log(copyText)
            await navigator.clipboard.writeText(copyText).then(() => {
                this.setState({
                    alert: t('pages.UserProfilePage.982276-22')
                })
            });
            return
        } else {
            this.setState({
                alert: t('pages.UserProfilePage.982276-27')
            })
        }
    }
    purchasePremiumUser = () => {
        window.location.href='/purchase?gtype='+GoodsCategory.PREMIUM
    }
}

export default withTranslation()(UserProfilePage)