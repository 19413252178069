import { Col, Flex, Row } from "antd";
import React from "react";
import { isMobile } from 'react-device-detect';
import { WithTranslation, withTranslation } from "react-i18next";
import { Server } from "../../server/server";
import { TermialType } from "../common/common";
import AlertModal from "../modals/AlertModal";
import MessageModal from "../modals/MessageModal";

class RechargePage extends React.Component<WithTranslation, RechargePageState> {
    private id: string;
    private currency: string
    /** 充值成功后重定向页面（来源页面）URL */
    private redirect: string
    constructor(props: any) {
        super(props)
        // console.log(window.location.search)
        const {search} = window.location
        // let { good, currency, redirect } = qs.parse(search.replace(/^\?/, ''))
        const searchParams = new URLSearchParams(search)
        let good = searchParams.get('good')
        let currency = searchParams.get('currency')
        let redirect = searchParams.get('redirect')

        // const { good, currency } = props.params
        if (good == undefined) good = ""
        if (currency == undefined) currency = ""
        if (redirect == undefined || redirect == null) redirect = ""
        console.log("redirect:" + redirect)
        this.id = good.toString()
        this.currency = currency.toString()
        this.redirect = redirect.toString()
        if(this.currency != 'jpy' && this.currency != 'cny') {
            this.currency = 'jpy'
        }
        // console.log(good, currency)
        this.state = {
            amount: 0,
            amountStr: '',
            price: 0,
            priceStr: '',
            tax: 0,
            taxStr: '',
            totalPrice: 0,
            totalPriceStr: '',
            sbpBuyUrl: '',
            sbpBuyFormData: null,
            processing: false,
            message: '',
            alert: ''
        }
    }

    componentDidMount(): void {
        this.loadRechargeGoods()

        // 如果是Safari浏览器监听pageshow事件，当前页面是回退页面，则重新加载页面
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        // console.log("isSarari:" + isSafari)
        // console.log(navigator.userAgent)
        if (isSafari) {
            // console.log('safari browser')
            window.addEventListener('pageshow', this.onPageShow);
        }
    }

    componentWillUnmount(): void {
        if (this.onPageShow) {
            window.removeEventListener('pageshow', this.onPageShow)
        }
    }

    render(): React.ReactNode {
        const { t } = this.props
        const { amountStr, priceStr, taxStr, totalPriceStr, sbpBuyUrl, sbpBuyFormData } = this.state

        return (
            <div className="main">
                <span>{t('pages.RechargePage.465362-0')}</span>
                <hr style={{ width: "100%" }}></hr>
                <Row gutter={3}>
                    <Col span={5}><span>{t('pages.RechargePage.465362-1')}</span></Col>
                    <Col span={18}><span>{t('pages.RechargePage.465362-2')}</span></Col>
                </Row>
                <Row gutter={3}>
                    <Col span={5}><span>{t('pages.RechargePage.465362-3')}</span></Col>
                    <Col span={18}><span>{amountStr}{t('pages.RechargePage.465362-4')}</span></Col>
                </Row>
                <Row gutter={3}>
                    <Col span={5}><span>{t('pages.RechargePage.465362-5')}</span></Col>
                    <Col span={18}><span>{priceStr}</span></Col>
                </Row>
                {this.currency == 'jpy' &&
                <Row gutter={3}>
                    <Col span={5}><span>{t('pages.RechargePage.465362-6')}</span></Col>
                    <Col span={18}><span>{taxStr}</span></Col>
                </Row>
                }
                <hr style={{ width: "100%" }}></hr>
                <Row gutter={3}>
                    <Col span={5}><span>{t('pages.RechargePage.465362-7')}</span></Col>
                    <Col span={18}><span style={{ fontWeight: "bolder" }}>{totalPriceStr}</span></Col>
                </Row>
                {sbpBuyFormData && 
                <form name="sbpBuyForm" action={sbpBuyUrl} method="POST" acceptCharset="shift_jis">
                    <input type="hidden" name="pay_method" value="" />
                    <input type="hidden" name="merchant_id" value={sbpBuyFormData.merchant_id} />
                    <input type="hidden" name="service_id" value={sbpBuyFormData.service_id} />
                    <input type="hidden" name="cust_code" value={sbpBuyFormData.cust_code} />
                    <input type="hidden" name="order_id" value={sbpBuyFormData.order_id} />
                    <input type="hidden" name="item_id" value={sbpBuyFormData.item_id} />
                    <input type="hidden" name="item_name" value={sbpBuyFormData.item_name} />
                    <input type="hidden" name="sps_cust_no" value="" />
                    <input type="hidden" name="sps_payment_no" value="" />
                    <input type="hidden" name="free_csv" value="" />
                    <input type="hidden" name="tax" value={sbpBuyFormData.tax} />
                    <input type="hidden" name="amount" value={sbpBuyFormData.amount} />
                    <input type="hidden" name="pay_type" value={sbpBuyFormData.pay_type} />
                    <input type="hidden" name="service_type" value={sbpBuyFormData.service_type} />
                    <input type="hidden" name="terminal_type" value={sbpBuyFormData.terminal_type} />
                    <input type="hidden" name="success_url" value={sbpBuyFormData.success_url} />
                    <input type="hidden" name="cancel_url" value={sbpBuyFormData.cancel_url} />
                    <input type="hidden" name="error_url" value={sbpBuyFormData.error_url} />
                    <input type="hidden" name="pagecon_url" value={sbpBuyFormData.pagecon_url} />

                    <input type="hidden" name="request_date" value={sbpBuyFormData.request_date} />
                    <input type="hidden" name="limit_second" value={sbpBuyFormData.limit_second} />
                    <input type="hidden" name="sps_hashcode" value={sbpBuyFormData.sps_hashcode} />
                </form>
                }
                <button className="base-button" onClick={this.onStartRecharge} disabled={this.state.processing}>{t('pages.RechargePage.465362-8')}</button>
                
                <Row>
                    <Flex vertical align="left">
                        <span>{t('pages.RechargePage.465362-13')}</span>
                        <span>{t('pages.RechargePage.465362-14')}</span>
                        <span>{t('pages.RechargePage.465362-15')}</span>
                    </Flex>
                </Row>

                <MessageModal message={this.state.message} />
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
            </div>
        )
    }

    loadRechargeGoods = () => {
        if (!this.id) return
        const { t } = this.props
        Server.goods.getRechargeGoods(this.id).then(res => {
            if (res.success) {
                const { value, jpy, taxJpy, jpyInclTax, cny, taxCny, cnyInclTax } = res.data
                let price = 0, tax = 0, totalPrice = 0, unitStr = ''
                if (this.currency == 'cny') {
                    price = cny
                    tax = taxCny
                    totalPrice = cnyInclTax
                    unitStr = t('pages.RechargePage.465362-9')
                } else {
                    price = jpy
                    tax = taxJpy
                    totalPrice = jpyInclTax
                    unitStr = t('pages.RechargePage.465362-10')
                }
                this.setState({
                    amount: value,
                    amountStr: Number(value).toLocaleString(),
                    price: price,
                    priceStr: Number(price).toLocaleString() + unitStr,
                    tax: tax,
                    taxStr: Number(tax).toLocaleString() + unitStr,
                    totalPrice: totalPrice,
                    totalPriceStr: Number(totalPrice).toLocaleString() + unitStr
                })
            }
        })
    }

    onStartRecharge = () => {
        // window.history.replaceState(null, "", '/balance');
        // window.history.go(0);
        // window.location.href = '/balance'
        // window.history.go(-1)

        if (this.state.processing) return
        
        const { t } = this.props
        const processingMsg = t('pages.RechargePage.465362-11')
        const errorMsg = t('pages.RechargePage.465362-12')

        this.setState({processing: true, message: processingMsg})

        // SBP发起支付地址
        const url = process.env.REACT_APP_SBP_URL_BUY
        // 测试地址
        // const url = "https://stbfep.sps-system.com/f01/FepBuyInfoReceive.do"
        // 本番地址
        // const url = "https://fep.sps-system.com/f01/FepBuyInfoReceive.do"
        // protocol + hostname + port
        const balanceUrl = window.location.origin + '/balance'

        // 充值成功后重定向的页面，没有的话跳转到充值与账单页面
        const successRedirectUrl = this.redirect || balanceUrl
        // 充值取消后重定向的页面，同上
        const cancelRedirectUrl =  this.redirect || balanceUrl
        // 充值失败后，点击SB页面上的返回按钮，重定向的页面，没有的话跳转到充值失败页面
        const errorRedirectUrl = balanceUrl
        // 客户端类型 0：PC 1：フィーチャーフォン
        const terminalType = isMobile ? TermialType.MOBILE : TermialType.PC

        Server.order.createRechargeOrder(this.id, encodeURIComponent(successRedirectUrl), encodeURIComponent(cancelRedirectUrl), encodeURIComponent(errorRedirectUrl), terminalType).then(res => {
            if (res.success) {
                console.log(res.data)
                const obj = res.data
                this.setState({
                    sbpBuyUrl: url,
                    sbpBuyFormData: obj
                }, () => {
                    if (this.state.sbpBuyFormData) {
                        const form = (document.forms.namedItem('sbpBuyForm') as HTMLFormElement) || null
                        if (form) {
                            form.submit()
                            sessionStorage.setItem("isBack","true")
                            
                        } else {
                            console.error('Form not found');
                            this.setState({processing: false, alert: errorMsg})
                        }
                    }
                })
            }
        }).catch(err => {
            console.log(err)
            this.setState({processing: false, alert: errorMsg})
        })
        
    }

    onPageShow = (event: any) => {
        console.log('pageshow')
        let isBack = sessionStorage.getItem("isBack")
        if (isBack == "true") {
            window.location.reload();
            sessionStorage.setItem("isBack", "false")
        }
    }
}

export default withTranslation()(RechargePage)

interface RechargePageState {
    amount: number
    amountStr: string
    price: number
    priceStr: string
    tax: number
    taxStr: string
    totalPrice: number
    totalPriceStr: string
    sbpBuyUrl: string
    sbpBuyFormData: {
        // [key: string]: string
        pay_method: string
        merchant_id: string
        service_id: string
        cust_code: string
        order_id: string
        item_id: string
        item_name: string
        sps_cust_no: string
        sps_payment_no: string
        free_csv: string
        tax: string
        amount: string
        pay_type: string
        service_type: string
        terminal_type: string
        success_url: string
        cancel_url: string
        error_url: string
        pagecon_url: string
        request_date: string
        limit_second: string
        sps_hashcode: string        
    },
    processing: boolean,
    message: string,
    alert: string
}
