import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

class ConfirmDialogModal extends React.Component<ConfirmModalProps, ConfirmModalState> {
    constructor(props:ConfirmModalProps) {
        super(props)

        this.state = {
            title: '',
            message: '',
            show: false,
        }
    }

    render(): React.ReactNode {
        // console.log(this.state.show)
        if (!this.state.show) {
            return(<></>)
        }
        
        const title = this.state.title
        const message = this.state.message
        const cancelText = this.state.cancelText
        const okText = this.state.okText

        return (
            <div className="modal open">
                <div className="modal-content" style={{width: '250px', textAlign: 'center'}}>
                    {title && <div><span style={{fontWeight: 'bold'}}>{title}</span></div>}
                    <hr></hr>
                    <div style={{whiteSpace: "pre-wrap", textAlign: 'justify'}}>{message}</div>
                    <div style={{marginTop: '25px', display: "flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", columnGap:"10px"}}>
                        <button onClick={this.onCancelButtonClick}>{cancelText}</button>
                        <button onClick={this.onOkButtonClick}>{okText}</button>
                    </div>
                </div>
            </div>
        )
    }

    // 取消按钮按下事件
    onCancelButtonClick = () => {

        this.setState({
            show: false
        })

        // 取消回调
        if(this.state.cancelCallback) {
            this.state.cancelCallback()
        }
    }

    // 确定按钮按下事件
    onOkButtonClick = () => {

        this.setState({
            show: false
        })

        // 确定回调
        if(this.state.okCallback) {
            this.state.okCallback()
        }
    }

    // 显示
    show = () => {
        this.setState({
            show: false
        })
    }

    // 隐藏
    hide = () => {
        this.setState({
            show: true
        })
    }

    build = (config:any) => {
        const { t } = this.props
        let that = this
        this.setState({
            title: config.title,
            message: config.message || '',
            okText: config.okText || t('modals.ConfirmDialogModal.827882-0'),
            okCallback: config.okCallback ? config.okCallback : void 0,
            cancelText: config.cancelText || t('modals.ConfirmDialogModal.827882-1'),
            cancelCallback: config.cancelCallback ? config.cancelCallback : void 0,
            show: true
        },() => {
            that = this
        })
        return that
    }

}

export default withTranslation('translation', { withRef : true })(ConfirmDialogModal)

interface ConfirmModalProps extends WithTranslation {
    title?: string,
    message?: string,
    cancelText?: string,
    cancelTextColor?: string,
    okText?: string,
    okTextColor?: string,
    cancelCallback?: Function,
    okCallback?: Function,
    ref: any
}

interface ConfirmModalState {
    show: boolean,
    title?: string,
    message: string,
    cancelText?: string,
    cancelTextColor?: string,
    okText?: string,
    okTextColor?: string,
    cancelCallback?: Function,
    okCallback?: Function,
}