import React from "react";
import { WithTranslation, withTranslation } from 'react-i18next';
import { Server } from '../../../server/server';
import './MyTalentList.css';
import TalentCard from './TalentCard';

/**
 * 组件-我的要员列表
 */
class MyTalentList extends React.Component<WithTranslation, MyTalentListState> {
    constructor(props:any) {
        super(props)
        this.state = {
            loading: false,
            talents: [],
            isPremiumUser: false,
        }
    }

    componentDidMount(): void {
        // 加载我的要员
        this.init()
    }

    render(): React.ReactNode {
        const { t } = this.props
        if(this.state.loading) {
            return (<div>{t('elements.talent.MyTalentList.281492-0')}</div>)
        }

        const talents = this.state.talents || []
        // 当前登录用户是否为有效的付费用户
        const isPremiumUser = this.state.isPremiumUser
        let list = []
        for(let i = 0, len = talents.length; i<len; i++) {
            const talent = talents[i]
            const id = talent.id
            const talentText = talent.text
            const talentStatus = talent.status
            const talentResume = talent.resume
            list.push(<TalentCard key={id} id={id} status={talentStatus} html={talentText} resume={talentResume} isPremiumUser={isPremiumUser}></TalentCard>)
        }

        return(
            <>
                <div className="my-talent-title">{t('elements.talent.MyTalentList.281492-1')}</div>
                <div className='my-talent-list'>
                    {talents.length == 0 && <div>{t('elements.talent.MyTalentList.281492-2')}</div>}
                    {talents.length > 0 && <div>{list}</div>}
                </div>
            </>
        )
    }

    // init获取我的要员
    async init() {
        this.setState({loading: true})
        // 获取我的要员列表
        Server.talent.getMyTalents().then((res)=>{
            if (!res.success) {
                return this.setState({
                    loading: false,
                })
            }
            Server.user.getProfile().then(user => {
                const { premiumUser } = Server.user.isPremiumUser(user)
                this.setState({
                    talents: res.data || [],
                    isPremiumUser: premiumUser,
                    loading: false
                })
            })
        }).catch(e => {
            console.error(e)
            this.setState({
                loading: false
            })
        })
    }

}

export default withTranslation('translation', { withRef: true })(MyTalentList)

interface MyTalentListState {
    loading: boolean
    talents: any[]
    isPremiumUser: boolean
}