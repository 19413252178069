export class ServiceResponse {
  success: boolean;
  message?: string;
}

export class Service {
  protected static API_BASE_PATH = 'https://api-match.waoooh.com'
  // protected static API_BASE_PATH = 'http://localhost:3333'
  protected static authorizationToken: string = '';

  public static setAuthorizationToken(token: string) {
    Service.authorizationToken = token;
  }

  protected listeners:any[];

  constructor() {
    this.listeners = [];
  }

  public async init(): Promise<ServiceResponse> {
    return new Promise((resolve)=>{
      resolve({success: true});
    })
  }

  public async sync(): Promise<ServiceResponse> {
    return new Promise((resolve)=>{
      resolve({success: true});
    })
  }

  public addEventListener(id:string, callback:Function) {
    this.listeners.push({id, callback});
  }

  public removeEventListener(id:string, callback:Function) {
    for(let i = 0; i < this.listeners.length; i++) {
      if(this.listeners[i].id == id && this.listeners[i].callback == callback) {
        this.listeners.splice(i, 1);
        return;
      }
    }
  }

  public notifyListeners(eventId:string, eventData:any = {}) {
    for(let i = 0; i < this.listeners.length; i++) 
      if(this.listeners[i].id == eventId) 
        this.listeners[i].callback(eventData);
  }

  protected getCommand(method:string, command:string, params:any = null): Promise<{status:number, body?:any}> {
    return new Promise((resolve, reject)=>{
      let endpoint = 'api-match.waoooh.com/api';
      let request = new XMLHttpRequest();
      request.open('GET', `https://${endpoint}/${method}/${command}`);
      request.setRequestHeader('Content-Type', 'application/json');

      if(Service.authorizationToken != '')
        request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
  
      let payload = {command};

      if(params) 
        payload = {command, ...params};

      let body = JSON.stringify(payload);

      console.log('==>', payload);
      
      request.send(body);
  
      request.onload = () => {
        let body = null;
        if (request.responseText != '')
          body = JSON.parse(request.responseText);

        if(body)
          console.log('<==', body);
        else
          console.log('<== ' + request.status + ' ' + request.responseText);
  
        resolve({
          status: request.status,
          body: body
        });
      };
  
      request.onerror = (e) => {
        console.log('<== ERROR');
        resolve({
          status: 500,
          body: {message: 'Internal service error!'}
        });
      };
    });
  }

  protected postCommand(method:string, command:string, params:any = null): Promise<{status:number, body?:any}> {
    return new Promise((resolve, reject)=>{
      // let endpoint = Service.environment == 'dev' ? ServerConfig.gatewayDev : ServerConfig.gatewayProd;
      let endpoint = 'api-match.waoooh.com/api';

      var request = new XMLHttpRequest();
      request.open('POST', `https://${endpoint}/${method}/${command}`);
      request.setRequestHeader('Content-Type', 'application/json');

      // 'Authorization': 'Bearer ' + localStorage.getItem('token')
      if(Service.authorizationToken != '')
        // request.setRequestHeader('Authorization', Service.authorizationToken);
        request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
  
      let payload = {command};

      if(params) 
        payload = {command, ...params};

      let body = JSON.stringify(payload);

      // let isProduction = (window.location.href.indexOf('www.') != -1);
      // if(!isProduction)
        console.log('==>', payload);
      
      request.send(body);
  
      request.onload = () => {
        let body = null;
        if (request.responseText != '')
          body = JSON.parse(request.responseText);

        // if(!isProduction)
          if(body)
            console.log('<==', body);
          else
            console.log('<== ' + request.status + ' ' + request.responseText);
  
        resolve({
          status: request.status,
          body: body
        });
      };
  
      request.onerror = (e) => {
        console.log('<== ERROR');
        resolve({
          status: 500,
          body: {message: 'Internal service error!'}
        });
      };
    });
  }
}
