import Api from "../app/api/Api";
import { CaseOrTalent } from "../app/common/common";
import { Service } from "./service";

export class MessageService extends Service {

    constructor(){
        super()
    }

    async send(type:number, message:any) {
        const {caseId, talentId, text, isInterviewMsg} = message
        let body = null
        if (CaseOrTalent.CASE === type) {
            const sendRefId = caseId
            const sendRefType = CaseOrTalent.CASE
            const receiverRefType = CaseOrTalent.TALENT
            const receiverRefId = talentId
            body = {sendRefId, sendRefType, receiverRefType, receiverRefId, msg:text, isInterviewMsg}
        } else if (CaseOrTalent.TALENT === type) {
            const sendRefId = talentId
            const sendRefType = CaseOrTalent.TALENT
            const receiverRefType = CaseOrTalent.CASE
            const receiverRefId = caseId
            body = {sendRefId, sendRefType, receiverRefType, receiverRefId, msg:text}
        }

        return await Api.post('/api/message/send', body)
    }

    async loadNextPage(type: number, cPageIndex:number, pageSize:number, caseId:string, talentId:string) {
        if (cPageIndex < -1) cPageIndex = -1
        cPageIndex++
        return await this.loadPage(type, cPageIndex, pageSize, caseId, talentId)
    }

    async loadPage(type:number, pageIndex:number, pageSize:number, caseId:string, talentId:string) {
        let path = ""
        if(type == CaseOrTalent.CASE) {
            path = "/api/message/case-message"
        } else if (type == CaseOrTalent.TALENT) {
            path = "/api/message/talent-message"
        }
        path = path + '?index='+pageIndex+'&size='+pageSize
        return await Api.post(path, {caseId: caseId, talentId: talentId})
    }
}