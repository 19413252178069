import qs from 'qs';
import React from 'react';
import { BsGlobe, BsList } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { Server } from '../../server/server';
import LoginModal from '../modals/LoginModal';
import SignupEmailMode from '../modals/SignupEmailModel';
import { publish } from '../util/event';
import { getPortraitImage } from '../util/util';
import { WithRouterProps } from '../util/withRouter';
import './HeaderBar.css';
import SiteMenu from './SiteMenu';
import UserMenu from './UserMenu';
import { WithTranslation } from 'react-i18next';
import LanguageMenu from './LanguageMenu';
import i18n from '../../i18n';
import { Language } from '../common/common';

interface HeaderBarProps extends WithTranslation {
  router: WithRouterProps;
}

interface HeaderBarState {
  openSiteMenu: boolean;
  openUserMenu: boolean;
  showLanguage: boolean;
  languageMenuPosition: { x: number; y: number };
  openLogin: boolean;
  openSignup: boolean;
  userName: string;
}

class HeaderBar extends React.Component<HeaderBarProps, HeaderBarState> {
  private headerBarRef: any;
  private popupRef: any;
  constructor(props: HeaderBarProps) {
    super(props);
    this.state = {
      openSiteMenu: false,
      openUserMenu: false,
      showLanguage: false,
      languageMenuPosition: { x: 0, y: 0 },
      openLogin: false,
      openSignup: false,
      userName: ''
    };

    this.openSiteMenu = this.openSiteMenu.bind(this);
    this.openUserMenu = this.openUserMenu.bind(this);
    this.openLanguageMenu = this.openLanguageMenu.bind(this);
    this.closeUserMenu = this.closeUserMenu.bind(this);
    this.closeSiteMenu = this.closeSiteMenu.bind(this);
    this.openLogin = this.openLogin.bind(this);
    this.closeSignup = this.closeSignup.bind(this);
    this.openSignup = this.openSignup.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
    this.onUserProfileUpdated = this.onUserProfileUpdated.bind(this);
    this.onUserLogin = this.onUserLogin.bind(this);
    this.onUserLogout = this.onUserLogout.bind(this);

    this.headerBarRef = React.createRef()
    this.popupRef = React.createRef();
  }

  componentDidMount() {
    Server.user.addEventListener('user-login', this.onUserLogin);
    Server.user.addEventListener('user-logout', this.onUserLogout);
    Server.user.addEventListener('user-profile-updated', this.onUserProfileUpdated);

    // console.log('componentDidMount')
    this.getUserInfo()
  }

  componentWillUnmount(): void {
    Server.user.removeEventListener('user-login', this.onUserLogin);
    Server.user.removeEventListener('user-logout', this.onUserLogout);
    Server.user.removeEventListener('user-profile-updated', this.onUserProfileUpdated);
  }

  getUserInfo() {
    Server.user.isLogin() && Server.user.getProfile().then(user => {
      // console.log(user)
      let userNames = []
      if (user.lastName) {
        userNames.push(user.lastName)
      } else {
        // 如果没有完善姓名，跳转到完善页面 TODO
        // window.history.replaceState(null, "", '/profile?noname');
        // window.history.go(0);
      }
      if(user.firstName) {
        userNames.push(user.firstName)
      } else {
        // 如果没有完善姓名，跳转到完善页面 TODO
      }

      if (userNames.length == 0) {
        this.setState({
          userName: user.name || ''
        })
      } else {
        this.setState({
          userName: userNames.join(' ')
        })
      }
    })
  }

  clearUserInfo() {
    this.setState({
      userName: ''
    })
  }

  // 用户登录回调函数
  onUserLogin = ()=> {
    // console.log('用户登录事件')
    // this.forceUpdate()
    this.getUserInfo()
  }

  // 用户退出登录回调函数
  onUserLogout = ()=> {
    // console.log('用户退出登录事件')
    // this.forceUpdate()
    this.clearUserInfo()
  }

  onUserProfileUpdated = ()=> {
    // console.log('用户信息更新事件')
    // this.forceUpdate();
    this.getUserInfo()
  }

  openSiteMenu() {
    this.setState({ openSiteMenu: true });
  }

  closeSiteMenu() {
    this.setState({ openSiteMenu: false });
  }

  openUserMenu() {
    this.setState({ openUserMenu: true });
  }

  openLanguageMenu() {
    const element = this.popupRef.current;
    if (element) {
      const headerBarRef = this.headerBarRef.current;
      const { height } = headerBarRef?.getBoundingClientRect();
      // console.log(height)
      const px = element.offsetLeft;
      const py = height;
      this.setState({ showLanguage: true, languageMenuPosition: { x: px, y: py } });
    }
  }

  closeUserMenu() {
    this.setState({ openUserMenu: false });
  }

  openLogin() {
    this.setState({ openLogin: true });
    publish('login.event.show-qrcode');
  }

  closeLogin() {
    this.setState({ openLogin: false });
  }

  openSignup() {
    this.setState({ openSignup: true});
  }
  closeSignup() {
    this.setState({ openSignup : false});
  }

  render() {
    const { t } = this.props;
    const currentLanguage = i18n.language;
    let isLogin = Server.user.isLogin()
    let userName = this.state.userName
    let portrait = getPortraitImage(null);
    let path = this.props.router.location.pathname;
    let pageName = '';

    if(path == '/')
      pageName = t('elements.HeaderBar.359224-3');
    else if(path.indexOf('/topic') == 0) {
      pageName = t('elements.HeaderBar.359224-4')
      const { search } = window.location
      const { type } = qs.parse(search.replace(/^\?/, ''))
      // console.log(type, topic)
      if(type == "1") {
        pageName = t('elements.HeaderBar.359224-5')
      } else {
        pageName = t('elements.HeaderBar.359224-6')
      }
    }
    else if(path.indexOf('/profile') == 0)
      pageName = t('elements.HeaderBar.359224-7');
    else if(path.indexOf('/case') == 0)
      pageName = t('elements.HeaderBar.359224-8');
    else if(path.indexOf('/talent') == 0)
      pageName = t('elements.HeaderBar.359224-9');
    else if(path.indexOf('/feedback') == 0)
      pageName = t('elements.HeaderBar.359224-10')
    else if(path.indexOf('/balance') == 0)
      pageName = t('elements.HeaderBar.359224-11')
    else if(path.indexOf('/recharge') == 0)
      pageName = t('elements.HeaderBar.359224-12')
    else if(path.indexOf('/purchase') == 0)
      pageName = t('elements.HeaderBar.359224-13')
    else if(path.indexOf('/order') == 0)
      pageName = t('elements.HeaderBar.359224-16')
    else {
      pageName = path;
      let n = pageName.lastIndexOf('/');
      if(n != -1) {
        pageName = pageName.substring(n+1);
        let parts = pageName.split('-');
        pageName = '';
        for(let i = 0; i < parts.length; i++) {
          if(i > 0)
            pageName += ' ';
          pageName += parts[i].substring(0, 1).toUpperCase() + parts[i].substring(1);
        }
      }
    }

    return (
      <div className="headerbar-container" ref={this.headerBarRef}>
        <NavLink to='/'>
          <img className="headerbar-logo" src="/navbar-icon.png"></img>
        </NavLink>
        
        <div className="headerbar-menu-mobile" onClick={this.openSiteMenu}>
            <BsList size={24}/>
        </div>
        <img className="popup-menu" src="/navbar-icon.png" onClick={this.openSiteMenu} />

        <div className="headerbar-content">
          <div className="headerbar-pagename">{pageName}</div>
          <div className="headerbar-pagename-mobile" onClick={this.openSiteMenu}>{pageName}</div>

          {isLogin && 
            <div className="headerbar-username" onClick={this.openUserMenu}>{userName}</div>
          }

          {isLogin && 
            <img className="headerbar-portrait" src={portrait} onClick={this.openUserMenu}/>
          }
          { // 多语言切换下拉菜单
            !isLogin && 
            <>
              <div className='headerbar-language-change' onClick={this.openLanguageMenu} ref={this.popupRef}>
                <BsGlobe/>&nbsp;{currentLanguage == Language.ja ? t('elements.BottomBar.705461-0'): t('elements.BottomBar.705461-1')}&nbsp;
              </div>
              <div className="headerbar-login" onClick={this.openLogin}>{t('elements.HeaderBar.359224-14')}</div>
              <div className="headerbar-login" onClick={this.openSignup}>{t('elements.HeaderBar.359224-15')}</div>
            </>
          }
        </div>

        <LanguageMenu open={this.state.showLanguage} pos={{ left: this.state.languageMenuPosition.x, top: this.state.languageMenuPosition.y }} onClose={()=>{this.setState({showLanguage: false})}} />
        <UserMenu open={this.state.openUserMenu} onClose={this.closeUserMenu} />
        <SiteMenu open={this.state.openSiteMenu} onClose={this.closeSiteMenu} />
        <LoginModal open={this.state.openLogin} showClose={true} onClose={this.closeLogin} />
        <SignupEmailMode open={this.state.openSignup} onClose={this.closeSignup}></SignupEmailMode>
      </div>
    );
  }
}

export default HeaderBar;