import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BsBoxArrowDown, BsChat, BsGlobe, BsPersonFill, BsWallet2 } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { Server } from '../../server/server';
import MessageModal from '../modals/MessageModal';
import '../modals/Modal.css';
import './UserMenu.css';
import LanguageMenu from './LanguageMenu';

interface UserMenuProps extends WithTranslation {
  open: boolean;
  onClose: Function;
}

interface UserMenuState {
  message: string;
  showLanguage: boolean
}

class UserMenu extends React.Component<UserMenuProps, UserMenuState> {
  constructor(props:UserMenuProps) {
    super(props);

    this.state = {
      message: '',
      showLanguage: false
    }

    this.onClose = this.onClose.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  onLogout() {
    this.setState({message: 'Logging out...'});

    setTimeout(async () => {
      await Server.user.logout();
      this.setState({message: ''});
      this.props.onClose();
    }, 1000);
  }

  onClose() {
    this.props.onClose();
  }

  render() {
    const { t } = this.props
    if(this.state.message != '') 
      return (<MessageModal message={this.state.message} />)

    if(this.state.showLanguage) {
      return (
        <LanguageMenu open={this.state.showLanguage} pos={{ right: 0, top: 50 }} onClose={()=>{this.setState({showLanguage: false})}} />
      )
    }

    if(!this.props.open)
      return (null);

    return (
      <div>
        <div className="user-menu-panel" onClick={this.onClose}>
          <NavLink className="user-menu-item" to="/profile">
            <BsPersonFill />&nbsp;&nbsp;{t('elements.UserMenu.816384-0')}
          </NavLink>
          <NavLink className="user-menu-item" to="/balance">
            <BsWallet2 />&nbsp;&nbsp;{t('elements.UserMenu.816384-1')}
          </NavLink>
          <NavLink className="user-menu-item" to="/feedback">
            <BsChat />&nbsp;&nbsp;{t('elements.UserMenu.816384-2')}
          </NavLink>
          <div className='user-menu-item' onClick={this.changeLanguage}>
            <BsGlobe />&nbsp;&nbsp;{t('elements.UserMenu.816384-4')}
          </div>
          <div className="user-menu-item" onClick={this.onLogout}>
            <BsBoxArrowDown />&nbsp;&nbsp;{t('elements.UserMenu.816384-3')}
          </div>
        </div>
        <div className="user-menu-modal" onClick={this.onClose}></div>
      </div>
    )
  }

  // 多语言切换
  changeLanguage = () => {
    // console.log('change language')
    this.setState({
      showLanguage: true
    })
  }

}

export default withTranslation()(UserMenu);