import React from 'react';

class RechargeErrorPage extends React.Component {
  render() {
    return (
      <div>
        <div style={{textAlign: 'center', marginTop: '20px'}}>this is error page.</div>
      </div>
    )
  }
}

export default RechargeErrorPage;