import React from 'react';
import { NavLink } from 'react-router-dom';
import { Server } from '../../server/server';
import '../modals/Modal.css'
import './SiteMenu.css'
import { getMenuIcon } from '../util/util';
import { AppConfig } from '../AppConfig';
import { WithTranslation, withTranslation } from 'react-i18next';

interface SiteMenuProps extends WithTranslation {
  open: boolean;
  onClose: Function;
}

interface SiteMenuState {
}

class SiteMenu extends React.Component<SiteMenuProps, SiteMenuState> {
  constructor(props:SiteMenuProps) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onUserLogin = this.onUserLogin.bind(this);
    this.onUserLogout = this.onUserLogout.bind(this);
  }

  componentDidMount() {
    Server.user.addEventListener('user-login', this.onUserLogin);
    Server.user.addEventListener('user-logout', this.onUserLogout);
  }

  componentWillUnmount(): void {
    Server.user.removeEventListener('user-login', this.onUserLogin);
    Server.user.removeEventListener('user-logout', this.onUserLogout);
  }

  // 用户登录回调函数
  onUserLogin() {
    // console.log('用户登录事件')
    this.forceUpdate()
  }

  // 用户退出登录回调函数
  onUserLogout() {
    // console.log('用户退出登录事件')
    this.forceUpdate()
  }

  onClose() {
    this.props.onClose();
  }

  renderButton(menu: any) {
    const { t } = this.props
    return (
      <NavLink key={menu.text} className="site-menu-item" to={menu.to}>
        <img className="site-menu-icon" src={getMenuIcon(menu.icon)} />
        {t(menu.text)}
      </NavLink>
    )
  }
  
  render() {
    if(!this.props.open)
      return (<div></div>);

    let buttons = [];
    let menu = AppConfig.menu;

    for (let i = 0; i < menu.length; i++) {
      if (menu[i].loggedIn) {
        if (Server.user.isLogin())
          buttons.push(this.renderButton(menu[i]));
      }
      else
        buttons.push(this.renderButton(menu[i]));
    }

    return (
      <div>
        <div className="site-menu-panel" onClick={this.onClose}>
          {buttons}
        </div>
        <div className="site-menu-modal" onClick={this.onClose}></div>
      </div>
    )
  }
}

export default withTranslation()(SiteMenu);