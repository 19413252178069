import React from "react";
import "./MessageInput.css"
import { WithTranslation, withTranslation } from "react-i18next";

class MessageInput extends React.Component<MessageInputProps, MessageInputState> {
    constructor(props: MessageInputProps) {
        super(props)
        this.state = {
            message: props.value || ''
        }
    }

    render(): React.ReactNode {
        const { t } = this.props
        const placeHolder = this.props.placeholder || t('elements.message.MessageInput.172429-0')
        const message = this.state.message
        return (
            <div style={{display: 'flex'}}>
                <input
                    className="message-input-area"
                    onChange={this.handleChange}
                    value={message}
                    placeholder={placeHolder}
                    type="text"
                    maxLength={300}
                />
                <button onClick={this.handleMessageSend}>
                    {t('elements.message.MessageInput.172429-1')}
                </button>
            </div>
        )
    }

    // 文本输入
    handleChange = (e: any) => {
        this.setState({
            message: e.target.value
        })
    }

    // 发送
    handleMessageSend = (e: any) => {
        // e.preventDefault()
        this.props.onSend(this.state.message)
        this.setState({
            message: ''
        })
    }

}

export default withTranslation()(MessageInput)

interface MessageInputProps extends WithTranslation {
    value?: string;
    placeholder?: string;

    activateAfterChange?: boolean;
    autoFocus?: boolean;
    onChange?: (innerHtml: string, textContent: string, innerText: string, nodes: NodeList) => void;
    onSend: (text: string) => void
    // onSend?: (innerHtml: string, textContent: string, innerText: string, nodes: NodeList) => void;
    // sendButton?: boolean;
}

interface MessageInputState {
    message: string
}