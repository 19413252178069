import React from "react";
import { CaseOrTalent } from '../../common/common';
import { YYYY_MM_DD_HH_MM, formatDateFriendly, utc2Local } from '../../util/date';
import "./Message.css";

export default class Message extends React.Component<MessageProps, any> {
    constructor (props: MessageProps) {
        super(props)
        // console.log(this.props.data)
    }

    render(): React.ReactNode {

        const self = this.props.loginUserId == this.props.data.sender
        const message = this.props.data.message
        const senderRefertoType = this.props.data.senderRefertoType
        const sendTimeUtc = this.props.data.sendTime
        const sendTimeLocal = utc2Local(sendTimeUtc, YYYY_MM_DD_HH_MM)
        const timeFriendly = formatDateFriendly(new Date(sendTimeLocal))
        // console.log(timeFriendly)
        // const id = this.props.data.id
        return (
            <div className={self ? 'message-item message-item-self' : 'message-item message-item-other'}>
                <div>
                    {senderRefertoType == CaseOrTalent.CASE &&
                        <img className="message-item-avatar" src="/icon/inventory.png" />
                    }
                    {senderRefertoType == CaseOrTalent.TALENT &&
                        <img className="message-item-avatar" src="/icon/career.png" />
                    }
                </div>
                <div className='message-item-content-wrapper'>
                    <div className='message-item-content'>
                        {/* <p>{parse(message)}</p> */}
                        <div style={{whiteSpace:"pre-line"}}>{message}</div>
                    </div>
                    <div className='message-item-time'>{timeFriendly}</div>
                </div>
            </div>
        )
    }
}

interface MessageProps {
    // 当前登录用户id
    loginUserId:string
    // 消息数据
    data: { 
        // 消息id
        id:string
        // 消息发送者（以及关联的案件或要员）
        sender:string
        senderRefertoId:string
        senderRefertoType:number
        // 消息内容
        // messageType: number
        message: string
        // 消息接收者（以及关联的案件或要员）
        receiver:string
        receiverRefertoType: number
        receiverRefertoId: string
        // 消息发送时间
        sendTime: Date 
    }
}