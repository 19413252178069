import Api from "../app/api/Api";
import { Service } from "./service";

export class OrderService extends Service {

    constructor(){
        super()
    }

    async getAllOrders(orderType: number) {
        // 获取订单列表
        return await Api.post('/api/order/all-order', { orderType: orderType })
    }

    async createRechargeOrder(goodsId: string, successRedirectUrl: string, cancelRedirectUrl: string, errorRedirectUrl:string, terminalType: number) {
        // 获取SB购买接口参数并创建相应的充值订单信息
        return await Api.post('/api/pay/sbp/buy/' + goodsId, {successRedirectUrl, cancelRedirectUrl, errorRedirectUrl, terminalType})
    }

    async downloadInvoice(orderId: string, fileName: string) {
        // 下载发票
        // await Api.get('/api/file/pdf/test')
        await Api.download('/api/order/invoice/' + orderId, fileName)
    }

    
}