export class AppConfig {
  public static siteName = 'Match';
  public static secretPassword = 'ploy';

  public static token = {
    id: 'fdt',
    symbol: 'FDT',
    enabled: true
  }

  public static coin = {
    id: 'coin',
    enabled: false
  }

  public static menu = [
    {
      text: 'app.config.sidebar.menu.0',
      icon: 'home',
      to: '/',
      loggedIn: false
    },
    {
      text: 'app.config.sidebar.menu.1',
      icon: 'inventory',
      to: '/case',
      loggedIn: true
    },
    {
      text: 'app.config.sidebar.menu.2',
      icon: 'career',
      to: '/talent',
      loggedIn: true
    },
  ];

  public static tplsCase = [
    {
      title: 'app.config.sidebar.menu.3', // 自定义
      text: ''
    },
    {
      title: 'app.config.sidebar.menu.4', // 模版1
      text: `【案件名称】
【作業概要】
【作業期間】
【作業工程】
【要求スキル】
 ＜必須＞
　 ・ 
　 ・
　 ・
 ＜尚可＞
　 ・
　 ・
【募集人数】
【作業場所】
【単価】
【面談回数】
【言語】
【国籍】
【勤務時間】
【精算幅】
【年齢】 
【商流条件】
【その他】`
    },
    {
      title: 'app.config.sidebar.menu.5', //模版2
      text: `■案件名称：
■作業概要：
■作業期間：
■作業工程：
■要求スキル：
 ＜必須＞
　 ・ 
　 ・
　 ・
 ＜尚可＞
　 ・
　 ・
■募集人数：
■作業場所：
■単価：
■面談回数：
■言語：
■国籍：
■勤務時間：
■精算幅：
■年齢： 
■商流条件：
■その他：`
    }
  ];

  public static tplsTalent = [
    {
      title: 'app.config.sidebar.menu.3', text: ''
    },
    {
      title: 'app.config.sidebar.menu.4',
      text: `【概要】
【役割】
【所属】
【氏名】
【国籍】
【年齢】
【最寄り駅】
【実務経験】
【作業工程】
【言語】
【得意スキル】
【単価】
【稼働時期】
【通勤】
【営業状況】
【希望】
【備考】`
    },
    {
      title: 'app.config.sidebar.menu.5',
      text: `■概要：
■役割：
■所属：
■氏名：
■国籍：
■年齢：
■最寄り駅：
■実務経験：
■作業工程：
■言語：
■得意スキル：
■単価：
■稼働時期：
■通勤：
■営業状況：
■希望：
■備考：`
    }
  ];

  public static agreement_service_zhCN = `
梧桐果服务协议

更新日期：2024年05月20日

本梧桐果服务协议（以下称“本协议”）由您与梧桐智配平台（含微信公众号，网站等关联版本和相关网站，以下称“平台”）运营主体エイテイエルテイ株式会社（以下称“公司”）签订；平台将按照本协议约定及不时发布的操作规则向您提供服务（以下称“本服务”）。
为更好地向用户提供服务，请服务使用人（即下单购买“梧桐果”及使用梧桐果购买平台各项服务的账号使用人，以下称“您”）在开始使用本服务前认真阅读并充分理解本协议。
如您不同意本协议，请您不要以任何形式进行下一步操作（包括但不限于点击充值等操作按钮、进行支付）及使用本服务。
平台禁止未成年人使用梧桐果服务。平台在此善意提醒，若您为未成年人的监护人，您应对您监护的未成年人承担监护职责，请您在未成年人使用本平台相关产品及服务时开启青少年模式及/或其他未成年人保护工具，监督、指导未成年人正确使用相关产品及服务，同时加强对网络支付方式的限制与管理，共同营造未成年人健康成长的良好环境。

一、服务内容
1、“梧桐果”是平台向您提供的、仅限于在平台内进行相关消费的虚拟工具。您购买“梧桐果”后，可以按照平台相关页面的说明及指引使用、获得平台商品或服务，包括但不限于会员升级和激活案件/要员服务等。为免生疑义，梧桐果服务是用户付费、平台向用户持续提供的整体网络技术及相关服务，梧桐果服务并非网络支付服务，“梧桐果”也不是代币票券、虚拟货币或预付款凭证，不具备货币价值、预付价值。
2、您购买“梧桐果”后仅可将其用于本协议项下的约定目的，不得超出公司提供的产品/服务的范围以外使用，亦不能在不同的梧桐智配账号之间转移。
3、您应通过平台指定官方渠道购买“梧桐果”，平台不认可任何未经公司授权的渠道。若您使用非官方渠道购买“梧桐果”，平台无法保证该等“梧桐果”能顺利进入您的账号，且该等行为还可能伴随诈骗、洗钱等风险，给您、平台及相关第三方造成难以挽回的损失或损害。因此，非官方渠道购买行为将被视为违规，此时平台有权扣除或清空您账号内的“梧桐果”、限制您账号全部或部分功能、直至永久封禁您的账号，您应自行承担因此而导致的您的损失；如您违反前述约定的行为造成平台或其他第三方损失的，您应负责赔偿。
4、您购买“梧桐果”的费用将由公司收取。平台特别提示您，平台提供多种付款方式，具体以您购买“梧桐果”时的页面展示为准。请您注意确认相关页面信息并合理选择“梧桐果”购买的支付方式。

二、理性消费
1、平台倡导理性消费、量入为出。请务必根据您的自身消费能力和实际需求，合理购买并使用梧桐果服务，避免过度消费。
2、您用于购买“梧桐果”的资金应为您合法获得且有权使用的收入；如您违反本条，因此产生的任何争议或纠纷您应自行解决并承担法律后果，如您的行为给平台或第三方造成损失，您还应当全额赔偿。若平台发现（包括但不限于主动发现、收到第三方投诉或主管部门、司法机关通知等情形）您涉嫌违反前述约定，平台有权扣除或清空您账号内的“梧桐果”、限制您账号全部或部分功能、直至永久封禁您的账号；同时平台有权保存相关信息，并向相关主管部门、司法机关报告。

三、您的权利义务
1、您在使用本服务过程中提供的个人信息或资料应当真实、准确，并应按照法律规定及平台的要求协助和配合监管机构的调查（如需要）。
2、您在购买“梧桐果”时应谨慎选择及/或输入您的账号信息/账号绑定手机号码、“梧桐果”购买数量等。若因您自身输入错误、操作不当、未充分了解计费方式等因素造成账号错误、“梧桐果”数量错误等购买错误情形，因此而导致您的损失或支出，平台有权不作出补偿或赔偿。
3、您应妥善保管、使用您的账号，并对该账号下的一切操作行为及后果负责。如因您以下情形导致平台无法提供本服务或提供本服务时发生错误，则因此而导致您的损失，除法律法规明确要求外平台不承担法律责任：
（1）您的账号失效、丢失、被盗用、被封禁；
（2）您账号绑定的第三方支付机构、银行账户被冻结、查封或发生其他异常，或您使用了未经认证的账户或非您本人的账户；
（3）您将账号密码告知他人或已其他方式允许他人登录使用您的账号；
（4）您存在故意或重大过失的其他情形。
4、您应当合法合规使用本服务，不得将本服务用于任何违法犯罪、违背公序良俗、有害社会道德风尚或干扰平台正常运营、侵害第三方合法权益的目的，您使用本服务的行为也不得违反任何对您有约束力的文件或其他要求（如有）。平台特别提醒您，请勿外借、转让或以其他方式将您的账号提供给其他人使用，合理防范他人通过您的账号实施违反前述约定的行为，以保护自己的账号及财产安全。
5、平台依法依规提供未成年人消费退款服务，以保障未成年人及其监护人的合法权益；您不应以非法目的或不当方式使用该服务，包括但不限于成年人冒充未成年人骗取退款、诱导未成年人消费后申请退款等。前述行为将构成对本协议的严重违反，平台经合理确认后有权拒绝退款，并保留进一步依法追究您法律责任的权利。
6、您在使用本服务过程中如涉及由第三方提供的相关服务（例如支付服务等），则除遵守本协议约定外，您还需要同意并遵守该等第三方的协议及相关规则，任何情况下，对于该第三方及其提供的相关服务而产生的争议由您自行与该第三方解决，平台无需就此向您或该第三方承担任何责任。

四、平台权利义务
1、平台有权基于法律法规修订、监管部门要求、交易安全保障、运营策略更新、市场环境变化等方面的考虑，不时对“梧桐果”服务设置相关限制、提醒等，包括但不限于限制全部或部分用户的交易限额及/或交易次数、禁止特定用户使用本服务、或增加交易验证等。
2、为保障交易安全，平台还有权对您使用本服务的行为进行监测，对因此合理认定为风险较高的用户或账号，平台可能采取必要措施，以防范风险扩大、保障用户财产和平台生态安全；该等必要措施包括扣除或清空您账号内的“梧桐果”、限制您账号全部或部分功能、短期或永久封禁您的账号等。
3、当平台发现因系统故障或其他任何原因导致的处理错误，无论有利于平台还是有利于您，平台都有权纠正该错误。此时，如您实际收到的“梧桐果”数量少于您应获得的“梧桐果”，则平台在确认该处理错误后会尽快将差额补足至您的账号；如您实际收到的“梧桐果”数量多于您应获得的“梧桐果”，平台将有权不经提前通知而从您的账号直接扣除差额。
4、平台有权视交易安全、运营规划、国家法律法规或监管部门要求等具体情况，变更、中断、中止或终止本服务，平台不因此而向您承担任何维权或侵权责任。

五、免责条款
1、您理解并同意，本服务是按照现有技术和条件所能达到的现状提供的，平台会尽最大努力向您提供本服务，确保服务的连贯性和安全性。但您亦知悉并认可，平台不能随时或始终预见和防范技术及其他风险，包括但不限于因不可抗力、网络原因、第三方服务瑕疵等原因可能导致的服务中断或异常等，如发生此类情形，平台将近最大商业努力予以改善，但无义务就此对您或其他第三方承担任何法律责任。2、平台可能自行进行停机维护、系统升级及调整，如因此而导致您无法正常使用本服务，您同意平台无需承担法律责任。
3、在任何情况下，平台均不承担任何间接性、惩罚性、偶然性或刑罚性的损害赔偿责任。且平台对您承担的全部责任，无论因何原因或何种方式，始终不超过您因使用本而支付的费用。
4、平台服务范围主要是对用户发布到平台的案件和要员信息解析并将匹配结果推荐给用户。平台不对用户发布信息的真实性负责，请在与对方沟通、面试、签约时考察对方提供信息的真实性以免对您造成损失。

六、违约责任
1、如您违反本协议，平台有权根据法律法规、相关协议及平台管理规定，视您行为的具体情形及危害程度，对您或您的账号采取管理措施，包括但不限于警告、扣除或清空您账号内的“梧桐果”、限制您账号全部或部分功能、短期或永久封禁您的账号等。
2、您理解并认可，如有第三方涉嫌利用您的账号实施违法违规或违反本协议约定的行为，为了保障潜在受害人及其他用户合法权益、维护平台生态秩序并防止损害进一步扩大，平台亦有权按照本协议及平台相关规则对您的账号采取管理措施，对此您不应要求平台承担法律责任。
3、如您在平台外实施或涉嫌实施了违法或不当行为，使平台有合理理由认为您已经或即将违反本协议或可对您使用的平台其他规则，平台亦有权对您采取本条第1款约定的管理措施，对此您不应要求平台承担法律责任。

七、 附则
1、为了向您提供更好的服务，或者基于法律规定、主管部门要求、业务开展情况等因素的更新与变化，平台将适时对本协议内容进行修订，该等修订内容一经公布立即生效，平台将发布更新版本，您可通过“梧桐果”购买页面随时查阅最新版本的协议条款。若您在本协议内容发生修订后继续使用平台提供的梧桐果服务的，则视为您同意最新修订的协议内容；否则请您立即停止使用本服务。
2、因本协议引起的或与本协议有关的争议，均适用当地法律。若本协议之任何规定因与当地法律抵触或被有管辖权的人民法院认定而无效或不可执行，则在不违反法律的前提下，这些条款将按照尽可能接近本协议原条文意旨的原则重新解析，且本协议其它规定仍应具有完整的效力及效果。
3、本协议签订地为日本东京都台东区。因本协议引起的或与本协议有关的争议，平台与您将协商解决。协商不成的，任何一方均有权向本协议签订地有管辖权的人民法院提起诉讼。`;


public static agreement_service_jaJP = `
ユーザチャージ規約

更新日: 令和６年５月２０日

このユーザチャージ規約 (以下、「契約」といいます) は、お客様と梧桐智配 (微信公衆号アカウント、Webサイトおよびその他の関連バージョンおよび関連 Web サイトを含み、以下「梧桐智配」といいます) との間で締結されます。 エイテイエルテイ株式会社(以下「当社」といいます)は梧桐智配の運営者です。 梧桐智配は、本契約および公開されている運営規則に従ってサービス (以下「サービス」といいます) を提供します。

ユーザーにより良いサービスを提供するため、サービス利用者（「梧桐果」の購入注文と梧桐果を使って梧桐智配上の各種サービスを利用するユーザー、以下「お客様」といいます）は、本規約をよくお読みいただいた上でご利用ください。

本規約に同意しない場合は、いかなる形式（チャージやその他の操作ボタンのクリック、支払いを含みますがこれに限定されません）においても次のステップに進んだり、本サービスを利用したりしないでください。
この梧桐智配では、未成年者が梧桐果及び梧桐智配のサービスを利用することを禁止しています。この梧桐智配は、あなたが未成年者の後見人である場合、その監督下で未成年者を後見する責任を負う必要があることを、この梧桐智配の関連製品およびサービスを使用する際に青少年モードおよび/またはその他の未成年者をオンにしてください。個人保護ツールを導入し、未成年者が関連商品やサービスを正しく利用できるよう監督・指導するとともに、オンライン決済方法の制限と管理を強化し、未成年者の健全な成長に向けた良好な環境を共同で構築する。

一.サービス内容
1. 「梧桐果」は梧桐智配によって提供される仮想ツールであり、梧桐智配内での関連消費に限定されます。 「梧桐果」を購入した後、梧桐智配の関連ページにある指示とガイドラインに従って梧桐智配を使用し、会員のアップグレードや案件/要員サービスのアクティブ化などを含むがこれらに限定されない梧桐智配の商品やサービスを取得できます。誤解を避けるため、梧桐果サービスは、ユーザーが支払うネットワーク テクノロジーおよび関連サービス全体であり、梧桐智配はユーザーに提供し続けます。梧桐果サービスはオンライン決済サービスではなく、「梧桐果」はトークンではありません。チケット、仮想通貨、または前払いバウチャーには、金銭的価値や前払い価値はありません。
2.「梧桐果」を購入した後は、本規約に同意した目的にのみ使用することができ、当社が提供する製品/サービスの範囲を超えて使用することはできず、異なるWutong Smart間で譲渡することはできません。アカウント。
3.「梧桐果」は、梧桐智配が指定した公式チャネルを通じて購入する必要があります。梧桐智配は、会社によって承認されていないチャネルを認識しません。非公式チャネルを使用して「梧桐果」を購入した場合、梧桐智配はそのような「梧桐果」があなたのアカウントにスムーズに資産追加されることを保証できません。また、そのような行為には詐欺やマネーロンダリングなどのリスクが伴い、お客様に結果が生じる可能性があります。梧桐智配および関連する第三者の回復不能な損失または損害にしたがって、現時点では、非公式ルートでの購入は違反とみなされ、梧桐智配はアカウントの「梧桐果」を差し引いたり消去したり、アカウントの機能の全部または一部を制限したり、場合によっては永久に禁止したりする権利を有します。前述の規約への違反により梧桐智配またはその他の第三者に損失が生じた場合、お客様は補償の責任を負うものとします。
4.「梧桐果」のご購入にかかる費用は当社が預かります。この梧桐智配は、「梧桐果」を購入する際のページ表示に応じて、梧桐智配がさまざまな支払い方法を提供していることを特に思い出させます。 「梧桐果」をご購入いただく際は、各ページの情報をご確認の上、お支払い方法を適切にご選択くださいますようお願いいたします。

二. 合理的な消費
1. この梧桐智配は、合理的な消費と自分の収入の範囲内での生活を推奨します。過度の消費を避けるために、ご自身の消費能力と実際のニーズに応じて、梧桐果サービスを合理的に購入および使用してください。
2. 「梧桐果」の購入に使用する資金は、合法的に取得し、使用する権利のある収入である必要があります。本条に違反した場合は、それに起因する紛争または紛争を自分で解決し、法的責任を負うものとします。梧桐智配または第三者が損失を引き起こした場合は、それらを全額補償する必要があります。あなたが前述の契約に違反している疑いがあることを梧桐智配が発見した場合（これには、事前の発見、第三者からの苦情の受領、管轄当局や司法当局からの通知などが含まれますが、これらに限定されません。）、梧桐智配はあなたのアカウントの「梧桐果」を差し引くか消去するか、あなたのアカウントの機能の全部または一部を制限するか、またはあなたのアカウントを永久に禁止する権利を有します。同時に、梧桐智配は関連情報を保存し、管轄当局および司法当局からの報告書を関連当事者に提供する権利を有します。

三. お客様の権利と義務
1. このサービスの使用中に提供する個人情報または資料は真実かつ正確である必要があり、法規制および梧桐智配の要件に従って、（必要な場合）規制当局の調査を支援および協力する必要があります。
2.「梧桐果」を購入する際は、アカウント情報/アカウントに関連付けられた電話番号、「梧桐果」の購入数量などを慎重に選択および入力する必要があります。お客様の入力ミス、不適切な操作、請求方法の理解不足などにより、口座番号の誤り、「梧桐果」の数量の誤り、その他の購入ミスによりお客様に損失や費用が発生した場合、梧桐智配は補償や費用を行わない権利を有します。
3. あなたは、自分のアカウントを適切に保管および使用し、そのアカウントに基づくすべての操作とその結果に対して責任を負う必要があります。以下の状況により梧桐智配が本サービスを提供できない場合、または本サービスの提供時にエラーが発生した場合、法律および規制で明示的に要求されない限り、梧桐智配はお客様の損失について法的責任を負いません。
(1) アカウントが無効、紛失、盗難、または禁止されている場合。
(2) お客様の口座に関連付けられている第三者の決済機関または銀行口座が凍結、封印またはその他の異常な場合、または認証されていない口座またはご自身のものではない口座を使用した場合。
(3) あなたが自分のアカウントのパスワードを他の人に知らせた、または他の人があなたのアカウントにログインして他の方法で使用することを許可した場合。
(4) その他お客様に故意または重過失がある場合。
4. お客様は、本サービスを適法かつ遵守した方法で利用するものとし、違法または犯罪的、公序良俗に違反する、社会道徳を害する、梧桐智配の正常な運営を妨害するなどの目的で本サービスを利用してはなりません。 または第三者の正当な権利および利益を侵害することは、いかなる目的であっても、お客様を拘束する文書またはその他の要件に違反するものではありません。この梧桐智配は、あなたのアカウントを他人に貸したり、譲渡したり、使用目的で提供したりしないこと、また、あなたのアカウントと財産の安全を守るために、あなたのアカウントを通じて他人が前述の契約に違反することを合理的に防止することを特に注意します。
5. この梧桐智配は、未成年者とその保護者の正当な権利と利益を保護するために、法律および規制に従って未成年者向けの消費者返金サービスを提供します。成人を装ったものを含むがこれに限定されない、違法な目的または不適切な方法でサービスを使用してはなりません。未成年者 消費後の返金申請を誘導する詐欺的な返金等前述の行為は本契約への重大な違反となり、梧桐智配は合理的な確認の後、返金を拒否する権利を有し、法律に従ってお客様の法的責任をさらに追求する権利を留保します。
6. 本サービスの利用に第三者が提供する関連サービス（決済サービス等）が含まれる場合には、本規約の規定を遵守することに加え、規約および関連規定に同意し遵守する必要があります。この場合、第三者およびお客様が提供する関連サービスに起因する紛争は、お客様および第三者によって解決されるものとし、梧桐智配はお客様または第三者に対して一切の責任を負う必要はありません。

四. 梧桐智配の権利と義務
1. 梧桐智配は、法令の改正、規制当局の要件、取引の安全性の保証、運営の最新情報などの考慮事項に基づいて、「梧桐果」サービスに関連する制限、リマインダーなどを随時設定する権利を有します。戦略、市場環境の変化など。これには、一部のユーザーのすべてのサービスまたは取引制限および/または取引数の制限、特定のユーザーのサービスの使用禁止、または取引検証の増加などが含まれますが、これらに限定されません。
2. 取引の安全性を確保するため、梧桐智配は、リスクが高いと合理的に判断されたユーザーまたはアカウントに対して、リスクの拡大を防ぐために必要な措置を講じる場合があります。ユーザーの財産と梧桐智配を保護するため、これらの必要な措置には、アカウントの「梧桐果」の差し引きまたは消去、アカウントの機能の全部または一部の制限、アカウントの短期または永久的な禁止などが含まれます。 。
3. 梧桐智配がシステム障害またはその他の理由によって引き起こされた処理エラーを発見した場合、それが梧桐智配にとって有益であるかユーザーにとって有益であるかにかかわらず、梧桐智配はエラーを修正する権利を有します。この時点で、実際に受け取った「梧桐果」の数量が、受け取るべき「梧桐果」よりも少ない場合、梧桐智配は処理エラーを確認した後、できるだけ早くあなたのアカウントに差額を埋め合わせます。 「梧桐果」の数が、獲得すべき「梧桐果」を超えた場合、梧桐智配は予告なく差額をアカウントから直接差し引く権利を有します。
4. お客様が梧桐智配外で違法または不適切な行為を行った、またはその疑いがあり、お客様が本契約またはお客様に対して利用される可能性のある梧桐智配のその他の規則に違反した、または違反しようとしていると信じる合理的な理由がある場合、また、梧桐智配は、本条第 1 項に規定されている管理措置を取る権利を有しており、それについて梧桐智配に法的責任を負わせる必要はありません。

五. 免責事項
1. お客様は、本サービスが既存の技術および条件が達成できる現状に従って提供されることを理解し、同意するものとします。梧桐智配は、本サービスを提供し、サービスの継続性とセキュリティを確保するために最善を尽くします。ただし、不可抗力、ネットワーク上の理由、サードパーティのサービスによって引き起こされる可能性のあるサービスの中断や異常を含むがこれらに限定されない、梧桐智配がいつでも、または常時、技術的およびその他のリスクを予測および防止することはできないことも理解し、了承するものとします。このような状況が発生した場合、梧桐智配は商業的に最善の努力を払って改善しますが、これに関してお客様または他の第三者に対して法的責任を負う義務はありません。 
2. 梧桐智配は独自にシャットダウンメンテナンス、システムのアップグレードおよび調整を行う場合があり、その結果としてサービスを正常に利用できなくなった場合でも、梧桐智配が法的責任を負う必要がないことに同意するものとします。
3. いかなる状況においても、梧桐智配は間接的、懲罰的、付随的、または刑事的損害に対して責任を負いません。そして、原因や方法に関係なく、お客様に対する梧桐智配の総責任は、この梧桐智配の使用に対してお客様が支払った料金を超えることはありません。
4. 本梧桐智配のサービス範囲は、主にユーザーが梧桐智配に公開した案件や要員情報を解析し、マッチング結果をユーザーに推奨することです。梧桐智配は、ユーザーが公開した情報の信頼性について責任を負いません。お客様の損失を避けるために、相手方とのコミュニケーション、面接、契約の締結の際には、相手方から提供された情報の真実性をご自身で確認してください。

六. 契約違反に対する責任
1. お客様が本規約に違反した場合、梧桐智配は、お客様の行為による被害の程度や具体的な状況に応じて、法律、規制、関連協定および梧桐智配管理規定に従って、お客様またはお客様のアカウントに対して管理措置を講じる権利を有します。ただし、警告、差し引き、または「梧桐果」のクリア、アカウントの機能の全部または一部の制限、アカウントの一時的または永久的な禁止などに限定されません。
2. あなたは、第三者があなたのアカウントを使用して違法行為を行ったり、本契約の規定に違反したりした疑いがある場合、潜在的な被害者や他のユーザーの正当な権利と利益を保護するために、環境秩序を維持する必要があることを理解し、認めるものとします。あなたは、本契約および梧桐智配の関連規則に従って、自分のアカウントに対して管理措置を講じる権利を有しており、これについて梧桐智配に法的責任を負わせるべきではありません。
3. あなたが梧桐智配外で違法または不適切な行為を行ったことがある、またはその疑いがあり、あなたが本契約またはあなたに対して使用される可能性のある梧桐智配のその他の規則に違反した、または違反しようとしていると信じる合理的な理由が梧桐智配にある場合、梧桐智配は、本条の第1項に規定されているように、お客様に対して措置を講じる権利も有します。お客様は、同項で合意された管理措置について梧桐智配に法的責任を負うことを要求しないものとします。

七. 附則
1. より良いサービスを提供するため、または法規制、管轄当局の要件、事業展開およびその他の要因の更新および変更に基づいて、梧桐智配は適時に本契約の内容を改訂します。発表され次第、梧桐智配は更新版をリリースし、最新版の契約条件を「梧桐果」購入ページでいつでも確認できます。本規約の内容が変更された後、梧桐智配が提供する梧桐果サービスを引き続き使用する場合、最新の変更された規約の内容に同意したものとみなされます。そうでない場合は、直ちにサービスの使用を中止してください。
2. 本契約に起因または関連する紛争は、現地法に準拠するものとします。本契約のいずれかの条項が現地法に抵触するために無効または法的強制力がない場合、または管轄の裁判所によって無効または法的強制力がないと判断された場合、法律に違反していないことを前提として、これらの条項は再適用されます。本契約の元の条項の意図に可能な限り近い形で確立され、本契約のその他の条項は引き続き完全に効力を有するものとします。
3. この協定を締結する場所は、日本国東京都台東区とします。本契約に起因または関連するあらゆる紛争は、梧桐智配とお客様の間の交渉を通じて解決されます。交渉が失敗した場合、いずれの当事者も、本契約が締結された場所を管轄する裁判所に訴訟を起こす権利を有します。`;
}