import React from "react";
import { BsFillXCircleFill } from "react-icons/bs";
import { Server } from "../../server/server";
import { CaseOrTalent } from "../common/common";
import AlertModal from "./AlertModal";
import './ChooseToMatchModal.css';
import './Modal.css';
import { WithTranslation, withTranslation } from "react-i18next";

class ChooseToMatchModal  extends React.Component<ChooseToMatchModalProps, ChooseToMatchModalState> {
    private type: number
    private id: string
    constructor(props: ChooseToMatchModalProps) {
        super(props)
        this.type = props.type
        this.id = props.id

        this.state = {
            id: props.id,
            loading: false,
            list: [],
            show: false,
            alert: ''
        }
    }

    render(): React.ReactNode {
        const { t } = this.props
        if (!this.state.show) {
            return(<></>)
        }

        const unmatchedList = this.state.list
        let list:any = []
        unmatchedList.forEach((item, index) => {
            const id = item.id
            
            const title = CaseOrTalent.CASE == this.type ? item.talentBrief : item.caseBrief
            list.push(
                <div className="unmatched-list-item" key={index}>
                    <span className="title">{title}</span>
                    <button className="button" key={id} onClick={() => {this.onMatchClick(id)}}>{t('modals.ChooseToMatchModal.376163-1')}</button>
                </div>
            )
        })

        const title = this.type == CaseOrTalent.CASE ? t('modals.ChooseToMatchModal.376163-2') : t('modals.ChooseToMatchModal.376163-3')
        return (
            <>
            <div className="modal open">
                <div className="modal-content">
                    <button className="modal-close-button" onClick={this.hide}>
                        <BsFillXCircleFill />
                    </button>
                    <div className="unmatched-list-title">
                        <span>{title}</span>
                    </div>
                    {
                        this.state.loading ? 
                        (
                            <div>{t('modals.ChooseToMatchModal.376163-0')}</div>
                        ) : 
                        (
                            list.length ? <div className="unmatched-list">{list}</div> : <span>{t('modals.ChooseToMatchModal.376163-4')}</span>
                        )
                    }
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
            </div>
            </>
        )
    }

    hide = () => {
        this.setState({
            show: false
        })
    }

    show = () => {
        this.setState({
            show: true
        }, () => {
            // 重新加载尚未匹配的数据
            this.loadUnMatched(this.type, this.id)
        })
    }
    
    onMatchClick = async (id:string) => {
        const type = this.props.type
        const pid = this.props.id
        let caseId = null
        let talentId = null
        let res = null
        if (CaseOrTalent.CASE == type) {
            caseId = pid
            talentId = id
            res = await Server.talent.manualMatch(talentId, caseId)
        } else {
            talentId = pid
            caseId = id
            res  = await Server.case.manualMatch(caseId, talentId)
        }
        if (!res || !res.success) {
            return this.setState({
                alert: res.message
            })
        }

        this.setState({
            // alert: '手动匹配成功',
            show: false
        },() => {
            this.props.onMatchedCallback()
        })
    }

    loadUnMatched = async (type: number, id:string) => {
        this.setState({
            loading: true,
        })
        let res = null
        if (CaseOrTalent.CASE == type) {
            res = await Server.talent.getMyTalentsNotMathed(id)
        } else {
            res = await Server.case.getMyCasesNotMathed(id)
        }

        if (!res || !res.success) {
            return this.setState({
                loading: false,
            })
        }

        this.setState({
            loading: false,
            list: res.data || []
        })
    }
}

export default withTranslation('translation', { withRef : true })(ChooseToMatchModal)

interface ChooseToMatchModalProps extends WithTranslation {
    // 案件或要员
    type: number
    // 案件id或要员id
    id: string
    // 手动匹配成功后的回调函数
    onMatchedCallback?: Function
    ref: any
}

interface ChooseToMatchModalState {
    id: string
    // 数据加载标志
    loading: boolean
    // 尚未与案件或要员匹配的要员或案件
    list: any[]
    // 消息
    alert: string
    // 是否显示本对话框
    show: boolean
}