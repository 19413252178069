import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// 默认格式化
export const YYYY_MM_DD_HH_MM = "YYYY/MM/DD HH:mm"
export const YYYY_MM_DD = "YYYY/MM/DD"
export const MM_DD_HH_MM = "MM/DD HH:mm"
export const HH_MM = "HH:mm"

/**
 * 将UTC时间转换为本地时间
 *
 * @param utcDateTime UTC时间，支持Date或string类型
 * @param localFormat 本地时间格式化字符串，可选参数
 * @returns 返回格式化后的本地时间字符串
 */
export function utc2Local(utcDateTime: Date | string, localFormat?: string) {
    // console.log(utcDateTime)
    // const localTimezone = dayjs.tz.guess()
    // console.log(localTimezone)
    // return dayjs.tz(utcDateTime, '+0000').local().format(localFormat) 手机浏览器下不兼容
    const res = dayjs.utc(utcDateTime).local().format(localFormat)
    // console.log(res)
    return res
}

/**
 * 将本地时间转换为UTC时间
 *
 * @param localTime 本地时间
 * @param localTimezone 本地时区，默认为自动猜测
 * @returns 返回UTC时间的字符串表示
 */
export function local2Utc(localTime: Date, localTimezone?: string) {
    // console.log(localTime)
    if (!localTimezone) {
        localTimezone = dayjs.tz.guess()
    }
    return dayjs.tz(localTime, localTimezone).utc().format()
    // const date = new Date()
    // return dayjs(localTime).utc().format()
}

export function now2Utc() {
    return local2Utc(new Date())
}

/**
 * 将日期格式化为友好的字符串
 *
 * @param date 日期对象
 * @returns 格式化后的日期字符串
 */
export function formatDateFriendly(date: Date): string {
    const now = dayjs();
    // const diff = now.diff(date, 'day');
    const nowStr = now.format('YYYYMMDD')
    const dateStr = dayjs(date).format('YYYYMMDD')
    const diff = Number(nowStr) - Number(dateStr)
    
    // console.log(diff)
    if (diff === 0) {
        // 今天
        return `今天 ${dayjs(date).format('HH:mm')}`;
    } else if (diff === 1) {
        // 昨天
        return `昨天 ${dayjs(date).format('HH:mm')}`;
    } else if (diff >= 0 && diff <= 6) {
        // 本周
        const dayOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][dayjs(date).day()];
        return `${dayOfWeek} ${dayjs(date).format('HH:mm')}`;
    } else if (now.year() === date.getFullYear()) {
        // 本年
        return `${dayjs(date).format('MM月DD日 HH:mm')}`;
    } else {
        // 其他情况
        return `${dayjs(date).format('YYYY年MM月DD日 HH:mm')}`;
    }
}