import parse from 'html-react-parser';
import React from "react";

export default class HtmlContainer extends React.Component<HtmlContainerProps, HtmlContainerState> {

    constructor(props:HtmlContainerProps) {
        super(props)
        this.state = {
            caseText: props.html
        }
    }

    render(): React.ReactNode {
        const htmlText = this.props.html
        return (
            <div className="html-container">
                {parse(htmlText)}
            </div>
        )
        
    }

}

interface HtmlContainerProps {
    id:string
    html:string
}

interface HtmlContainerState {

}