import dayjs from "dayjs"
import React from "react"
import { Server } from "../../../server/server"
import { BusinessEvent, getInterviewTimes } from "../../common/common"
import { MM_DD_HH_MM, YYYY_MM_DD_HH_MM, utc2Local } from "../../util/date"
import './CaseEventList.css'
import { WithTranslation, withTranslation } from "react-i18next"

/**
 * 组件-案件的营业情况一览（面试+录用信息）
 */
class CaseEventList extends React.Component<CaseEventListProps, any> {
    // 案件id
    private caseId: string
    constructor(props:CaseEventListProps) {
        super(props)
        this.caseId = props.caseId
        // const list = props.list
        // console.log("list:" , list)
        // // 初始化状态
        // this.state = {
        //     events: list,
        //     loading: false
        // }
    }

    componentDidMount(): void {
        // console.log('componentDidMount')
        // 获取案件的营业状况
        // this.getCaseEvents()
    }

    render(): React.ReactNode {
        const { t } = this.props
        const events = this.props.list || []
        // const loading = this.state.loading
        // const events = this.state.events
        const now = dayjs()

        const eventList = events.map((item, index) => {
            const mid = item.mid
            const type = item.type
            let leftText = ''
            let rightText = ''
            if (type == BusinessEvent.INTERVIEW) {
                // 营业数据-面试
                const {timesTotal, times, time, way, 
                    talentId,
                    talentUserCompany,
                    talentUserName,
                    talentBrief,
                    matchedCaseStatus,
                    matchedTalentStatus} = item
                // 面试次数
                const timesStr = getInterviewTimes(times, timesTotal)
                // 面试时间
                let timeStr = utc2Local(time, YYYY_MM_DD_HH_MM)
                const timeObj = dayjs(timeStr)
                if (timeObj.year() == now.year()) {
                    timeStr = timeObj.format(MM_DD_HH_MM)
                }
                // const isExpiredTime = dayjs().isAfter(timeObj)
                // 面试方式
                // const wayStr = getInterviewWay(way)
                // 要员信息
                const talentStr = talentUserCompany + " " + talentUserName + "(" + talentBrief + ")"
                leftText = timeStr + ' ' + timesStr + ' ' + talentStr
            } else if(type == BusinessEvent.EMPLOY){
                // 营业数据-采用
                const {
                    talentId,
                    talentUserCompany,
                    talentUserName,
                    talentBrief,
                    matchedCaseStatus,
                    matchedTalentStatus} = item
                    // 要员信息
                const talentStr = talentUserCompany + " " + talentUserName + "(" + talentBrief + ")"
                leftText = talentStr 
                rightText = t('elements.talent.CaseEventList.335091-0')
            }

            return(
                <div key={index} className="case-event-list-item"  title={leftText + ' ' +rightText} onClick={()=>this.onEventClick(mid)}>
                    <div className="left">
                        <span className="text">{leftText}</span>
                    </div>
                    <div className="right">
                        <span className="text">{rightText}</span>
                    </div>
                </div>
            )
        })

        return(
            <div className="case-event-container">
                <div className="title">{t('elements.talent.CaseEventList.335091-1')}</div>
                {/* {loading && <div className="case-event-loading">加载中...</div>} */}
                {(eventList.length == 0) && <div className="case-event-empty">{t('elements.talent.CaseEventList.335091-2')}</div>}
                {(eventList.length > 0) && <div className="case-event-list">{eventList}</div>}
            </div>
        )
    }

    /**
     * 要员匹配的案件点击事件
     * 
     * @param id 匹配id
     */
    onEventClick(id: string): void {
        // 跳转到要员详情页面
        window.location.href ='/case-matched-talent?id=' + id
    }

    /**
     * 获取案件的营业状况
     * 
     * @returns 案件的营业状况列表
     */
    getCaseEvents(caseId: string) {
        // console.log('getCaseEvents', caseId)
        if (!caseId) return

        this.setState({
            loading: true
        })
        Server.case.getCaseEvents(caseId).then(res => {
            if(!res.success) {
                return this.setState({
                    loading: false
                })
            }
            // console.log("events: " + JSON.stringify(res.data))
            this.setState({
                loading: false,
                events: res.data || []
            })
        }).catch(err => {
            console.log(err)
            this.setState({
                loading: false
            })
        })
    }
}


interface CaseEventListProps extends WithTranslation {
    // 案件id
    caseId: string
    list?: CaseBussinesEvent[]
    // 导航
    navigate: any,
    ref: any,
}

interface CaseBussinesEvent {
    // 匹配id
    mid:string
    // 事件类型
    type: number
    
    // 面试要员id
    talentId: string
    // 面试要员所属用户的公司
    talentUserCompany: string
    // 面试要员所属用户的姓名
    talentUserName: string
    // 面试要员名称
    talentBrief: string
    // 案件关系状态
    matchedCaseStatus:number
    // 要员关系状态
    matchedTalentStatus:number

    // 面试总次数
    timesTotal?:number
    // 面试次数
    times?:number
    // 面试时间
    time?: Date
    // 面试方式
    way?: number

}

export default withTranslation('translation', { withRef: true })(CaseEventList);