import React from "react";
import './ManualMatchedList.css';
import { NavLink } from "react-router-dom";
import { CaseOrTalent } from "../common/common";
import { Server } from "../../server/server";
import ChooseToMatchModal from "../modals/ChooseToMatchModal";
import AlertModal from "../modals/AlertModal";
import { WithTranslation, withTranslation } from "react-i18next";

/**
 * 组件-手动匹配的要员或案件列表
 */
class ManualMatchedList extends React.Component<ManualMatchedListProps, ManualMatchedListState> {
    private type: number
    private id: string
    private chooseToMatchModalRef: any
    constructor(props:ManualMatchedListProps) {
        super(props)
        this.type = props.type
        this.id = props.id
        // console.log('manualMatchedList', props)
        this.state = {
            loading: false,
            list: [],
            alert: ''
        }

        this.chooseToMatchModalRef = React.createRef()
        
    }

    componentDidMount(): void {
        Server.user.addEventListener('user-login', this.onUserLogin);
        Server.user.addEventListener('user-logout', this.onUserLogout);
        this.loadMatchedList(this.props.type, this.props.id)
    }

    componentWillUnmount(): void {
        Server.user.removeEventListener('user-login', this.onUserLogin);
        Server.user.removeEventListener('user-logout', this.onUserLogout);
    }

    render(): React.ReactNode {
        const { t } = this.props
        if(this.state.loading) {
            return (<div>{t('elements.ManualMatchedList.912564-0')}</div>)
        }
        const isLogin = Server.user.isLogin()

        // 类型（案件或要员）
        const type = this.props.type
        const id = this.props.id
        const list = this.state.list || []
        const matchedList = list.map((item:any, index:number) => {
            // console.log(item)
            // 匹配id
            const mid = item.id
            // 链接
            let link = null
            if (CaseOrTalent.CASE == type) {
                link = `/talent-matched-case?id=${mid}`
            } else {
                link = `/case-matched-talent?id=${mid}`
            }
            // 原文
            const brief = CaseOrTalent.CASE == type ? item.talentBrief : item.caseBrief

            return(
                <NavLink to={link} key={index}>
                    <div key={mid} className="matched-item">
                        <div className="brief">{brief}</div>
                    </div>
                </NavLink>
            )
        })

        const title = CaseOrTalent.CASE == type ?  t('elements.ManualMatchedList.912564-1') : t('elements.ManualMatchedList.912564-2')
        const btnText = CaseOrTalent.CASE == type ?  t('elements.ManualMatchedList.912564-3') : t('elements.ManualMatchedList.912564-4')
        return(
            <div className="manual-matched-container">
                <div className="title-bar">
                    <div className="title">{title}</div>
                    {isLogin && <button className="btn-match" onClick={this.onMatchClick}>{btnText}</button>}
                </div>
                
                {isLogin ? (
                    matchedList.length > 0 ? <div>{matchedList}</div> : <span>{t('elements.ManualMatchedList.912564-5')}</span>
                ):(
                    <span>{t('elements.ManualMatchedList.912564-6')}</span>
                )}
                <ChooseToMatchModal type={type} id={id} ref={this.chooseToMatchModalRef} onMatchedCallback={this.matchedCallback}></ChooseToMatchModal>
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})} />
            </div>
        )
    }

    // 用户登录回调函数
    onUserLogin = () => {
        // console.log('用户登录事件')
        this.loadMatchedList(this.type, this.id)
    }

    // 用户退出登录回调函数
    onUserLogout = () => {
        // console.log('用户退出登录事件')
        this.setState({
            loading: false,
            list: []
        })
    }

    // 加载手动匹配的数据
    async loadMatchedList(type: number, id:string) {
        if (!Server.user.isLogin()) {
            return
        }
        this.setState({loading: true})
        let res = null
        if (CaseOrTalent.CASE == type) {
            res = await Server.case.getManualMatchedTalents(id).catch((e)=> {console.error(e); this.setState({loading: false})})
        } else {
            res = await Server.talent.getManualMatchedCases(id).catch((e)=> {console.error(e); this.setState({loading: false})})
        }

        if (!res || !res.success) {
            return this.setState({
                loading: false,
            })
        }
        
        this.setState({
            loading: false,
            list: res.data || []
        })
    }

    // 点击匹配事件
    onMatchClick = () => {
        if(!Server.user.isLogin()) {
            return this.setState({
                alert: this.props.t('elements.ManualMatchedList.912564-6')
            })
        }
        
        this.chooseToMatchModalRef.current.show(this.type, this.id)
    }

    // 手动匹配成功的回调函数
    matchedCallback = () => {
        this.loadMatchedList(this.type, this.id)
    }
}

export default withTranslation()(ManualMatchedList)

interface ManualMatchedListProps extends WithTranslation {
    // 当前类型（案件或要员）
    type: CaseOrTalent.CASE | CaseOrTalent.TALENT
    // 当前案件或要员id
    id: string
}

interface ManualMatchedListState {
    // 数据加载标志
    loading: boolean,
    // 当前案件匹配的要员或要员匹配的案件列表
    list?: []
    // 提示消息
    alert:string
}