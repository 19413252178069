import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Server } from '../server/server';
import './App.css';
import LoginModal from './modals/LoginModal';
import ActivityPage from './pages/ActivityPage';
import BalancePage from './pages/BalancePage';
import FeedbackPage from './pages/FeedbackPage';
import HomePage from './pages/HomePage';
import MatchedCasePage from './pages/MatchedCasePage';
import MatchedTalentPage from './pages/MatchedTalentPage';
import MyCasePage from './pages/MyCasePage';
import MyTalentPage from './pages/MyTalentPage';
import NotFoundPage from './pages/NotFoundPage';
import OrderPage from './pages/OrderPage';
import PurchasePage from './pages/PurchasePage';
import RechargePage from './pages/RechargePage';
import ServiceAgreementPage from './pages/ServiceAgreementPage';
import SitePage from './pages/SitePage';
import TopicPage from './pages/TopicPage';
import UserProfilePage from './pages/UserProfilePage';
import { publish } from './util/event';
import RechargeErrorPage from './pages/RechargeErrorPage';

interface AppState {
  // allowAccess: boolean;
  // postLoginPage: string;
  // initialized: boolean;
  // maintenance: boolean;
};

class App extends React.Component<{}, AppState> {
  constructor(props = {}) {
    super(props);

    this.state = {
      // allowAccess: this.checkSecretPassword(),
      // postLoginPage: 'account',
      // initialized: false,
      // maintenance: false,
      // isLogin: Server.user.isLogin()
    }

    // if(!this.state.maintenance)
    //   Server.init();

    // this.setInitialized = this.setInitialized.bind(this);
    // this.checkSecretPassword = this.checkSecretPassword.bind(this);
    // this.setSecretPassword = this.setSecretPassword.bind(this);
    // this.onAccountChanged = this.onAccountChanged.bind(this);

    // window.addEventListener("beforeunload", function (e) {
    //   // Server.network.disconnect();
    // });

  }

  componentDidMount() {
    Server.user.addEventListener('user-login', this.onUserLogin);
    // Server.account.addEventListener('login', this.onAccountChanged);
    // Server.account.addEventListener('logout', this.onAccountChanged);

    // if(!this.state.maintenance)
    //   Server.network.setPresence('site');
  }

  componentWillUnmount(): void {
    Server.user.removeEventListener('user-login', this.onUserLogin);
  }

  // setInitialized() {
  //   this.setState({initialized: true});
  // }

  // checkSecretPassword() {
  //   return (localStorage.getItem('secret-password') === AppConfig.secretPassword);
  // }

  // setSecretPassword(password:string) {
  //   localStorage.setItem('secret-password', password);
  //   this.setState({allowAccess: this.checkSecretPassword()});
  // }

  // onAccountChanged() {
  //   this.forceUpdate();
  // }

  onUserLogin = () => {
    this.forceUpdate();
  }

  render() {
    // if (!this.state.initialized)
    //   return (<LoadingPage maintenance={this.state.maintenance} setInitialized={this.setInitialized} />);

    // if(!this.state.allowAccess)
    //   return (<PasswordPage setPassword={this.setSecretPassword} />);

    const isLogin = Server.user.isLogin()
    // const isLogin = this.state.isLogin
    let path = window.location.pathname;
    // console.log(path)
    if(path != '/' && path != '/topic' && path != '/agreement' && path != '/test' && path != '/recharge-error') {
      if (!isLogin) {
        setTimeout( () => {
          publish('login.event.show-qrcode');
        }, 1000)
        return (
          <LoginModal open={true}></LoginModal>
        )
      }
    }
    
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<SitePage />}>
            <Route index element={<HomePage />} />
            <Route path='/topic' element={<TopicPage />} />
            <Route path='/case' element={<MyCasePage />} />
            <Route path='/case-matched-talent' element={<MatchedTalentPage />} />
            <Route path='/talent' element={<MyTalentPage />} />
            <Route path='/talent-matched-case' element={<MatchedCasePage />} />
            {/* <Route path='/profile/:id' element={<ProfilePage />} /> */}
            <Route path='/profile' element={<UserProfilePage />} />
            <Route path='/feedback' element={<FeedbackPage />} />
            <Route path='/balance' element={<BalancePage />} />
            <Route path='/recharge' element={<RechargePage />} />
            <Route path='/purchase' element={<PurchasePage />} />
            <Route path='/order' element={<OrderPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="/recharge-error" element={<RechargeErrorPage />} />
          <Route path='/agreement' element={<ServiceAgreementPage />} />
          <Route path='/test' element={<ActivityPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
