export const mapGender = new Map([[-1, '未找到'], [0, '男'], [1, '女']]);
export const regexGender = [
  /(男性|男|男のみ|限男性)/g,  // 男
  /(女性|女|女のみ|限女性)/g,  // 女
];

export const mapCountry = new Map([[-1, '未找到'], [0, '中国'], [1, '日本']]);
export const regexCountry = [
  /(中国籍|中国|china|chinese|中国人|外国籍は日本語非常に流暢なら提案可)/g,  // 中国
  /(日本籍|日本|japan|japanese|日本人|日本籍のみ|日本人が望ましい|日本人技術者のみ|帰化不可)/g,  // 日本
];

export const mapJLPT = new Map([[-1, '未找到'], [0, '流畅'], [1, '普通'], [2, '入门']]);
export const regexJLPT = [
  /(日本人|日本語流暢|日语流畅|日本語ネイティブ|日本語流暢な方のみ|N[12]|N[12]以上|日本語N[12]|日語N[12]|日语N[12]|日本語[12]級|日語[12]級|日语[12]級)/g,  // 流畅
  /(N[34]|日本語N[34]|日語N[34]|日语N[34]|日本語[34]級|日語[34]級|日语[34]級)/g,  // 普通
  /(N[5]|日本語N[5]|日語N[5]|日语N[5]|日本語[5]級|日語[5]級|日语[5]級)/g,  // 入门
];

export const mapWorkStyle = new Map([[-1, '未找到'], [0, '在宅'], [1, '出社'], [2, '部分出勤']]);
export const regexWorkStyle = [
  /(在宅|テレワーク|リモート|基本在宅|リモート可|フルリモート|リモート併用|在宅あり|テレワークも可能|フルリモ|全在宅|副業)/g,  // 在宅
  /(出社|現場出勤|在宅なし|常駐|への出勤|リモートは少ない)/g,  // 出社
  /(部分出勤)/g,  // 部分出勤
];

export const map = new Map([[-1, '未找到'], [0, ''], [1, '']]);
export const regex = [
];