import React from "react";
import InfiniteScroll from 'react-infinite-scroller';
import { Server } from "../../../server/server";
import "./Chat.css";
import Message from "./Message";
import MessageInput from "./MessageInput";
import { WithTranslation, withTranslation } from "react-i18next";

class Chat extends React.Component<ChatProps, ChatState> {

    // 页码，默认为-1
    private pageIndex: number = -1
    // 页大小，默认10
    private pageSize: number = 10

    constructor(props: ChatProps) {
        super(props)
        
        this.state = {
            hasMore: true, // 组件加载默认获取一次数据
            messages:[],
            userCompany: "",
            userName: ""
        }
    }

    render(): React.ReactNode {
        const { t } = this.props
        const placeholder = this.props.placeholder
        // const loginUserId = this.state.loginUserId
        const loginUserId = this.props.loginUserId
        // console.log("loginUserId from props:" + loginUserId)
        const closeSend = this.props.closeSend
        const messages = this.state.messages
        const hasMore = this.state.hasMore
        const list = messages.map((value) => {
            const message = value
            return (<Message key={message.id} loginUserId={loginUserId} data={message} ></Message>)
        })
        const defaultStr = t('elements.message.Chat.465483-0') // 不明
        const userName = this.state.userName
        const userCompany = this.state.userCompany
        const companyStr = userCompany ? userCompany : defaultStr
        const userStr = userName ? userName : defaultStr
        return (
            <div className="chat-container">
                <div className="title">
                    <div className="primary">{t('elements.message.Chat.465483-1')}</div>
                    <div className="second">
                        <span>{t('elements.message.Chat.465483-2')}{companyStr}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>{t('elements.message.Chat.465483-3')}{userStr}</span>
                    </div>
                </div>
                <div className="content">
                    <div id="chat" className="chat" style={{height:"300px", overflow:"auto"}}>
                        <InfiniteScroll 
                            pageStart={0}
                            hasMore={hasMore}
                            loadMore={this.loadMoreMessage} 
                            loader={<div className="loader" key={0}>Loading ...</div>}
                            isReverse={true}
                            useWindow={false}>
                            {list}
                        </InfiniteScroll>
                    </div>
                    {!closeSend &&
                        <MessageInput placeholder={placeholder} onSend={this.onMessageSend}></MessageInput>
                    }
                </div>
            </div>
        )
    }

    // 交流区域滚动到底部
    scrollToBottom = () => {
        // this.messagesEnd.scrollIntoView({ behavior: "auto" });
        const chatBox = document.getElementById('chat');
        chatBox.scrollTop = chatBox.scrollHeight
    }

    // 发送消息
    onMessageSend = async (message:string, isInterviewMsg: boolean = false) => {
        if (!message || message.length == 0) {
            return
        }

        const {type, caseId, talentId} = this.props
        Server.msg.send(type, {caseId, talentId, text: message, isInterviewMsg}).then(res => {
            if (!res.success) {
                console.log(res.message)
                return
            }
            // 重新加载第一页数据
            this.pageIndex = -1
            setTimeout(() => {
                this.loadMoreMessage()
            }, 1000)
            
        }).catch ((err) => {
            console.log(err)
        })
    }

    // 滚动到顶部，加载更多数据
    loadMoreMessage = async () => {
        // console.log('loadMoreMessage')
        const {type, caseId, talentId} = this.props
        // console.log(JSON.stringify(this.props))
        const isFirstPage = this.pageIndex == -1
        const pageIndex = isFirstPage ? -1 : this.pageIndex
        const pageSize = this.pageSize
        Server.msg.loadNextPage(type, pageIndex, pageSize, caseId, talentId).then(res => {
            if (!res.success) {
                console.log(res.message)
                return
            }
            const data = res.data
            if (data) {
                const { list, index, pages, userName, userCompany } = data
                let newMessages = []
                const newList = [...list].reverse()

                if(!isFirstPage) {
                    let messages = [...this.state.messages]
                    messages.unshift(...newList)
                    newMessages = messages
                    // console.log(newMessages)
                } else {
                    newMessages = newList
                    // console.log(newMessages)
                }

                const hasMore = (index + 1) < pages
                this.pageIndex = this.pageIndex + 1
                this.setState({
                    messages : newMessages,
                    userName: userName,
                    userCompany: userCompany,
                    hasMore : hasMore,
                }, () => {
                    this.scrollToBottom()
                })
            }
        }).catch ((err) => {
            console.log(err)
        })
    }
}

export default withTranslation('translation', { withRef: true })(Chat);

interface ChatProps extends WithTranslation {
    // 1:案件匹配要员用 2:要员匹配案件用
    type: number,
    // 案件id
    caseId: string,
    // 要员id
    talentId: string,
    // 输入提示
    placeholder: string
    // 当前登录用户id
    loginUserId: string
    /** 是否关闭发送功能 */
    closeSend?: boolean
    
    ref: any
}

interface ChatState {
    // 是否有更多数据
    hasMore: boolean,
    // 消息列表
    messages: any[]
    // 所属营业者
    userName: string
    // 所属营业着公司简称
    userCompany: string
}