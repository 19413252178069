import React from "react";
import withNavigation from "../../util/withNavigation";
import './MatchedCaseList.css';
import { MatchedCaseStatus, getMathcedCaseStatus, getMathcedCaseTotal } from "../../common/common";
import { WithTranslation, withTranslation } from "react-i18next";
import { BsLock, BsUnlock } from "react-icons/bs";

/**
 * 组件-要员匹配的案件列表
 */
class MatchedCaseList extends React.Component<MatchedCaseListProps, any> {
    constructor(props:MatchedCaseListProps) {
        super(props)
    }

    render(): React.ReactNode {
        const { t } = this.props
        const list = this.props.list || []
        const isPremiumUser = this.props.isPremiumUser
        
        const matchedList = list.map((item:any) => {
            // console.log(item)
            // 匹配id
            const mid = item.id
            // 案件id
            const caseId = item.caseId
            // 案件概要
            const caseBrief = item.caseBrief
            // 匹配度
            const matchedPercent = item.matchedPercent
            // 去掉%百分号只取数值部分（存在小数点）
            const matchedPercentNum = Number(matchedPercent.replace(/%/g, ''))
            // 匹配的案件关系状态
            const matchedCaseStatus = item.matchedCaseStatus
            const matchedCaseStatusStr = getMathcedCaseStatus(matchedCaseStatus, true, t)
            // 匹配的案件成交及待面试要员数量统计
            const dealCount = item.caseDealCount
            const caseInterviewCount = item.caseInterviewCount
            const totalStr = getMathcedCaseTotal(dealCount, caseInterviewCount)
            // 是否已解锁
            const isUnlock = item.isUnlock || isPremiumUser
            // 大于90%加粗字体
            const isBold = matchedPercentNum.valueOf() >= Number(90).valueOf()
            return(
                <div className={`case-item ${matchedCaseStatus == MatchedCaseStatus.CLOSED ? 'closed' : '' } `} style={{fontWeight: `${isBold ? 'bolder' : ''} `}} key={caseId} onClick={()=>this.onCaseClick(mid)}>
                    {!isUnlock ?  <BsLock color="burlywood"/> : <BsUnlock color="burlywood"/>}
                    {matchedCaseStatusStr && <div className="status">{matchedCaseStatusStr}</div>}
                    <div className="brief" title={caseBrief + ' ' + matchedPercent}>{caseBrief}</div>
                    <div className="total">{totalStr}</div>
                </div>
            )
        })

        return(
            <div className="matched-case-container">
                <div className="title">{t('elements.talent.MatchedCaseList.968627-0')}</div>
                {matchedList.length ? <div>{matchedList}</div> : <span>{t('elements.talent.MatchedCaseList.968627-1')}</span>}
            </div>
        )
    }

    /**
     * 要员匹配的案件点击事件
     * 
     * @param id 匹配id
     */
    onCaseClick(id: string): void {
        // 跳转到他社案件详情页面
        const navigate = this.props.navigate
        navigate('/talent-matched-case?id=' + id)
    }
}

interface MatchedCaseListProps extends WithTranslation {
    // 当前案件
    case: any
    // 案件匹配的要员列表
    list?: []
    // 当前用户是否为付费用户
    isPremiumUser: boolean
    // 导航
    navigate: any
}

export default withTranslation()(withNavigation(MatchedCaseList));