import Api from "../app/api/Api";
import { Service } from "./service";

export class AppService extends Service {

    constructor(){
        super()
    }

    async getTopics() {
        // 获取案件和要员的主题列表
        return await Api.get('/api/app/topics')
    }

    async feedback(email:string, content:string) {
        // 反馈提交
        return await Api.post('/api/feedback/post', {email, content})
    }
}