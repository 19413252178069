import { Flex } from 'antd';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BsArrowClockwise, BsFillXCircleFill } from 'react-icons/bs';
import { LoginService } from '../../server/LoginService';
import { Server } from '../../server/server';
import Api from '../api/Api';
import { subscribe, unsubscribe } from '../util/event';
import { isValidEmail } from '../util/util';
import AlertModal from './AlertModal';
import EditUserInfoModal4Wx from './EditUserInfoModal4Wx';
import './LoginModal.css';
import MessageModal from './MessageModal';
import './Modal.css';
import ResetPasswordModal from './ResetPasswordModal';

interface LoginModalProps extends WithTranslation {
  open: boolean;
  showClose?: boolean;
  onClose?: Function;
}

interface LoginModalState {
  activeTabIndex: number;
  qrCodeUrl: string;
  qrCodeScene: string;
  isExpiredQrCode: boolean; // 二维码是否过期或获取失败
  email: string;
  password: string;
  message: string;
  alert: string;
}

class LoginModal extends React.Component<LoginModalProps, LoginModalState> {

  public loginService: LoginService;
  private resetPasswordModalRef: any;
  private editUserInfoModalRef: any;
  private token: string;
  private timer: any;
  private count: number = 60;

  constructor(props:LoginModalProps) {
    super(props);

    this.state = {

      activeTabIndex: 0,
      qrCodeUrl: '',
      qrCodeScene: '',
      isExpiredQrCode: false,

      email: '',
      password: '',

      message: '',
      alert: ''
    }
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    // const [userData, setUserData] = useState({email: '', password: ''});

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForgetPwd = this.handleForgetPwd.bind(this);
    this.handleQrCodeExpiredClick = this.handleQrCodeExpiredClick.bind(this);
    this.loginService = new LoginService()

    this.resetPasswordModalRef = React.createRef()
    this.editUserInfoModalRef = React.createRef()
  }

  handleClose = () => {
    if (this.timer) {
      this.stopRequestToken()
    }
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleChange = (e:any):void => {
    // console.log(e)
    if (e.target.name === 'email') {
      this.setState({
        email: e.target.value
      })
    }
    if (e.target.name === 'password') {
      this.setState({
        password: e.target.value
      })
    }
  }

  handleTabClick = (tabActiveIndex: number) => {
    this.setState({
      activeTabIndex: tabActiveIndex
    })
  }

  handleQrCodeExpiredClick = () => {
    // 获取二维码并展示
    this.getQrCode()
  }

  handleSubmit = (e:any) => {
    const { t } = this.props
    const email = this.state.email;
    const password = this.state.password;

    if (email.length === 0) {
      this.setState({alert: t('modals.LoginModal.639192-0')})
      return
    }
    if(!isValidEmail(email)) {
        this.setState({alert: t('modals.LoginModal.639192-1')})
        return
    }
    
    if (password.length === 0) {
        return this.setState({alert: t('modals.LoginModal.639192-2')})
    }

    this.setState({
      message: t('modals.LoginModal.639192-4')
    })
    this.loginService.login({email, password}).then(res => {
      if (!res.success) {
        return this.setState({
          alert: res.message,
          message: ''
        })
      }

      // 设置token
      this.token = res.data

      // 清除输入信息
      this.setState({
        email: '',
        password: '',
        message: ''
      })
      
      // 登录成功
      this.setTokenAndLoginNotify(this.token)

    }).catch(e => {
      console.error(e)
      this.setState({
        alert: t('modals.LoginModal.639192-3')
      })
    })
  }

  handleForgetPwd = (e: any) => {
    // 忘记密码
    this.resetPasswordModalRef.current.show()
  }

  onEditUserInfoSuccess = () => {
    // 修改用户信息成功后的回调函数
    this.setTokenAndLoginNotify(this.token)
  }

  onBindUserSuccess = (newToken:string) => {
    // 绑定微信成功后的回调函数
    this.setTokenAndLoginNotify(newToken)
  }

  setTokenAndLoginNotify = (token:string) => {
    // 关闭窗口
    this.handleClose()
    // 保存token
    localStorage.setItem('token', token)
    // 登录成功通知
    Server.user.notifyListeners('user-login')
  }

  getQrCode = async () => {

    await this.loginService.qrcode().then(res => {
      // const { success, message, data } = res
      if (!res.success) {
        return this.setQrCodeFailedOrExpired()
      }

      const { qrcode, scene } = res.data

      // 展示二维码
      this.setQrCode(qrcode, scene)
      // 每隔1秒请求一次扫码后用户登录凭证token（用户是否关注公众号），总时长为60秒（二维码过期时间）
      this.startRequestToken(scene)

      // 60秒后过期
      setTimeout(()=> {
        this.setQrCodeFailedOrExpired()
      }, 60000)

    }).catch(err=> {
      this.setQrCodeFailedOrExpired()
    })
  }

  startRequestToken(scene:string) {
    this.timer = setInterval(()=> {
      this.requestTokenByScanQrCode(scene)
    }, 1000)
  }

  stopRequestToken() {
    clearInterval(this.timer)
    this.timer = null
    this.count = 60
  }

  requestTokenByScanQrCode(scene: string) {
    if (this.count <= 0 ){
      return this.stopRequestToken()
    }
    this.count++

    // console.log('requestTokenByScanQrCode')
    this.loginService.qrcodeLoginCheck(scene).then(res => {
      if(res.success && res.data){
        // console.log('找到token：', res.data)
        const token = res.data

        // 让二维码过期
        this.setQrCodeFailedOrExpired()

        // 获取用户信息
        Api.get('/api/user/profile', token).then(res => {
          if(res.success) {
            const {email, lastName, firstName} = res.data
            if (!email || !lastName || !firstName) {
              // 如果没有邮件或完善姓名，弹出完善页面
              this.token = token
              this.editUserInfoModalRef.current.show(token)
            } else {
              this.token = token
              this.setTokenAndLoginNotify(this.token)
            }
          }
        })
      }
    }).catch(err => {
      console.error(err)
    })

  }

  /**
   * 展示公众号二维码
   * 
   * @param qrcode 二维码地址
   * @param scene scene字符串
   */
  setQrCode(qrcode:string, scene:string) {
    this.setState({
      qrCodeUrl: qrcode,
      qrCodeScene: scene,
      isExpiredQrCode: false
    })
  }

  /**
   * 展示二维码获取失败或过期
   */
  setQrCodeFailedOrExpired() {
    this.setState({
      qrCodeUrl: '',
      qrCodeScene: '',
      isExpiredQrCode: true,
    })
    if(this.timer) {
      this.stopRequestToken()
    }
  }


  componentDidMount(): void {
    // console.log('componentDidMount')
    // 订阅显示二维码事件
    subscribe('login.event.show-qrcode', this.getQrCode);
    
  }

  componentWillUnmount(): void {
    // 取消订阅显示二维码事件
    unsubscribe('login.event.show-qrcode', this.getQrCode)
  }
  
  render() {
    const { t } = this.props
    if(!this.props.open)
      return (<div></div>);

    // if(this.state.alert != '')
    //   return (<AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>)

    const activeTabIndex = this.state.activeTabIndex;
    const qrCodeUrl = this.state.qrCodeUrl;
    const isExpiredQrCode = this.state.isExpiredQrCode;
    return (
      <div className="modal open">
        <div className="modal-content">
          {
            this.props.showClose && 
            <button className="modal-close-button" onClick={this.handleClose}>
              <BsFillXCircleFill />
            </button>
          }

          <div><h3>{t('modals.LoginModal.639192-5')}</h3></div>
          <div className='login-box'>
            <div className='login-box-tabs'>
              <div className='login-box-tabs-titles'>
                {/* <span className="tips">扫码登录更方便</span> */}
                <span className={`${activeTabIndex === 0 ? 'active' : ''}`} onClick={() => {this.handleTabClick(0)}}>{t('modals.LoginModal.639192-6')}</span>
                <span className={`${activeTabIndex === 1 ? 'active' : ''}`} onClick={() => {this.handleTabClick(1)}}>{t('modals.LoginModal.639192-7')}</span>
              </div>
              <div className='login-box-tabs-contents'>
                {/* 微信公众号登录 */}
                <div className={'login-wechat-mp ' + (activeTabIndex === 0 ? 'show' : 'hide')}>
                  {/* 二维码失效 */}
                  {isExpiredQrCode && (
                  <div className='login-wechat-mp-code expired' onClick={() => this.handleQrCodeExpiredClick()}>
                    <span className='mark'></span>
                    <span className='retry-icon'>
                      <BsArrowClockwise size={40}/>
                    </span>
                    <span className='retry-name'>{t('modals.LoginModal.639192-8')}</span>
                  </div>
                  )}

                  {/* 二维码正常显示 */}
                  {!isExpiredQrCode && (
                  <div className='login-wechat-mp-code'>
                    {qrCodeUrl && <img className='qrcode' src={qrCodeUrl}></img>}
                    {/* 二维码在此 */}
                  </div>
                  )
                  }
                  
                  {/* 扫码提示 */}
                  <div className='login-wechat-mp-tips'>
                    {t('modals.LoginModal.639192-9')}
                  </div>
                </div>
                {/* 账号密码登录 */}
                <div className={'login-form ' + (activeTabIndex === 1 ? 'show' : 'hide')}>
                  <div className="login-form-item">
                    <div className="base-input">
                      <input placeholder={t('modals.LoginModal.639192-10')} type="email" name="email" className="base-input-text" value={this.state.email} onChange={this.handleChange} maxLength={32}></input>
                    </div>
                  </div>
                  <div className="login-form-item">
                    <div className="base-input">
                      <input placeholder={t('modals.LoginModal.639192-11')} type="password" name="password" className="base-input-text" value={this.state.password} onChange={this.handleChange} maxLength={32}></input>
                    </div>
                    <Flex className="base-input" justify='flex-end'>
                      <a className='forget-password' onClick={this.handleForgetPwd}>{t('modals.LoginModal.639192-12')}</a>
                    </Flex>
                  </div>
                  <div className="login-form-item">
                    <div className='bottom-group'>
                      <button className="base-button" onClick={this.handleSubmit}>{t('modals.LoginModal.639192-5')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <ResetPasswordModal ref={this.resetPasswordModalRef}></ResetPasswordModal>
        {/* <EditUserInfoModal ref={this.editUserInfoModalRef} onEditUserInfoSuccess={this.onEditUserInfoSuccess}></EditUserInfoModal> */}
        <EditUserInfoModal4Wx ref={this.editUserInfoModalRef} onEditUserInfoSuccess={this.onEditUserInfoSuccess} onBindUserSuccess={this.onBindUserSuccess}></EditUserInfoModal4Wx>
        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
      </div>
    )
  }
}

export default withTranslation('translation', { withRef : true })(LoginModal);