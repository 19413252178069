import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import '../modals/Modal.css';
import './LanguageMenu.css';

interface LanguageMenuMenuProps extends WithTranslation {
  open: boolean;
  pos: { left?: number, top?: number, right?: number, bottom?: number };
  onClose: Function;
}

class LanguageMenu extends React.Component<LanguageMenuMenuProps, any> {
  constructor(props:LanguageMenuMenuProps) {
    super(props);
  }

  render() {
    const { t } = this.props
    if(!this.props.open) {
      return (null);
    }

    const left = this.props.pos.left
    const right = this.props.pos.right
    const top = this.props.pos.top
    const bottom = this.props.pos.bottom

    const styles: React.CSSProperties = {
      left: (left != null && !Number.isNaN(left)) ? left : undefined,
      top: (top != null && !Number.isNaN(top)) ? top : undefined,
      right: (right != null && !Number.isNaN(right)) ? right : undefined,
      bottom: (bottom != null && !Number.isNaN(bottom)) ? bottom : undefined,
    }
    return (
      <div>
        <div className="language-menu-panel" onClick={this.onClose} style={styles}>
          <div className='language-menu-item' onClick={() => {this.onLanguageClick('ja');}}>{t('elements.BottomBar.705461-0')}</div>
          <div className='language-menu-item' onClick={() => {this.onLanguageClick('zh');}}>{t('elements.BottomBar.705461-1')}</div>
        </div>
        <div className="language-menu-modal" onClick={this.onClose}></div>
      </div>
    )
  }

  onClose = () => {
    this.props.onClose();
  }

  // 多语言切换
  changeLanguage = () => {
    // console.log('change language')
    this.setState({
      showLanguage: true
    })
  }

  onLanguageClick = (lng:string) => {
    i18n.changeLanguage(lng, (err, t) => {
        // console.log(err)
        localStorage.setItem('lan', lng)
    })

    this.onClose();
  }
}

export default withTranslation()(LanguageMenu);