import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { BsFillXCircleFill } from 'react-icons/bs';
import { Server } from '../../server/server';
import { isPhoneNumber, isValidEmail } from '../util/util';
import AlertModal from './AlertModal';
import MessageModal from './MessageModal';
import Api from '../api/Api';
import ResetPasswordModal from './ResetPasswordModal';
import './Modal.css';
import './EditUserInfoModal4Wx.css';

class EditUserInfoModal4Wx extends React.Component<EditUserInfoModal4WxPro, EditUserInfoModal4WxState> {
    private userInfo:any
    private token:string
    private timer: any = null
    private bindWxModalRef: any = null;
    private resetPasswordModalRef: any = null;

    constructor(props: EditUserInfoModal4WxPro) {
        super(props)
        this.state = {
            activeTabIndex: 0,
            email: '',
            code: '',
            inviteCode: '',
            firstName: '',
            lastName: '',
            mobile: '',
            company: '',
            companyShort: '',
            isSendEmail: false,
            count: 60,

            bindEmail: '',
            bindPwd: '',

            show: false,
            message:'',
            alert: '',
        }
        this.bindWxModalRef = React.createRef()
        this.resetPasswordModalRef = React.createRef()
    }

    componentWillUnmount(): void {
        this.stopCountdown()
    }

    render() {
        const { t } = this.props
        if (!this.state.show) {
            return(<></>)
        }
        
        const activeTabIndex = this.state.activeTabIndex
        const isSendEmail = this.state.isSendEmail
        const count = this.state.count
        return (
            <div className="modal open">
                <div className="modal-content">
                    <button className="modal-close-button" onClick={this.hide}>
                        <BsFillXCircleFill />
                    </button>
                    {/* <div className="modal-title">
                        <h3>{t('modals.EditUserInfoModel4Wx.title')}</h3>
                    </div> */}
                    <div className='user-box'>
                        <div className='user-box-tabs'>
                            <div className='user-box-tabs-titles'>
                                <span className={`${activeTabIndex === 0 ? 'active' : ''}`} onClick={() => {this.handleTabClick(0)}}>{t('modals.EditUserInfoModel4Wx.tab.title.01')}</span>
                                <span className={`${activeTabIndex === 1 ? 'active' : ''}`} onClick={() => {this.handleTabClick(1)}}>{t('modals.EditUserInfoModel4Wx.tab.title.02')}</span>
                            </div>
                        </div>
                    </div>

                    <div className='modal-main'>
                        {/* 完善账号 */}
                        <form className={'modal-form ' + (activeTabIndex === 0 ? 'show' : 'hide')} >
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.EditUserInfoModel4Wx.email')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        id='email'
                                        name='email'
                                        placeholder={t('modals.EditUserInfoModel4Wx.input-placeholder-email')}
                                        maxLength={32}
                                        value={this.state.email}
                                        onChange={(e)=> {this.setState({ email: e.currentTarget.value })}}/>
                                </div>
                                <div style={{fontSize: '60%', textAlign: 'justify'}}>
                                    {t('modals.EditUserInfoModel4Wx.email-tips')}
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.EditUserInfoModel4Wx.code')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModel4Wx.input-placeholder-code')}
                                        name="code"
                                        value={this.state.code}
                                        onChange={(e) => this.setState({code: e.target.value})}
                                        maxLength={8}/>
                                
                                {
                                isSendEmail ? (
                                    <div className="countdown">{count}{t('modals.EditUserInfoModel4Wx.countdown-unit')}</div>
                                ) : (
                                    <div className='sendcode-btn' onClick={this.onSendCodeClick}>{t('modals.EditUserInfoModel4Wx.btn-send-code')}</div>
                                )
                                }
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.EditUserInfoModel4Wx.name')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModel4Wx.input-placeholder-lastname')}
                                        name="lastName"
                                        value={this.state.lastName}
                                        onChange={(e) => this.setState({lastName: e.target.value})}
                                        maxLength={16}/>
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModel4Wx.input-placeholder-firstname')}
                                        name="firstName"
                                        value={this.state.firstName}
                                        onChange={(e) => this.setState({firstName: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label title="">{t('modals.EditUserInfoModel4Wx.mobile')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModel4Wx.input-placeholder-mobile')}
                                        name="mobile"
                                        value={this.state.mobile}
                                        onChange={(e) => this.setState({mobile: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label title="">{t('modals.EditUserInfoModel4Wx.company')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModel4Wx.input-placeholder-company')}
                                        name="company"
                                        value={this.state.company}
                                        onChange={(e) => this.setState({company: e.target.value})}
                                        maxLength={32}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label title="">{t('modals.EditUserInfoModel4Wx.companyshort')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModel4Wx.input-placeholder-companyshort')}
                                        name="companyShort"
                                        value={this.state.companyShort}
                                        onChange={(e) => this.setState({companyShort: e.target.value})}
                                        maxLength={6}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label title="">{t('modals.EditUserInfoModel4Wx.invite-code')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModel4Wx.input-placeholder-invite-code')}
                                        name="mobile"
                                        value={this.state.inviteCode}
                                        onChange={(e) => this.setState({inviteCode: e.target.value})}
                                        maxLength={6}/>
                                </div>
                            </div>
                            <div>
                                <button className="base-button" type="button" onClick={this.handleSubmit}>{t('modals.EditUserInfoModel4Wx.btn-post')}</button>
                            </div>
                        </form>

                        {/* 绑定账号 */}
                        <form className={'modal-form ' + (activeTabIndex === 1 ? 'show' : 'hide')}>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.EditUserInfoModel4Wx.tab.bind.title.email')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        id='bind-email'
                                        name='bind-email'
                                        placeholder={t('modals.EditUserInfoModel4Wx.tab.bind.title.email')}
                                        maxLength={32}
                                        value={this.state.bindEmail}
                                        onChange={(e)=> {this.setState({ bindEmail: e.currentTarget.value })}}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.EditUserInfoModel4Wx.tab.bind.title.pwd')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        id='bind-password'
                                        name='bind-password'
                                        type="password"
                                        placeholder={t('modals.EditUserInfoModel4Wx.tab.bind.title.pwd')}
                                        maxLength={32}
                                        value={this.state.bindPwd}
                                        onChange={(e)=> {this.setState({ bindPwd: e.currentTarget.value })}}/>
                                </div>
                                <div className="forget-btn">
                                    <a className='forget-password' onClick={this.handleForgetPwd}>{t('modals.EditUserInfoModel4Wx.tab.bind.btn.forgetpwd')}</a>
                                </div>
                            </div>
                            <div>
                                <button className="base-button" type="button" onClick={this.handleBindSubmit}>{t('modals.EditUserInfoModel4Wx.tab.bind.btn.bind')}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <ResetPasswordModal ref={this.resetPasswordModalRef}></ResetPasswordModal>
                <MessageModal message={this.state.message} />
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
            </div>
        )
    }

    hide = () => {
        this.setState({
            show: false
        })
        this.clear()
    }

    show = (token:string) => {
        this.token = token

        this.setState({
            show: true
        }, () => {
            this.loadInfo(token)
        })
    }

    handleTabClick = (tabActiveIndex: number) => {
        this.setState({
          activeTabIndex: tabActiveIndex
        })
    }

    // 绑定微信openid
    showBindWx = () => {
        this.bindWxModalRef.current.show(this.token)
    }

    // 发送邮箱验证码
    onSendCodeClick = async () => {
        const { t } = this.props
        const email = this.state.email
        if(!email) {
            return this.setState({alert: t('modals.EditUserInfoModel4Wx.msg-0')})
        }
        if(!isValidEmail(email)) {
            return this.setState({alert: t('modals.EditUserInfoModel4Wx.msg-1')})
        }

        // 开启倒计时
        this.startCountdown()

        // 发送验证码
        Server.email.sendCode(email).then(res => {
            if(!res.success) {
                this.stopCountdown()
                return this.setState({ alert: res.message })
            }
        }).catch(err => {
            console.log(err)
            this.stopCountdown()
            this.setState({ alert: t('modals.EditUserInfoModel4Wx.msg-2') })
        })
    }
    
    handleSubmit = () => {
        const { t } = this.props
        const email = this.state.email
        const code = this.state.code
        const inviteCode = this.state.inviteCode
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const mobile = this.state.mobile
        const company = this.state.company
        const companyShort = this.state.companyShort
        if(!email) {
            return this.setState({alert: t('modals.EditUserInfoModel4Wx.msg-0')})
        }
        if(!isValidEmail(email)) {
            return this.setState({alert: t('modals.EditUserInfoModel4Wx.msg-1')})
        }

        if(!code) {
            return this.setState({alert: t('modals.EditUserInfoModel4Wx.msg-3')})
        }

        if (!firstName || !lastName) {
            return this.setState({alert: t('modals.EditUserInfoModel4Wx.msg-4')})
        }
        if(mobile && !isPhoneNumber(mobile)) {
            return this.setState({alert: t('modals.EditUserInfoModel4Wx.msg-5')})
        }
        // console.log("token:" + this.token)
        Server.user.updateWxUserInfo(email, code, firstName, lastName, company, companyShort, mobile, inviteCode, this.token).then(res => {
            if (!res.success) {
                return this.setState({alert: res.message})
            }

            this.hide()
            
            // 更新用户信息
            this.userInfo['email'] = email
            this.userInfo['firstName'] = firstName
            this.userInfo['lastName'] = lastName
            this.userInfo['company'] = company
            this.userInfo['companyShort'] = companyShort
            this.userInfo['mobile'] = mobile
            Server.user.setProfile(this.userInfo)

            // 更新成功后回调
            if(this.props.onEditUserInfoSuccess) {
                this.props.onEditUserInfoSuccess()
            }
        }).catch(e => {
            console.log(e)
            this.setState({alert: t('modals.EditUserInfoModel4Wx.msg-6')})
        })
    }

    loadInfo = (token:string) => {
        if (token) {
            // 从扫公众号二维码过来的情况（没有完善信息或第一次使用微信扫码登录）
            Api.get('/api/user/profile', token).then(res => {
                if (!res.success) {
                    this.userInfo = {}
                    return
                }

                const user = res.data
                this.userInfo = user
                this.setState({
                    email: user.email || '',
                    firstName: user.firstName || '',
                    lastName: user.lastName || '',
                    mobile: user.mobile || '',
                    company: user.company || '',
                    companyShort: user.companyShort || ''
                })
            }).catch(e => {
                console.log(e)
            })
            return
        }
    }

    clear = () => {
        this.setState({
            // show: false,
            email: '',
            code: '',
            isSendEmail: false,
            inviteCode: '',
            firstName: '',
            lastName: '',
            mobile: '',
            company: '',
            companyShort: '',
            count: 60,

            bindEmail: '',
            bindPwd: '',

            alert: '',
            message: ''
        })
        this.token = null
    }

    // 开启倒计时
    startCountdown = () => {
        this.setState({
            isSendEmail: true,
            count: 60
        })
        this.timer = setInterval(()=> {
            if (this.state.count > 1) {
                this.setState({
                    count: this.state.count - 1
                })
            } else {
                this.stopCountdown()
            }
        }, 1000)
    }

    // 停止倒计时
    stopCountdown = () => {
        this.setState({
            isSendEmail: false,
            count: 60
        })
        this.resetTimer()
    }

    // 清空计时器
    resetTimer = () => {
        if (this.timer) {
            clearInterval(this.timer)
        }
        this.timer = null
    }

    // 绑定已有账号
    handleBindSubmit = (e:any) => {
        const { t } = this.props
        const email = this.state.bindEmail
        const password = this.state.bindPwd
        const oldToken = this.token
        console.log('bind submit', email, password, oldToken)
        if (email.length === 0) {
            this.setState({alert: t('modals.EditUserInfoModel4Wx.tab.bind.msg-01')})
            return
        }
        if(!isValidEmail(email)) {
            this.setState({alert: t('modals.EditUserInfoModel4Wx.tab.bind.msg-02')})
            return
        }
        
        if (password.length === 0) {
            return this.setState({alert: t('modals.EditUserInfoModel4Wx.tab.bind.msg-03')})
        }

        // 绑定当前扫码的微信openid到指定的账户
        Server.user.bindWxOpenId(email, password, oldToken).then(res => {
            if (!res.success) {
                return this.setState({
                    alert: res.message,
                })
            }

            this.hide()

            if (this.props.onBindUserSuccess) {
                const newToken = res.data
                this.props.onBindUserSuccess(newToken)
            }

        }).catch(e => {
            console.error(e)
            this.setState({
            alert: t('modals.EditUserInfoModel4Wx.tab.bind.msg-04')
            })
        })

    }
    
    handleForgetPwd = (e: any) => {
        // 忘记密码
        this.resetPasswordModalRef.current.show()
    }
    
    setTokenAndLoginNotify = () => {
        // 关闭窗口
        this.hide()
        // 保存token
        localStorage.setItem('token', this.token)
        // 登录成功通知
        Server.user.notifyListeners('user-login')
    }
}

interface EditUserInfoModal4WxPro extends WithTranslation {
    onEditUserInfoSuccess: Function,
    onBindUserSuccess: Function,
    ref: any
}

interface EditUserInfoModal4WxState {
    activeTabIndex: number

    // 完善账号
    email: string
    code: string
    inviteCode: string
    firstName: string
    lastName: string
    mobile: string
    company: string
    companyShort: string
    isSendEmail: boolean
    count: number

    // 绑定已有账号
    bindEmail:string
    bindPwd:string

    // 是否显示
    show: boolean

    message:string
    alert: string
    
}

export default withTranslation('translation', { withRef : true })(EditUserInfoModal4Wx);