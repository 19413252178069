import React from 'react';
import { Server } from '../../server/server';
import NavBarButton from './NavBarButton';
import './NavBar.css';
import { AppConfig } from '../AppConfig';
import { getMenuIcon } from '../util/util';
import { WithTranslation, withTranslation } from 'react-i18next';

class NavBar extends React.Component<WithTranslation, any> {
  constructor(props:any) {
    super(props)
    this.onUserLogin = this.onUserLogin.bind(this);
    this.onUserLogout = this.onUserLogout.bind(this);
  }

  componentDidMount() {
    Server.user.addEventListener('user-login', this.onUserLogin);
    Server.user.addEventListener('user-logout', this.onUserLogout);
  }

  componentWillUnmount(): void {
    Server.user.removeEventListener('user-login', this.onUserLogin);
    Server.user.removeEventListener('user-logout', this.onUserLogout);
  }

  // 用户登录回调函数
  onUserLogin() {
    // console.log('用户登录事件')
    this.forceUpdate()
  }

  // 用户退出登录回调函数
  onUserLogout() {
    // console.log('用户退出登录事件')
    this.forceUpdate()
  }

  renderButton(menu: any) {
    const { t } = this.props
    return (
      <NavBarButton key={menu.text} icon={getMenuIcon(menu.icon)} text={t(menu.text)} to={menu.to} />
    )
  }

  render() {
    let buttons = [];
    let menu = AppConfig.menu;

    for (let i = 0; i < menu.length; i++) {
      if (menu[i].loggedIn) {
        if (Server.user.isLogin())
          buttons.push(this.renderButton(menu[i]));
      }
      else
        buttons.push(this.renderButton(menu[i]));
    }
    
    return (
      <nav>
        <div className="navbar-container">{buttons}</div>
      </nav>
    );
  }
}

export default withTranslation()(NavBar);