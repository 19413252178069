import Api from "../app/api/Api";
import { Service } from "./service";

export class GoodsService extends Service {

    constructor(){
        super()
    }

    async getAllRechargeGoods() {
        // 获取充值商品列表
        return await Api.get('/api/goods/recharge-goods')
    }

    async getRechargeGoods(id:string) {
        // 获取指定的充值商品信息
        return await Api.get('/api/goods/recharge-goods/'+id)
    }

    async getPurchaseCaseGoods() {
        // 获取需要付费的特定案件商品信息
        return await Api.get('/api/goods/purchase-goods/case')
    }
    async getPurchaseTalentGoods() {
        // 获取需要付费的特定要员商品信息
        return await Api.get('/api/goods/purchase-goods/talent')
    }
    async getPurchasePremiumGoods() {
        // 获取需要付费的会员商品信息
        return await Api.get('/api/goods/purchase-goods/premium')
    }
}