import Api from "../app/api/Api";
import { Service } from "./service";

export class UserUnlockService extends Service {

    constructor(){
        super()
    }

    /**
     * 检查要员或案件是否已经激活
     * 
     * @param unlockType 激活案件或要员区分
     * @param mid 匹配id
     * @param caseId 匹配的案件id
     * @param talentId 匹配的要员id
     * @returns 
     */
    async isMyUnlockItem(unlockType:number, mid:string, caseId:string, talentId:string) {
        // 检查要员或案件是否已经激活
        return await Api.post('/api/unlock/is-my-unlock', {unlockType, mid, caseId, talentId})
    }
}