import dayjs from "dayjs"
import React from "react"
import { MatchedCaseStatus, MatchedTalentStatus, getInterviewTimes, getInterviewWay } from "../../common/common"
import { MM_DD_HH_MM, YYYY_MM_DD_HH_MM, utc2Local } from "../../util/date"
import './InterviewList.css'
import { WithTranslation, withTranslation } from "react-i18next"

/**
 * 组件-要员面试情况一览
 */
class InterviewList extends React.Component<InterviewListProps, any> {
    // private talentId:string
    // 对照案件id
    // private refCaseId:string
    constructor(props:InterviewListProps) {
        // console.log('constructor InterviewList')
        super(props)
    }

    render(): React.ReactNode {
        const { t } = this.props
        // console.log('render InterviewList')
        const title = this.props.title
        const list = this.props.list || []
        const refCaseId = this.props.refCaseId
        const now = dayjs()
        const interviewList = list.map((item, index) => {
            // let mid = item.mid
            let leftText = ''
            let rightText = ''
            const {times, time, way, timesTotal,
                caseId, caseUserCompany, caseUserName, caseBrief,
                matchedCaseStatus,
                matchedTalentStatus} = item
            // 面试次数
            const timesStr = getInterviewTimes(times, timesTotal)
            // 面试时间
            let timeStr = utc2Local(time, YYYY_MM_DD_HH_MM)
            const timeObj = dayjs(timeStr)
            if (timeObj.year() == now.year()) {
                timeStr = timeObj.format(MM_DD_HH_MM)
            }
            // 是否过期
            const isExpiredTime = now.isAfter(timeObj)
            // 面试方式
            const wayStr = getInterviewWay(way)
            const thisCaseStr = t('elements.talent.InterviewList.990018-0') // 此案件
            const otherCaseStr = t('elements.talent.InterviewList.990018-2') // 他案件
            const employDoneStr = t('elements.talent.InterviewList.990018-1') // 已采用
            if(this.props.showCase) {
                // 附加案件信息
                if (refCaseId == caseId) {
                    leftText = timeStr + " " + timesStr + " " +  wayStr + " " + thisCaseStr
                } else {
                    const caseStr = caseUserCompany + " " + caseUserName + "(" + caseBrief + ")"
                    leftText = timeStr + " " + timesStr + " " +  wayStr + " " + caseStr
                }
                if (matchedCaseStatus == MatchedCaseStatus.EMPLOY_DONE && matchedTalentStatus == MatchedTalentStatus.EMPLOY_REQUEST_OK) {
                    rightText = employDoneStr
                }
            } else {
                if (refCaseId == caseId) {
                    leftText = timeStr + " " + timesStr + " " +  wayStr + " " + thisCaseStr
                } else {
                    leftText = timeStr + " " + timesStr + " " +  wayStr + " " + otherCaseStr
                }
            }
            return(
                <div key={index} className={`interview ${isExpiredTime ? 'expired' : '' }`}>
                    {/* <span className="text">{text}</span> */}
                    <div className="left">
                        <span className="text">{leftText}</span>
                    </div>
                    <div className="right">
                        <span className="text">{rightText}</span>
                    </div>
                </div>
            )
        })

        return(
            <div className="interview-list-container">
                <div className="title">{title}</div>
                {interviewList.length == 0 && <div className="content">{t('elements.talent.InterviewList.990018-3')}</div>}
                {interviewList.length > 0 && <div className="content">{interviewList}</div>}
            </div>
        )
    }
}

export default withTranslation('translation', {withRef: true})(InterviewList)

interface InterviewListProps extends WithTranslation {
    // 列表标题
    title: string
    // 对照案件ID
    refCaseId:string
    // 案件面试列表
    list?: Interview[]
    // 是否显示案件信息（案件所属用户、所属公司、案件概要） 
    showCase:boolean

    ref:any
}

interface Interview {
    // 匹配id
    mid:string
    // 类型
    type: number
    // 面试总次数
    timesTotal:number,
    // 面试次数
    times:number
    // 面试时间
    time: Date
    // 面试方式
    way: number
    // 要去面试的匹配的案件id
    caseId: string
    // 案件所属用户公司简称
    caseUserCompany?:string
    // 案件所属用户姓名
    caseUserName?:string
    // 要去面试的案件的概要
    caseBrief?:string,
    // 案件关系状态
    matchedCaseStatus?:number
    // 要员关系状态
    matchedTalentStatus?:number
}