import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Language } from "../common/common";
import { AppConfig } from "../AppConfig";

class ServiceAgreementPage extends React.Component<WithTranslation, ServiceAgreementPageState> {
    constructor(props: WithTranslation) {
        super(props);
        this.state = {
        };
    }

    render(): React.ReactNode {
        const { i18n } = this.props
        // console.log(i18n.language)
        const content = i18n.language === Language.ja ? AppConfig.agreement_service_jaJP : AppConfig.agreement_service_zhCN
        return (
            <div style={{padding: '20px', whiteSpace: "pre-wrap", textAlign: 'justify'}}>
                {content}
            </div>
        );
    }
}

export default withTranslation()(ServiceAgreementPage);

interface ServiceAgreementPageState {

}