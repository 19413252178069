import React from 'react';
import {Outlet} from 'react-router-dom';
import HeaderBar from '../elements/HeaderBar';
import NavBar from '../elements/NavBar';
import withRouter from '../util/withRouter';
import BottomBar from '../elements/BottomBar';
import { withTranslation } from 'react-i18next';

const HeaderBarWithRouter = withTranslation()(withRouter(HeaderBar));

class SitePage extends React.Component {
  render() {
    return (
      <div className="app-container">
        <div>
          <HeaderBarWithRouter />
        </div>
        <div className="app-content">
          <div className="app-navbar">
            <NavBar />
          </div>
          <div id="id-app-page" className="app-page">
            <Outlet />
          </div>
        </div>
        {/* <div>
            <BottomBar />
        </div> */}
      </div>
    );
  }
}

export default SitePage;
