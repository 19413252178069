import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Server } from '../../server/server';
import { AppConfig } from '../AppConfig';
import SharedQuillEditor from '../elements/SharedQuillEditor';
import TemplateBar from '../elements/case/TemplateBar';
import MyTalentList from '../elements/talent/MyTalentList';
import AlertModal from '../modals/AlertModal';
import MessageModal from '../modals/MessageModal';
import { subscribe, unsubscribe } from '../util/event';
import './MyTalentPage.css';

/**
 * 页面-要员登录与管理
 */
class MyTalentPage extends React.Component<WithTranslation, MyTalentPageState> {
  // 富文本编辑器quill组件的引用
  quillRef: any;
  // 原文文字数量
  wordCount = 0;
  // 当前使用的模版
  tplIndex = 0;
  // 要员列表引用
  myTalentListRef: any;

  constructor(props: any) {
    super(props);

    // 初始化组件状态
    this.state = {
      talents: [],
      loading: false,
      message: '',
      alert: ''
    }

    // 绑定用户登录、登出事件回调函数
    this.onUserLogin = this.onUserLogin.bind(this);
    this.onUserLogout = this.onUserLogout.bind(this);

    // 绑定模版选择事件回调函数
    this.onTalentTplSelected = this.onTalentTplSelected.bind(this);

    this.myTalentListRef = React.createRef()
  }

  // 生命周期-组件装载完成
  componentDidMount() {
    subscribe('templatebar.tpl-selected', this.onTalentTplSelected);
    Server.user.addEventListener('user-login', this.onUserLogin);
    Server.user.addEventListener('user-logout', this.onUserLogout);
  }

  // 生命周期-组件即将卸载
  componentWillUnmount() {
    unsubscribe('templatebar.tpl-selected', this.onTalentTplSelected);
    Server.user.removeEventListener('user-login', this.onUserLogin);
    Server.user.removeEventListener('user-logout', this.onUserLogout);
  }

  render() {
    const { t } = this.props
    let tpls = AppConfig.tplsTalent;
    let isLogin = Server.user.isLogin()
    return (
      <div className="main">
        {isLogin &&
          <div className="talent-post-container">
            {/* 模版选择 */}
            <TemplateBar selected={0} tpls={tpls}></TemplateBar>
            {/* 编辑器 */}
            <SharedQuillEditor
              placeholder={t('pages.MyTalentPage.440531-0')}
              onChange={(length: number) => { this.wordCount = length; }}
              getRef={(ref: any) => this.quillRef = ref} />
            {/* 提交按钮 */}
            <div className='talent-post-btns'>
              <button onClick={() => this.onPost()}>{t('pages.MyTalentPage.440531-1')}</button>
            </div>
          </div>
        }
        {/* 我的要员列表 */}
        {isLogin && <MyTalentList ref={this.myTalentListRef}/>}

        <MessageModal message={this.state.message} />
        <AlertModal message={this.state.alert} button="OK" onClose={() => this.setState({ alert: '' })} />
      </div>
    );
  }

  // 模版选择回调函数
  onTalentTplSelected(event: any) {
    // 模版选择
    // console.log(event.detail)
    this.quillRef.setText(event.detail)
  }

  // 用户登录回调函数
  onUserLogin() {
    this.forceUpdate()
  }

  // 用户退出登录回调函数
  onUserLogout() {
    this.forceUpdate()
  }

  // 提交要员
  async onPost() {
    const { t } = this.props
    let message = '';
    if (this.wordCount === 0) {
      message = t('pages.MyTalentPage.440531-2');
    } else if (this.wordCount > 2000) {
      message = t('pages.MyTalentPage.440531-3');
    }
    if (message) {
      this.setState({ alert: message });
      return;
    }

    this.setState({ message: t('pages.MyTalentPage.440531-4') });

    let html = this.quillRef.root.innerHTML;
    let text = this.quillRef.getText()
    // console.log("html:", html)
    // console.log("text:", text)
    let response = await Server.talent.postTalent(html, text)
    if (response.success) {
      this.quillRef.setText('');
      this.setState({ message: ''});
    } else {
      this.setState({ message: '', alert: response.message })
    }

    // 重新加载要员
    this.myTalentListRef.current.init()
  }
}

export default withTranslation()(MyTalentPage)

interface MyTalentPageState {
  // 要员列表（包含每要员匹配的案件列表）
  talents: any[];
  // 数据加载中标志
  loading: boolean;
  // 提示消息
  message: string;
  // 警告消息
  alert: string;
}