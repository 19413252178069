import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { BsFillXCircleFill } from "react-icons/bs";
import { Server } from "../../server/server";
import Api from "../api/Api";
import { isPhoneNumber } from "../util/util";
import AlertModal from "./AlertModal";
import './EditUserInfoModal.css';

class EditUserInfoModal extends React.Component<EditUserInfoModalProps, any> {
    
    private userInfo:any
    private token:string

    constructor (props: EditUserInfoModalProps) {
        super(props)

        this.state = {
            firstName: '',
            lastName: '',
            mobile: '',
            company: '',
            companyShort: '',
            show: false,
            alert: ''
        }
    }

    componentDidMount(): void {
        
    }

    render(): React.ReactNode {
        const { t } = this.props
        if (!this.state.show) {
            return(<></>)
        }
        return (
            <div className="modal open">
                <div className="modal-content">
                    <button className="modal-close-button" onClick={this.hide}>
                        <BsFillXCircleFill />
                    </button>
                    <div className="modal-title">
                        <h3>{t('modals.EditUserInfoModal.235425-0')}</h3>
                    </div>
                    <div className="modal-main">
                        <form className="modal-form">
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label className="required" title="">{t('modals.EditUserInfoModal.235425-1')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModal.235425-2')}
                                        name="lastName"
                                        value={this.state.lastName}
                                        onChange={(e) => this.setState({lastName: e.target.value})}
                                        maxLength={16}/>
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModal.235425-3')}
                                        name="lastName"
                                        value={this.state.firstName}
                                        onChange={(e) => this.setState({firstName: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label title="">{t('modals.EditUserInfoModal.235425-4')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModal.235425-5')}
                                        name="mobile"
                                        value={this.state.mobile}
                                        onChange={(e) => this.setState({mobile: e.target.value})}
                                        maxLength={16}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label title="">{t('modals.EditUserInfoModal.235425-6')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModal.235425-7')}
                                        name="company"
                                        value={this.state.company}
                                        onChange={(e) => this.setState({company: e.target.value})}
                                        maxLength={32}/>
                                </div>
                            </div>
                            <div className="modal-form-item">
                                <div className="modal-form-item-label">
                                    <label title="">{t('modals.EditUserInfoModal.235425-8')}</label>
                                </div>
                                <div className="modal-form-item-control">
                                    <input
                                        className="base-input-text"
                                        placeholder={t('modals.EditUserInfoModal.235425-9')}
                                        name="companyShort"
                                        value={this.state.companyShort}
                                        onChange={(e) => this.setState({companyShort: e.target.value})}
                                        maxLength={6}/>
                                </div>
                            </div>
                            <div>
                                <button className="base-button" type="button" onClick={this.handleSubmit}>{t('modals.EditUserInfoModal.235425-10')}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <AlertModal message={this.state.alert} button="OK" onClose={()=>this.setState({alert: ''})}/>
            </div>
        )
    }

    hide = () => {
        this.setState({
            show: false
        })
        this.clear()
    }

    show = (token?:string) => {
        this.token = token

        this.setState({
            show: true
        }, () => {
            this.loadInfo(token)
        })
    }

    handleSubmit = () => {
        const { t } = this.props
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const mobile = this.state.mobile
        const company = this.state.company
        const companyShort = this.state.companyShort

        if (!firstName || !lastName) {
            return this.setState({alert: t('modals.EditUserInfoModal.235425-11')})
        }
        if(mobile && !isPhoneNumber(mobile)) {
            return this.setState({alert: t('modals.EditUserInfoModal.235425-12')})
        }
        // console.log("token:" + this.token)
        Server.user.updageExtInfo(firstName, lastName, company, companyShort, mobile, this.token).then(res => {
            if (!res.success) {
                return this.setState({alert: res.message})
            }

            this.hide()
            
            // 更新用户信息
            this.userInfo['firstName'] = firstName
            this.userInfo['lastName'] = lastName
            this.userInfo['company'] = company
            this.userInfo['companyShort'] = companyShort
            this.userInfo['mobile'] = mobile
            Server.user.setProfile(this.userInfo)

            // 更新成功后回调
            if(this.props.onEditUserInfoSuccess) {
                this.props.onEditUserInfoSuccess()
            }
        }).catch(e => {
            console.log(e)
            this.setState({alert: t('modals.EditUserInfoModal.235425-13')})
        })
    }

    loadInfo = (token:string) => {
        if (token) {
            // 从扫公众号二维码过来的情况
            Api.get('/api/user/profile', token).then(res => {
                if (!res.success) {
                    this.userInfo = {}
                    return
                }

                const user = res.data
                this.userInfo = user
                this.setState({
                    firstName: user.firstName || '',
                    lastName: user.lastName || '',
                    mobile: user.mobile || '',
                    company: user.company || '',
                    companyShort: user.companyShort || ''
                })
            }).catch(e => {
                console.log(e)
            })
            return
        }

        Server.user.isLogin() && Server.user.getProfile().then(user => {
            // console.log(user)
            this.userInfo = user
            this.setState({
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                mobile: user.mobile || '',
                company: user.company || '',
                companyShort: user.companyShort || ''
            })
        }).catch(e => {
            console.log(e)
        })
    }

    clear = () => {
        this.setState({
            // show: false,
            firstName: '',
            lastName: '',
            mobile: '',
            company: '',
            companyShort: '',
            alert: ''
        })
        this.token = null
    }
}

export default withTranslation('translation', { withRef : true })(EditUserInfoModal)

interface EditUserInfoModalProps extends WithTranslation {
    onEditUserInfoSuccess: Function,
    ref: any
}